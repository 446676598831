import { IPatientResponse } from '../domain/patient.entities';
import {IPatientGateway} from '../domain/patient.gateway';

export class FindUseCase {
  constructor(private findGate: IPatientGateway) {}
  async execute(cpf: string): Promise<IPatientResponse | undefined> {
    const replacedCpf = cpf.replace(/\D/g, '');
    if (replacedCpf.length < 11) return
    return await this.findGate.searchByCpf(cpf);
  }
}
