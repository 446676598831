const containerTable = {
    minHeight: '100%' as const,
    maxHeight: '100%' as const,
    minWidth: '100%' as const,
    maxWidth: '100%' as const,
    justifyContent: 'center' as const,
    justifyItems: 'center' as const,
    flexDirection: 'column' as const,
    alignItems: 'center' as const,
    backgroundColor: 'white',
};

const containerHeader = {
    minWidth: '10%' as const,
};

const pagination = {
    alignItems: 'flex-end',
    marginBottom: '1.5%',
    marginTop: '1.5%',
    justifyContent: 'center',
};

const header = {
    display: 'flex',
    alignItems: 'flex-center',
    justifyContent: 'center',
};

const add = {
    alignItems: 'flex-end',
    marginBottom: '1%',
    marginTop: '1%',
    justifyContent: 'flex-end',
};

export default {
    containerTable,
    containerHeader,
    pagination,
    header,
    add,
};
