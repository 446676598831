import { UnitGetData } from "@/@core/modules/unit/domain/portal-unit.entities"
import { Divider, Popover, Space } from "antd";
import { ColumnsType } from "antd/es/table"
import { Link } from "react-router-dom";

export const columns = (
    removeUnit: (id: number) => void,
    setVisibleSurgeon: (visible: boolean) => void,
    visibleSurgeon: boolean,
    setUnitPortalId: (id: number) => void,
    setUnitName: (name: string) => void,
    setVisibleSecretary: (visible: boolean) => void,
    visibleSecretary: boolean,

): ColumnsType<UnitGetData> => {
    const handleOpenModalSurgeon = (isOpen: boolean, id: number, name: string) => {
        setVisibleSurgeon(isOpen);
        setUnitPortalId(id);
        setUnitName(name);
    }

    const handleOpenModalSecretary = (isOpen: boolean, id: number, name: string) => {
        setVisibleSecretary(isOpen);
        setUnitPortalId(id);
        setUnitName(name);
    }

    const handleRemoveUnit = (id: number) => {
        if (!visibleSurgeon && !visibleSecretary) {
            removeUnit(id);
        }
    }

    const content = (record: UnitGetData) => {

        return (
            <>
                <Space direction="vertical">
                    <Link
                        style={{ color: '#A1A2A2' }}
                        onClick={() => handleRemoveUnit(record.unit_id)}
                        to={''}
                    >
                        Remover unidade
                    </Link>
                    <Divider style={{margin: 0, padding: 0}} />
                    <Link
                        style={{ color: '#A1A2A2' }}
                        onClick={() => handleOpenModalSurgeon(true, record.id, record.unit_name)}
                        to={''}
                    >
                        Cirurgiões
                    </Link>
                    <Divider style={{margin: 0, padding: 0}} />
                    <Link
                        style={{ color: '#A1A2A2' }}
                        onClick={() => handleOpenModalSecretary(true, record.id, record.unit_name)}
                        to={''}
                    >
                        Secretárias
                    </Link>
                </Space>
            </>
        );
    };

    return [
        {
            title: 'Nome',
            dataIndex: 'unit_name',
            width: '40%',
            sortDirections: ['descend'],
            render: (_, record) => (
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Popover
                        content={content(record)}
                        placement="bottom"
                        trigger="click"
                        overlayStyle={{
                            border: '1.5px solid #D9D9D9',
                            borderRadius: 13,
                            backdropFilter: 'blur(2px)',
                        }}
                    >
                        <img src="/menu-points.svg" alt="Points" style={{ height: 16, width: 12 }} />
                    </Popover>
                    <>{record.unit_name}</>
                </div>
            ),
        },
        {
            title: 'Data criação',
            dataIndex: 'created_at',
            render: (value: string) => {
                return new Date(value).toLocaleDateString();
            }
        },
        {
            title: 'Cirurgiões',
            dataIndex: 'total_surgeons',
            defaultSortOrder: 'descend',
            responsive: ['sm'],
        },
        {
            title: 'Secretárias',
            dataIndex: 'total_secretaries',
            defaultSortOrder: 'descend',
            width: '20%',
            responsive: ['sm'],
        },
      ];
}