import 'reflect-metadata';
import { Container } from "inversify";
import { DashboardHttpGateway } from './dashboard.http.gateway';
import { GetDashboardUseCase } from '../application/get-dashboard.use.case';
import { httpApi } from '../../common/infra/http';

export const Registry = {
    AxiosAdapter: Symbol.for('AxiosAdapter'),
    DashboardGateway: Symbol.for('DashboardGateway'),
    DashboardUseCase: Symbol.for('DashboardUseCase'),
};

export const container = new Container();

//########## HTTP
container.bind(Registry.AxiosAdapter).toConstantValue(httpApi);

//#region ########## GATEWAYS
container.bind(Registry.DashboardGateway).toDynamicValue((context) => {
    return new DashboardHttpGateway(context.container.get(Registry.AxiosAdapter));
});
//#endregion

//#region ########## USE CASES
container.bind(Registry.DashboardUseCase).toDynamicValue((context) => {
    return new GetDashboardUseCase(context.container.get(Registry.DashboardGateway));
});
//#endregion

//#region ######### CONTAINERS GET
const dashboard = {
    get: container.get<GetDashboardUseCase>(Registry.DashboardUseCase)
};
export default dashboard;
//#endregion