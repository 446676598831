import React, { useEffect, useState } from 'react'
import { Form, Row, Col, Input, Select, Card, Badge } from 'antd'
import { useItemsContext } from '@/@presentation/contexts/schedulesContext'
import { Notification } from '@presentation/components/common';
import { useProcedure } from '@/@presentation/modules/procedure/context/procedure';
import { Button, ButtonIcon } from '@/@presentation/components/form';

const FiltersBase = (props: {
    setFilter: (f: string) => void
    dataRaw: any
    setReload: (a: boolean) => void
    showStatus?: boolean
    showStatusBilling?: boolean
}) => {
    const isBackoffice = JSON.parse(localStorage.getItem('@user_infos_key') ?? '{}').type === 'BACKOFFICE';

    const { showStatus, showStatusBilling } = props

    const {
        initialDate,
        finalDate,
        surgeon,
        locale,
        scheduleId,
        requester,
        statusFilter,
        patient,
        createdInitial,
        createdFinal,
        statusBilling,

        setInitialDate,
        setFinalDate,
        setSurgeon,
        setStatusFilter,
        setLocale,
        setScheduleId,
        setRequester,
        setPatient,
        setCreatedInitial,
        setCreatedFinal,
        setStatusBilling,

        isRedirect,
        setIsRedirect,
    } = useItemsContext()

    const {
        states,
        actions,
    } = useProcedure();

    const [text, setText] = useState('')

    const [showFilters, setShowFilters] = useState(false)

    const [startDate, setStartDate] = useState<string>(initialDate)
    const [endDate, setEndDate] = useState<string>(finalDate)

    const [createdDateInitial, setCreatedDateInitial] = useState<string | undefined>(createdInitial)
    const [createdDateFinal, setCreatedDateFinal] = useState<string | undefined>(createdFinal)

    const [localeFilter, setLocaleFilter] = useState<any | undefined>(states?.procedureLocation)
    const [surgeonFilter, setSurgeonFilter] = useState<any | undefined>(states?.doctors)

    const [activeFiltersCount, setActiveFiltersCount] = useState(0);

    const handleFilterSelect = (text: string, type: 'LOCALE' | 'SURGEON') => {
        if (type === 'LOCALE') {
            setLocaleFilter(states?.procedureLocation?.filter((pl: any) => pl.label.toLowerCase().includes(text.toLowerCase())))
        }

        if (type === 'SURGEON') {
            setSurgeonFilter(states?.doctors?.filter((surgeon: any) => surgeon.label.toLowerCase().includes(text.toLowerCase())))
        }
    }

    useEffect(() => {
        clearFilters()

        actions.handleInitLoadSearchGeneral()

        setLocaleFilter(states?.procedureLocation)
        setSurgeonFilter(states?.doctors)

        if (startDate === '') {
            const today = new Date().toISOString().slice(0, 10)
            setStartDate(today)
            setInitialDate(today)
        }

        if (finalDate === '') {
            const nextMonth = new Date()
            nextMonth.setDate(nextMonth.getDate() + 30)
            const nextMonthDate = nextMonth.toISOString().slice(0, 10)
            setEndDate(nextMonthDate)
            setFinalDate(nextMonthDate)
        }

    }, [])

    useEffect(() => {
        if (!states?.procedureLocation || states?.doctors) {
            setLocaleFilter(states?.procedureLocation)
            setSurgeonFilter(states?.doctors)
        }
    }, [states?.doctors, states?.procedureLocation])

    function handleFilter() {
        if (createdDateFinal && !createdDateInitial) {
            Notification.error({
                message: 'Informe a data de solicitação inicial!'
            });

            return;
        }

        if (!createdDateFinal && createdDateInitial) {
            return Notification.error({
                message: 'Informe a data de solicitação final!'
            });
        }

        if (new Date(endDate) < new Date(startDate)) {
            return Notification.error({
                message: 'A data inicial é menor que a final!'
            });
        }

        if (createdDateFinal && createdDateInitial && (new Date(createdDateFinal) < new Date(createdDateInitial))) {
            return Notification.error({
                message: 'A data de solicitação incial é menor que a final!'
            });
        }

        props.setFilter(text)
        text.length <= 0 && props.setReload(true)
        setShowFilters(false)
    }

    const STATUS_BILLING = {
        ALL_PENDING: 'allpending',
        PENDING_TO_PAY: 'pendingToPay',
        PAID: 'paid',
        COURTESY: 'courtesy',
    }

    const statusBillingList = [
        { value: STATUS_BILLING.ALL_PENDING, label: 'Pendente' },
        { value: STATUS_BILLING.PENDING_TO_PAY, label: 'Pagamento a Confirmar' },
        { value: STATUS_BILLING.PAID, label: 'Pago' },
        { value: STATUS_BILLING.COURTESY, label: 'Cortesia' },
    ];

    enum STATUS {
        CANCELED = 'canceled',
        RESCHEDULED = 'rescheduled',
        FINISHED = 'finished',
        CONFIRMED = 'confirmed',
        IN_PROGRESS = 'inProgress',
        PENDING = 'pending',
    }

    const statusOptions = [
        { value: STATUS.PENDING, label: 'PENDENTE' },
        { value: STATUS.CANCELED, label: 'CANCELADO' },
        { value: STATUS.FINISHED, label: 'FINALIZADO' },
        { value: STATUS.CONFIRMED, label: 'CONFIRMADO' },
        { value: STATUS.IN_PROGRESS, label: 'EM ANDAMENTO' },
        { value: STATUS.RESCHEDULED, label: 'REAGENDADO' }
    ];

    const clearFilters = () => {
        if (isRedirect) {
            setIsRedirect(false)
        } else {
            const today = new Date().toISOString().slice(0, 10)
            setStartDate(today)
            setInitialDate(today)

            const nextMonth = new Date()
            nextMonth.setDate(nextMonth.getDate() + 30)
            const nextMonthDate = nextMonth.toISOString().slice(0, 10)
            setEndDate(nextMonthDate)
            setFinalDate(nextMonthDate)
            setSurgeon([])
            setLocale([])
            setScheduleId('')
            setRequester('')
            setPatient('')
            setCreatedDateInitial('')
            setCreatedDateFinal('')
            setStatusFilter([])
            setStatusBilling([])
            props.setReload(true)
            setShowFilters(false)
        }
    }

    const updateActiveFiltersCount = () => {
        let count = 0;

        if (startDate) count++;
        if (endDate) count++;
        if (surgeon && surgeon.length > 0) count++;
        if (locale && locale.length > 0) count++;
        if (scheduleId) count++;
        if (requester) count++;
        if (patient) count++;
        if (createdDateInitial) count++;
        if (createdDateFinal) count++;
        if (statusFilter && statusFilter.length > 0) count++;
        if (statusBilling && statusBilling.length > 0) count++;

        setActiveFiltersCount(count);
    };

    useEffect(() => {
        updateActiveFiltersCount();
    }, [startDate, endDate, surgeon, locale, scheduleId, requester, patient, createdDateInitial, createdDateFinal, statusFilter, statusBilling]);

    return (
        <Form layout="vertical" style={{ padding: 10 }}>
            <Row justify="end">
                {
                    !showFilters && (
                        <div style={{ position: 'relative', display: 'inline-block' }}>
                            <label style={{ position: 'absolute', marginTop: -22, left: 5 }}>Data Inicial</label>
                            <Input
                                type='date'
                                style={{ borderRadius: 8, width: 150 }}
                                placeholder={'Data Inicial'}
                                value={startDate}
                                onChange={(e) => [setStartDate(e.target.value), setInitialDate(e.target.value)]}
                            />

                            <label style={{ position: 'absolute', marginTop: -22, left: 165 }}>Data Final</label>
                            <Input
                                type='date'
                                style={{ borderRadius: 8, width: 150, marginLeft: 10, marginRight: 10 }}
                                placeholder={'Data Final'}
                                value={endDate}
                                onChange={(e) => [setEndDate(e.target.value), setFinalDate(e.target.value)]}
                            />
                        </div>
                    )
                }

                <div style={{ position: 'relative', display: 'inline-block' }}>
                    <input
                        style={{
                            borderRadius: 8,
                            borderColor: '#D9D9D9',
                            borderWidth: 1,
                            borderStyle: "solid",
                            paddingLeft: 10,
                            paddingRight: 40,
                            width: 300,
                            height: 35
                        }}
                        placeholder='Pesquisar'
                        onChange={(e) => setText(e.target.value)}
                    />
                </div>
                {
                    !showFilters && (
                        <Button
                            text=''
                            nativeProps={{
                                htmlType: 'submit',
                                style: {
                                    borderRadius: 8,
                                    marginLeft: 10,
                                    marginTop: 5,
                                    color: 'white',
                                    width: 33,
                                    height: 33,
                                    backgroundColor: '#004A7D',
                                    position: 'relative',
                                },
                                icon: <img
                                    src="/search.svg"
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        cursor: 'pointer',
                                        height: 18,
                                        width: 18,
                                    }}
                                />
                            }}

                            onClick={handleFilter}
                        />
                    )
                }

                <Badge size="small" count={activeFiltersCount}>
                    <Button
                        text='Filtros'
                        onClick={() => setShowFilters(!showFilters)}
                        nativeProps={{
                            htmlType: 'submit',
                            style: {
                                borderRadius: 8,
                                marginLeft: 10,
                                marginTop: 5,
                                color: 'white',
                                height: 33,
                                backgroundColor: '#004A7D',
                                position: 'relative',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                paddingRight: 20,
                            },
                            icon: <img
                                src="/filter.svg"
                                alt="filter icon"
                                style={{
                                    width: 20,
                                    height: 20,
                                }}
                            />
                        }}
                    />
                </Badge>

            </Row>
            {showFilters && (
                <Card
                    style={{
                        borderRadius: 8,
                        borderColor: '#D9D9D9',
                        borderWidth: 1,
                        borderStyle: "solid",
                        marginTop: 10,
                        marginBottom: 10
                    }}
                >
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item label="Procedimento de:">
                                <Input
                                    type='date'
                                    style={{ borderRadius: 8 }}
                                    value={startDate}
                                    required
                                    onChange={(e) => [setStartDate(e.target.value), setInitialDate(e.target.value)]}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="até:">
                                <Input
                                    type='date'
                                    style={{ borderRadius: 8 }}
                                    value={endDate}
                                    required
                                    onChange={(e) => [setEndDate(e.target.value), setFinalDate(e.target.value)]}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Paciente">
                                <Input
                                    style={{ borderRadius: 8 }}
                                    placeholder={'Paciente'}
                                    value={patient}
                                    onChange={(e) => setPatient(e.target.value)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item label="Cirurgião">
                                <Select
                                    disabled={!isBackoffice}
                                    mode='multiple'
                                    style={{ borderRadius: 8 }}
                                    placeholder={'Cirurgião'}
                                    value={surgeon}
                                    onChange={(e) => setSurgeon(e)}
                                    onSearch={(e) => handleFilterSelect(e, 'SURGEON')}
                                    filterOption={false}
                                    showSearch
                                >
                                    {
                                        surgeonFilter?.map((surgeon: any, index: any) => (
                                            <Select.Option key={index} value={surgeon.value}>{surgeon.label}</Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        {showStatus && <Col span={8}>
                            <Form.Item label="Status">
                                <Select
                                    style={{ borderRadius: 8 }}
                                    mode='multiple'
                                    placeholder={'Status'}
                                    value={statusFilter}
                                    onChange={(e) => setStatusFilter(e)}
                                >
                                    {
                                        statusOptions.map((status, index) => (
                                            <Select.Option key={index} value={status.value}>{status.label}</Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>}
                        {showStatusBilling && <Col span={8}>
                            <Form.Item label="Status do pagamento">
                                <Select
                                    style={{ borderRadius: 8 }}
                                    mode='multiple'
                                    placeholder={'Status'}
                                    value={statusBilling}
                                    onChange={(e) => setStatusBilling(e)}
                                >
                                    {
                                        statusBillingList.map((status, index) => (
                                            <Select.Option key={index} value={status.value}>{status.label}</Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>}
                        <Col span={8}>
                            <Form.Item label="Unidade">
                                <Select
                                    disabled={!isBackoffice}
                                    mode='multiple'
                                    style={{ borderRadius: 8 }}
                                    placeholder={'Unidade'}
                                    value={locale}
                                    onChange={(e) => setLocale(e)}
                                    onSearch={(e) => handleFilterSelect(e, 'LOCALE')}
                                    filterOption={false}
                                    showSearch
                                >
                                    {
                                        localeFilter?.map((pl: any, index: any) => (
                                            <Select.Option key={index} value={pl.value}>{pl.label}</Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item label="Solicitado de:">
                                <Input
                                    type='date'
                                    style={!createdDateInitial && createdDateFinal ? { borderRadius: 8, borderColor: 'red' } : { borderRadius: 8 }}
                                    value={createdDateInitial}
                                    required={(!createdDateInitial && createdDateFinal) ? true : false}
                                    onChange={(e) => [setCreatedDateInitial(e.target.value), setCreatedInitial(e.target.value)]}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="até:">
                                <Input
                                    type='date'
                                    style={createdDateInitial && !createdDateFinal ? { borderRadius: 8, borderColor: 'red' } : { borderRadius: 8 }}
                                    value={createdDateFinal}
                                    required={(createdDateInitial && !createdDateFinal) ? true : false}
                                    onChange={(e) => [setCreatedDateFinal(e.target.value), setCreatedFinal(e.target.value)]}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Solicitante">
                                <Input
                                    style={{ borderRadius: 8 }}
                                    placeholder={'Solicitante'}
                                    value={requester}
                                    onChange={(e) => setRequester(e.target.value)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item label="ID">
                                <Input
                                    style={{ borderRadius: 8 }}
                                    placeholder={'ID'}
                                    type='number'
                                    value={scheduleId}
                                    onChange={(e) => setScheduleId(e.target.value)}
                                />
                                {scheduleId && scheduleId.length > 0 && (
                                    <p>Apenas o ID será considerado no filtro</p>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                        </Col>
                        <Col span={8} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <Button
                                text='Limpar filtro'
                                nativeProps={{
                                    ghost: true,
                                    style: {
                                        borderRadius: 8,
                                        marginLeft: 10,
                                        marginTop: 5,
                                        position: 'relative',
                                    }
                                }}
                                onClick={clearFilters}
                            />
                            <Button
                                text='Aplicar filtro'
                                nativeProps={{
                                    style: {
                                        borderRadius: 8,
                                        marginLeft: 10,
                                        marginTop: 5,
                                        color: 'white',
                                        backgroundColor: '#004A7D',
                                        position: 'relative',
                                    }
                                }}
                                onClick={handleFilter}
                            />
                        </Col>
                    </Row>
                </Card>
            )}
        </Form>
    )
}

export default FiltersBase
