import { AuthenticationProvider } from '../authentication/contexts/authentication';
import { LoadingProvider } from '@presentation/contexts/loading';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { plugin, observability } from '../../../config/observability';
import Security from '../../../config/security';
import Routes from './routes';
import { ProcedureProvider } from '../procedure/context/procedure';
import { ItemsProvider } from '@/@presentation/contexts/schedulesContext';
import { SelectedMenuProvider } from '@/@presentation/contexts/selectedMenu';
import { BaseInfoProvider } from '@/@presentation/contexts/BaseInfo';

const Root = () => {
    Security();
    observability();
    return (
        <AuthenticationProvider>
            <SelectedMenuProvider>
                <LoadingProvider>
                    <AppInsightsContext.Provider value={plugin}>

                        {/* <BaseInfoProvider> */}
                            <ItemsProvider>
                                <ProcedureProvider>
                                    <Routes />
                                </ProcedureProvider>
                            </ItemsProvider>
                        {/* </BaseInfoProvider> */}

                    </AppInsightsContext.Provider>
                </LoadingProvider>
            </SelectedMenuProvider>
        </AuthenticationProvider>
    );
};

export default Root;
