import React, {useEffect} from 'react';
import {Modal as AntModal} from 'antd';
import {FormVerifyCode, FormChangePassword} from '@presentation/components/form';
import {auth} from '@core/modules/authentication/infra/container.registry';
import {Notification} from '@presentation/components/common';

interface IResetPassword {
    email: string;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    visible: boolean;
}

const ResetPassword = (props: IResetPassword) => {
    const [loading, setLoading] = React.useState(false);
    const [isVerifyCode, setIsVerifyCode] = React.useState(false);
    const [code, setCode] = React.useState('');

    const sendCode = async () => {
        await auth.forgotPassword.execute({email: props.email});
        Notification.success({
            message: 'Solicitação de redefinição de senha enviada para seu e-mail!',
        });
    };

    const initialize = async () => {
        setLoading(true);
        await sendCode();

        setIsVerifyCode(false);
        setLoading(false);
        setCode('');
    };

    useEffect(() => {
        initialize();
    }, [props.visible]);

    return (
        <AntModal
            data-testid="modal-form"
            destroyOnClose={true}
            forceRender
            title={null}
            open={props.visible}
            centered={true}
            onOk={() => props.setVisible(!props.visible)}
            onCancel={() => props.setVisible(!props.visible)}
            width={380}
            footer={null}
        >
            {props.email && !isVerifyCode && (
                <FormVerifyCode
                    loading={loading}
                    setLoading={setLoading}
                    setIsVerifyCode={setIsVerifyCode}
                    email={props.email}
                    setCode={setCode}
                />
            )}
            {isVerifyCode && (
                <FormChangePassword
                    loading={loading}
                    setLoading={setLoading}
                    setVisible={props.setVisible}
                    visible={props.visible}
                    email={props.email}
                    code={code}
                />
            )}
        </AntModal>
    );
};

export {ResetPassword};
