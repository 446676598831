import 'reflect-metadata';
import {Container} from 'inversify';
import {httpApi, mockApi} from '../../common/infra/http';

import {SurgeonHttpGateway} from './surgeon.http.gateway';

import {GetUseCase} from '../application/get.use.case';
import {GetAllUseCase} from '../application/getAll.use.case';
import {ResetPasswordUseCase} from '../application/resetPassword.use.case';
import {UpdateUseCase} from '../application/update.use.case';
import {CreateUseCase} from '../application/create.use.case';
import {CreateAccessUseCase} from '../application/createAccess.use.case';

export const Registry = {
    AxiosAdapter: Symbol.for('AxiosAdapter'),
    MockAdapter: Symbol.for('MockAdapter'),

    SurgeonGateway: Symbol.for('SurgeonGateway'),
    GetAllUseCase: Symbol.for('GetAllUseCase'),
    GetUseCase: Symbol.for('GetUseCase'),
    ResetPasswordUseCase: Symbol.for('ResetPasswordUseCase'),
    UpdateUseCase: Symbol.for('UpdateUseCase'),
    CreateUseCase: Symbol.for('CreateUseCase'),
    CreateAccessUseCase: Symbol.for('CreateAccessUseCase'),
};

export const container = new Container();

//########## HTTP
container.bind(Registry.AxiosAdapter).toConstantValue(httpApi);
container.bind(Registry.MockAdapter).toConstantValue(mockApi);

//#region ########## GATEWAYS
container.bind(Registry.SurgeonGateway).toDynamicValue((context) => {
    return new SurgeonHttpGateway(
        context.container.get(Registry.AxiosAdapter),
        context.container.get(Registry.MockAdapter),
    );
});

//#endregion

//#region ########## USE CASES
container.bind(Registry.GetUseCase).toDynamicValue((context) => {
    return new GetUseCase(context.container.get(Registry.SurgeonGateway));
});
container.bind(Registry.GetAllUseCase).toDynamicValue((context) => {
    return new GetAllUseCase(context.container.get(Registry.SurgeonGateway));
});
container.bind(Registry.ResetPasswordUseCase).toDynamicValue((context) => {
    return new ResetPasswordUseCase(context.container.get(Registry.SurgeonGateway));
});
container.bind(Registry.UpdateUseCase).toDynamicValue((context) => {
    return new UpdateUseCase(context.container.get(Registry.SurgeonGateway));
});
container.bind(Registry.CreateUseCase).toDynamicValue((context) => {
    return new CreateUseCase(context.container.get(Registry.SurgeonGateway));
});
container.bind(Registry.CreateAccessUseCase).toDynamicValue((context) => {
    return new CreateAccessUseCase(context.container.get(Registry.SurgeonGateway));
});
//#endregion

//#region ######### CONTAINERS GET
const surgeon = {
    get: container.get<GetUseCase>(Registry.GetUseCase),
    getAll: container.get<GetAllUseCase>(Registry.GetAllUseCase),
    resetPassword: container.get<ResetPasswordUseCase>(Registry.ResetPasswordUseCase),
    update: container.get<UpdateUseCase>(Registry.UpdateUseCase),
    create: container.get<CreateUseCase>(Registry.CreateUseCase),
    createAccess: container.get<CreateAccessUseCase>(Registry.CreateAccessUseCase),
};
export default surgeon;
//#endregion
