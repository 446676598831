import { BillInterface } from '../domain/bill.entities';
import { BillGateway } from '../domain/bill.gateway';

export class CreateUseCase {
    constructor(private gate: BillGateway) {}

    async execute(params: BillInterface): Promise<Boolean> {
        const updatedParams: BillInterface = {
            ...params
        };
        return this.gate.create(updatedParams);
    }
}
