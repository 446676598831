const div = {
    display: 'flex',
    justifyContent: 'center'
};

const img: React.CSSProperties = {
    width: '100%',
    height: '100vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: 'transparent',
};

const container: React.CSSProperties = {
    position: 'absolute',
    top: '34%',

    textAlign: 'start',
    marginLeft: '22%',
};

const title = {
    color: '#FFF',
    fontSize: '64px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
};

const details = {
    color: '#FFF',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    marginTop: '20%',
};

const card: React.CSSProperties = {
    marginTop: '20%',
    textAlign: 'center',
    background: '#FFF',
    margin: 10,
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 16,
    width: 470,
};

const row = {
    height: '100vh',
    width: '100%',
};

const col = {
    margin: 0,
    padding: 0,
    height: '100vh',
};

const colSecond = {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const cardTitle: React.CSSProperties = {
    color: '#054B7C',
    maxWidth: 410,
    textAlign: 'center',
    fontSize: 21,
    fontStyle: 'normal',
    fontWeight: 550,
    marginTop: 40,
    marginBottom: 12,
    alignSelf: 'center',
};

const cardSubTitle: React.CSSProperties = {
    color: '#475569',
    textAlign: 'center',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 500,
    maxWidth: '413px',
};

const formTitle: React.CSSProperties = {
    color: '#000000',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '20.5px',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    textAlign: 'left',
    alignSelf: 'start'
};

const cardFormFlex: React.CSSProperties = {
    marginTop: '10%',
};

export default {
    row,
    col,
    colSecond,
    div,
    img,
    container,
    title,
    details,
    card,
    cardTitle,
    cardSubTitle,
    cardFormFlex,
    formTitle,
};
