interface IConfirmView {
  item: {
    label: string;
    value?: string;
  }
}

export const ConfirmationView: React.FC<IConfirmView> = (props: IConfirmView) => {
  return (
    <div style={{ minWidth: 100, margin: 15 }}>
      <span style={{
        fontWeight: 400,
        fontSize: 15,
        color: '#262A41'
      }}>{props.item.label}</span>
      {props.item?.value ? <p style={{
        fontWeight: 400,
        fontSize: 20,
        color: '#262A41'
      }}>{props.item?.value}</p> : <p style={{
        fontWeight: 400,
        fontSize: 17,
        color: '#898a8c'
      }}>(Não informado)</p>}
    </div>
  )
}