import {Form as AntForm, Select} from 'antd';

const {Option} = Select;

const SelectGender = () => {
    return (
        <AntForm.Item name="gender" label="Gênero" rules={[{required: false}]}>
            <Select
                placeholder="Gênero"
                data-testid="select-sex"
                style={{
                    maxWidth: '100%',
                    height: 40,
                }}
            >
                <Option value="MALE">Masculino</Option>
                <Option value="FEMALE">Feminino</Option>
                <Option value="OTHER">Outro</Option>
            </Select>
        </AntForm.Item>
    );
};

export {SelectGender};
