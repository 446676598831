import { TGetUnitByIdRequest, TGetUnitByIdResponse } from "../domain/portal-unit.entities";
import { UnitGateway } from "../domain/unit.gateway";

export class GetUnitByIdUseCase {
    constructor(private unitGateway: UnitGateway) {}

    async execute(data: TGetUnitByIdRequest): Promise<TGetUnitByIdResponse> {
        return this.unitGateway.getUnitById(data);
    }
}
