export const getAllMedicalProcedure = () => {
  return {
    data: [
      {
        code: 'P001',
        name: 'Radiografia Torácica',
        isPrincipal: false
      },
      {
        code: 'P002',
        name: 'Ultrassonografia Abdominal',
        isPrincipal: false
      },
      {
        code: 'P003',
        name: 'Eletrocardiograma',
        isPrincipal: false
      },
      {
        code: 'P004',
        name: 'Tomografia Computadorizada de Crânio',
        isPrincipal: false
      },
      {
        code: 'P005',
        name: 'Ressonância Magnética de Joelho',
        isPrincipal: false
      },
      {
        code: 'P006',
        name: 'Biopsia de Pele',
        isPrincipal: false
      }
    ]
  };
};

export const chargeMethods = () => {
  return {
    data: [
      { label: 'Particular', value: 'particular' },
      { label: 'Reembolso', value: 'reimbursement' },
      { label: 'Convênio', value: 'health-insurance' },
    ]
  }
}

export const procedureTypes = () => {
  return {
    data: [
      { title: 'Cirurgia', value: 'surgery' },
      { title: 'Robótica', value: 'robotic' },
    ]
  }
}

export const procedureLocation = () => {
  return {
    data: [
      { label: 'Clínica', value: 'clinic' },
      { label: 'Hospital', value: 'hospital' },
      { label: 'Domiciliar', value: 'domicile' },
    ]
  }
}

export const genres = () => {
  return {
    data: [
      { label: 'Masculino', value: 'MALE' },
      { label: 'Feminino', value: 'FEMALE' },
      { label: 'Outros', value: 'OTHERS' },
    ]
  }
};

export const country = () => {
  return {
    data: [
      { label: 'Brasil', value: 'br' },
      { label: 'Estados Unidos', value: 'us' },
      { label: 'Argentina', value: 'ar' },
    ]
  }
}

export const relationships = () => {
  return {
    data: [
      { label: 'Pai', value: 'father' },
      { label: 'Mãe', value: 'mother' },
      { label: 'Irmão', value: 'brother' },
    ]
  }
}

export const states = () => {
  return {
    data: [
      { label: 'São Paulo', value: 'sp' },
      { label: 'Rio de Janeiro', value: 'rj' },
      { label: 'Minas Gerais', value: 'mg' },
    ]
  }
}

export const  neighborhood = () => {
  return {
    data: [
      { label: 'Vila Mariana', value: 'vila-mariana' },
      { label: 'Vila Madalena', value: 'vila-madalena' },
      { label: 'Vila Olimpia', value: 'vila-olimpia' },
    ]
  }
}

export const reasonCancelSchedule = () => {
  return {
    data: [
      { label: 'Motivos clínicos', value: 'CLINICAL_REASON' },
      { label: 'Procedimento não autorizado', value: 'UNAUTHORIZED_PROCEDURE' },
      { label: 'Paciente desistiu', value: 'PATIENT_GAVE_UP' },
      { label: 'Duplicidade', value: 'DUPLICATE' },
      { label: 'Solicitação médica', value: 'REQUESTED_DOC' },
      { label: 'Indisponibilidade do cirurgião', value: 'SURGEON_UNAVAILABILITY' },
      { label: 'Indisponibilidade do paciente', value: 'PATIENT_UNAVAILABILITY' },
      { label: 'Paciente positivo para Covid-19', value: 'PATIENT_COVID' },
      { label: 'Outros', value: 'OTHERS' },
    ]
  }
}
