import React, {createContext, useState, useContext, useEffect} from 'react';
import {Notification} from '@presentation/components/common';
import {auth} from '@core/modules/authentication/infra/container.registry';
import {UserInfos} from '@core/modules/authentication/domain/userInfos.entities';
import {
    RequestAccess,
    TypeRequestAccessParams,
} from '@core/modules/authentication/domain/requestAccess.entities';

interface AuthenticationContextData {
    user: UserInfos | undefined;
    setUser: React.Dispatch<UserInfos | undefined>;
    signIn: (credentials: TypeRequestAccessParams) => Promise<RequestAccess | undefined>;
    loading: boolean;
}
interface Props {
    children: React.ReactNode;
}

const AuthenticationContext = createContext<AuthenticationContextData>(
    {} as AuthenticationContextData,
);

const AuthenticationProvider: React.FC<Props> = ({children}) => {
    const [user, setUser] = useState<UserInfos>();
    const [loading, setLoading] = React.useState(false);

    async function signIn(values: TypeRequestAccessParams): Promise<RequestAccess | undefined> {
        try {
            setLoading(true);
            const requestAccess = await auth.requestAccess.execute(values);

            if (!requestAccess) return;

            const userInfos = await auth.userInfos.execute();
            setUser(userInfos);

            return requestAccess;
        } catch (error: any) {
            Notification.error({
                message: error.message,
            });
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }

    const init = React.useCallback(async () => {
        const currentUser = await auth.userInfos.execute();
        if (currentUser?.email) {
            setUser(currentUser);
        } else {
            setUser(undefined);
        }
    }, []);

    useEffect(() => {
        init();
    }, []);

    return (
        <AuthenticationContext.Provider value={{user, setUser, signIn, loading}}>
            {children}
        </AuthenticationContext.Provider>
    );
};

function useAuthentication() {
    const context = useContext(AuthenticationContext);

    if (!context) {
        throw new Error('useAuthentication must be used within an AuthenticationProvider.');
    }

    return context;
}

export {AuthenticationProvider, useAuthentication};
