import 'reflect-metadata';
import {Container} from 'inversify';
import {httpApi} from '../../common/infra/http';
import { VerifyUseCase } from '../application/verify.use.case';
import { ValidateCataGadpHttpGateway } from './validateCataGadp.http.gateway';

export const Registry = {
    AxiosAdapter: Symbol.for('AxiosAdapter'),
    ValidateCataGadpGateway: Symbol.for('ValidateCataGadpGateway'),
    VerifyUseCase: Symbol.for('VerifyUseCase'),
};

export const container = new Container();

//########## HTTP
container.bind(Registry.AxiosAdapter).toConstantValue(httpApi);

//#region ########## GATEWAYS
container.bind(Registry.ValidateCataGadpGateway).toDynamicValue((context) => {
    return new ValidateCataGadpHttpGateway(context.container.get(Registry.AxiosAdapter));
});

//#endregion

//#region ########## USE CASES
container.bind(Registry.VerifyUseCase).toDynamicValue((context) => {
    return new VerifyUseCase(context.container.get(Registry.ValidateCataGadpGateway));
});

//#endregion

//#region ######### CONTAINERS GET
const verifyCataGadp = {
    verify: container.get<VerifyUseCase>(Registry.VerifyUseCase)
};
export default verifyCataGadp;
//#endregion
