import 'reflect-metadata';
import {ConfigProvider} from 'antd';
import ReactDOM from 'react-dom/client';
import Route from './@presentation/modules/route';
import 'antd/dist/reset.css';
import 'typeface-poppins';
import 'dayjs/locale/pt-br';
import dayjs from 'dayjs';
import ptBR from 'antd/lib/locale/pt_BR';
import './config/scrollbar.css';
dayjs.locale('pt-br');

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <ConfigProvider
        locale={ptBR}
        theme={{
            token: {
                borderRadius: 4,
                colorPrimary: '#054b7c',
                colorPrimaryActive: '#00A6D0',
                colorPrimaryHover: '#00A6D0',
                colorPrimaryBg: '#FFFF',
                colorBgLayout: '#f8f8f8',
                wireframe: true,
                yellow: '#FCD212',
                colorText: '#667085',
            },
            components: {
                Button: {
                    colorPrimaryBorder: '#004A7D',
                    colorLink: '#262A41',
                },
                Layout: {
                    colorBgContainer: '#04ACCF',
                    colorPrimary: '#04ACCF',
                },
                Menu: {
                    colorText: '#004A7D',
                    colorPrimary: '#04ACCF',
                    itemActiveBg: '#FFFF',
                    itemBg: '#FFFF',
                    activeBarBorderWidth: -18,
                    colorBgElevated: '#FFFF',
                },
                Table: {
                    borderRadius: 8,
                    headerBorderRadius: 8,
                    colorTextHeading: '#667085',
                    headerBg: '#FCFCFD',
                    borderColor: '#EAECF0',
                    colorText: '#667085',
                    fontSize: 12,
                    fontWeightStrong: 400,
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
                },
                Pagination: {
                    colorTextBase: '#828383',
                    colorText: '#262A41',
                    colorTextDescription: '#828383',
                },
                Card: {
                    headerFontSize: 20,
                    colorTextHeading: '#004A7D',
                },
                Popover: {
                    borderRadiusXS: 12,
                    borderRadiusLG: 12,
                    colorBgElevated: 'rgba(255, 255, 255, 0.8)',
                },
                Input: {
                    borderRadius: 8,
                },
                Select: {
                    borderRadius: 8,
                },
            },
        }}
    >
        <Route />
    </ConfigProvider>,
);
