import { Steps as AntSteps, Tooltip } from 'antd';
import './style.css';

interface ISteps {
    id: any;
    onChange: (value: number) => any;
    current: number;
    item: any;
}

const StepsButton = (props: ISteps) => {
    const { item, current, onChange } = props;
    const nfIsCanceled = ['canceled', 'allcanceled', 'Cancelado', 'Erro'].includes(item?.statusInvoice);
    
    const getIcon = (status: string, icons: Record<string, string>) => icons[status] || icons.default;

    const iconCloak = getIcon(item?.status, {
        CANCELADO: 'error.svg',
        default: item?.schedulePaymentTypeId === 5 ? 'warning-blue.svg' : 'cloack.svg',
    });

    const iconSchedule = getIcon(item?.status, {
        CANCELADO: 'error.svg',
        default: item?.checkIn === null && item?.checkOut === null 
                    ? 'checkout_pending.svg' 
                    : item?.checkIn !== null && item?.checkOut === null 
                    ? 'checkin_finished.svg' 
                    : 'checkout_finished.svg',
    });

    const iconPayment = item?.status === 'CANCELADO' ? 'error.svg' : getIcon(item?.statusBilling, {
        pending: 'paymentPending.svg',
        paid: 'payment.svg',
        default: item?.status === 'CANCELADO' ? 'error.svg' : 'paymentGray.svg',
    });

    const iconNf = () => {
        if (item?.status === 'CANCELADO') return 'error.svg';
        if (nfIsCanceled) return 'error.svg';
        if (item?.statusInvoice==='Erro') return 'documentOrange.svg';
        if (item?.statusInvoice === 'Emitido' || item?.statusInvoice === 'Solicitado') return 'document.svg';
        return 'documentGray.svg';
    };

    const cloakTooltip = () => {
        if (item?.status === 'CANCELADO') return "Item cancelado";
        if (item?.schedulePaymentTypeId === 5) return "Agendamento";
        return "Agendamento";
    };

    const scheduleTooltip = () => {
        if (item?.status === 'CANCELADO') return "Item cancelado";
        if (item?.checkIn === null && item?.checkOut === null) return "Check-out pendente";
        if (item?.checkIn !== null && item?.checkOut === null) return "Check-in concluído";
        return "Check-out concluído";
    };

    const paymentTooltip = () => {
        if (item?.status === 'CANCELADO') return "Item cancelado";
        return item?.statusBilling === 'pending' 
            ? "Pagamento pendente" 
            : item?.statusBilling === 'paid' 
            ? "Pagamento realizado" 
            : "Status do pagamento";
    };

    const nfTooltip = () => {
        if (item?.status === 'CANCELADO') return "Item cancelado";
        if (nfIsCanceled) return "Erro ao gerar nota fiscal, por favor entre em contato com a Takaoka.";
        if (!item?.checkIn && !item?.checkOut && item?.statusInvoice==='Erro') return "Erro ao gerar nota fiscal, por favor aguarde a realização do procedimento.";
        if (item?.checkIn && item?.checkOut && (item?.statusInvoice === 'Emitido' || item?.statusInvoice === 'Solicitado')) return "NF emitida";
        return "Status NF";
    };

    return (
        <div style={{ cursor: 'not-allowed', width: 123 }}>
            <AntSteps
                size="small"
                current={current}
                onChange={onChange}
                items={[
                    {
                        title: '',
                        status: 'finish',
                        icon: (
                            <Tooltip title={cloakTooltip()}>
                                <img
                                    src={iconCloak}
                                    style={{
                                        cursor: 'not-allowed',
                                        height: 20,
                                        width: 20,
                                    }}
                                />
                            </Tooltip>
                        ),
                    },
                    {
                        title: '',
                        status: 'finish',
                        icon: (
                            <Tooltip title={scheduleTooltip()}>
                                <img
                                    src={iconSchedule}
                                    style={{
                                        cursor: 'not-allowed',
                                        height: 20,
                                        width: 20,
                                    }}
                                />
                            </Tooltip>
                        ),
                    },
                    {
                        title: '',
                        status: 'finish',
                        icon: (
                            <Tooltip title={nfTooltip()}>
                                <img
                                    src={iconNf()}
                                    style={{
                                        cursor: 'not-allowed',
                                        height: 20,
                                        width: 20,
                                    }}
                                />
                            </Tooltip>
                        ),
                    },
                    {
                        title: '',
                        status: 'finish',
                        icon: (
                            <Tooltip title={paymentTooltip()}>
                                <img
                                    src={iconPayment}
                                    style={{
                                        cursor: 'not-allowed',
                                        height: 20,
                                        width: 20,
                                    }}
                                />
                            </Tooltip>
                        ),
                    },
                ]}
            />
        </div>
    );
};

export default StepsButton;
