import {SurgeonPagination, TAll} from '../domain/surgeon.entities';
import {SurgeonGateway} from '../domain/surgeon.gateway';

export class GetAllUseCase {
    constructor(private gate: SurgeonGateway) {}

    async execute(params: TAll): Promise<SurgeonPagination> {
        return this.gate.getAll(params);
    }
}
