import {RequestAccess, TypeRequestAccessParams} from '../domain/requestAccess.entities';
import {
    AuthenticationGateway,
    AuthenticationLocalStorageGateway,
} from '../domain/authentication.gateway';
import {setHeaderAuthorization} from '../../common/infra/http';

export class RequestAccessUseCase {
    constructor(
        private gate: AuthenticationGateway,
        private gateLocalStorage: AuthenticationLocalStorageGateway,
    ) {}

    async execute(params: TypeRequestAccessParams): Promise<RequestAccess> {
        const requestAccess = await this.gate.requestAccess(params);
        if (requestAccess) {
            const token = requestAccess?.access_token;
            if (token) {
                await setHeaderAuthorization(token);
                await this.gateLocalStorage.save(requestAccess);
            }
        }
        return requestAccess;
    }
}
