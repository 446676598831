import React from 'react';
import { Form, Layout } from 'antd';
import { List } from '@presentation/components/form';
import { User } from '@core/modules/user/domain/user.entities';
import units from '@/@core/modules/unit/infra/container.registry';
import { columns } from './components/columns';
import { ModalUnit } from './components/modal-unit';
import { ModalSurgeon } from './components/modal-surgeon';
import { ModalSecretary } from './components/modal-secretary';

const UnitList = () => {
    const UNIT_API = units;
    const [modalVisible, setModalVisible] = React.useState(false);
    const [visibleSurgeon, setVisibleSurgeon] = React.useState(false);
    const [visibleSecretary, setVisibleSecretary] = React.useState(false);
    const [params, setParams] = React.useState<User>();
    const [loading, setLoading] = React.useState(false);
    const [isReload, setIsReload] = React.useState(false);
    const [visible, setVisible] = React.useState(false);
    const [unitPortalId, setUnitPortalId] = React.useState<number | undefined>(undefined);
    const [unitName, setUnitName] = React.useState<string | undefined>(undefined);

    async function loadData() {
        const result = await UNIT_API.unit.list.execute();
        return result;
    }

    const removeUnit = (id: number) => {
        units.unit.delete.execute({
            unit_id: id,
        }).then(() => {
            setIsReload(!isReload);
        });
    }

    return (
        <Layout>
            <ModalUnit
                setIsReload={setIsReload}
                isReload={isReload}
                setVisible={setVisible}
                visible={visible}
            />
            <ModalSurgeon
                isReload={isReload}
                setIsReload={setIsReload}
                setVisible={setVisibleSurgeon}
                visible={visibleSurgeon}
                unitPortalId={unitPortalId}
                unitName={unitName}
            />
            <ModalSecretary
                isReload={isReload}
                setIsReload={setIsReload}
                setVisible={setVisibleSecretary}
                visible={visibleSecretary}
                unitPortalId={unitPortalId}
                unitName={unitName}
            />
            <List
                isAdd={true}
                onAdd={() => setVisible(true)}
                columns={columns(
                    removeUnit,
                    setVisibleSurgeon,
                    visibleSurgeon,
                    setUnitPortalId,
                    setUnitName,
                    setVisibleSecretary,
                    visibleSecretary,
                )}
                loadData={loadData}
                Form={Form}
                setLoading={setLoading}
                loading={loading}
                setModalVisible={setModalVisible}
                modalVisible={modalVisible}
                setParams={setParams}
                params={params}
                setReload={setIsReload}
                isReload={isReload}
                hideDataFilter={true}
            />
        </Layout>
    );
};

export default UnitList;
