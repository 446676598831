import {TypeForgotPassword} from '../domain/requestAccess.entities';
import {AuthenticationGateway} from '../domain/authentication.gateway';

export class ForgotPasswordUseCase {
    constructor(private gate: AuthenticationGateway) {}

    async execute(params: TypeForgotPassword): Promise<boolean> {
        return this.gate.forgotPassword(params);
    }

    async validateCode(params: TypeForgotPassword): Promise<boolean> {
        return this.gate.forgotPasswordValidateCode(params);
    }

    async changePassword(params: TypeForgotPassword): Promise<boolean> {
        return this.gate.forgotPasswordByCode(params);
    }
}
