import React from 'react';
import {Input, Col, Form, Row, Space, Button} from 'antd';
import {SearchProps} from 'antd/es/input/Search';
import {SearchOutlined} from '@ant-design/icons';

const {Search} = Input;

export interface OptionType {
    value: string;
    name: string;
    placeholder: string;
}

interface IFilter {
    options: OptionType;
    onFilterChange: (selectedKey: string, value: string) => void;
}

export const Filter: React.FC<IFilter> = (props) => {
    const onSearch: SearchProps['onSearch'] = (value) =>
        props.onFilterChange(props.options.value, value);

    return (
        <div style={{ marginRight: -50, marginTop: 20 }}>
            <Form layout="vertical">
                <Row justify="start">
                    <Col xs={24} sm={22} md={22} lg={22} xl={22}>
                        <Space size="small">
                            <Search
                                placeholder={props.options.placeholder}
                                onSearch={onSearch}
                                style={{
                                    height: 42,
                                    width: 400,
                                    borderRadius: 8,
                                    backgroundColor: 'white',
                                }}
                                enterButton={
                                    <Button
                                        type="primary"
                                        style={{
                                            borderColor: '#D9D9D9',
                                            borderLeftColor: 'white',
                                            backgroundColor: 'white',
                                            color: '#D9D9D9',
                                        }}
                                    >
                                        <SearchOutlined />
                                    </Button>
                                }
                            />
                        </Space>
                    </Col>
                    <Col xs={24} sm={2} md={2} lg={2} xl={2}>
                        <Form.Item></Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};
