import React, {useEffect, useState} from 'react'
import {Modal as AntModal, Typography, Form, Row, Col, Input} from 'antd'
import {
    Button,
    validateMessages,
} from '@presentation/components/form'
import style from './style'
import schedule from '@/@core/modules/schedule/infra/container.registry'
import { TProcedureUpdate } from '@/@core/modules/schedule/domain/schedule.entities'
import {Notification} from '@presentation/components/common'

export interface IReceiverProps {
    visible: boolean
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
    data?: any
    setReload: (arg: boolean) => void
}

const ProcedureValueUpdate = (props: IReceiverProps) => {
    const [isSubmit, setIsSubmit] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [processValue, setProcessValue] = useState('0,00')

    const handleTimeout = () => {
        setIsSubmit(true);

        setTimeout(() => {
            setIsSubmit(false);
        }, 5000); // 5 seconds
      };

    async function handleUpdateValue() {

        setLoading(true)

        try{

            let valueBase = parseFloat(props?.data?.amountFeesTable.replace('R$', '').trim()) || 0;

            const hour = props?.data?.hourSchedule

            if (hour < 6 || hour > 19) {
                valueBase = parseFloat(props?.data?.amountSpecialHourFeesTable.replace('R$', '').trim()) || 0;
            }

            const floatValue = parseFloat(processValue.replace(',', '.'))

            if (floatValue >= valueBase) {
                const dataUpdate: TProcedureUpdate = {
                    id: props?.data?.id,
                    amount: floatValue,
                    updatedBySecretary: true
                }

                const res = await schedule.update.execute(dataUpdate)

                if (res) {
                    Notification.success({
                        message: 'Valor atualizado com sucesso!',
                        duration: 2,
                    })
                    props.setReload(true)
                    props.setVisible(false)
                } else {
                    Notification.error({
                        message: 'Não é possível alterar o valor!',
                        duration: 2,
                    })
                }
            } else {
                Notification.error({
                    message: 'O valor informado está abaixo do permitido!',
                    duration: 2,
                })
            }

        }catch(e){
            Notification.error({
                message: 'Não é possível alterar o valor!',
                duration: 2,
            })
        }

        setLoading(false)
    }

    useEffect(()=>{
        if (props?.data !== null && props?.data != undefined){

            const totalWithoutCurrency = props?.data?.total.replace(/[^\d,]/g, '')
            setProcessValue(totalWithoutCurrency)
        }
    },[props.data])

    return (
        <AntModal
            data-testid="modal-form"
            destroyOnClose={true}
            forceRender
            title={
                <Typography.Title style={style.description}>
                    Atualizar o valor do Procedimento
                </Typography.Title>
            }
            visible={props.visible}
            centered={true}
            onOk={() => props.setVisible(!props.visible)}
            onCancel={() => props.setVisible(!props.visible)}
            footer={[
                <Button
                    text="Cancelar"
                    key="cancelar"
                    nativeProps={{
                        htmlType: 'submit',
                        ghost: true,
                        size: 'middle',
                        loading: loading ?? isSubmit,
                    }}
                    onClick={() => props.setVisible(false)}
                />,
                <Button
                    key="salvar"
                    text="Salvar"
                    nativeProps={{
                        htmlType: 'submit',
                        type: 'primary',
                        size: 'middle',
                        loading: loading ?? isSubmit,
                    }}
                    onClick={async () => await handleUpdateValue()}
                />
            ]}
        >
            <Form
                layout="horizontal"
                validateMessages={validateMessages}
                style={{
                    textAlign: 'center',
                }}
            >
                <Row style={{marginTop: -20, marginLeft: -10}}>
                    <Col span={24} style={{padding: 20}}>
                        <Form.Item
                            label="Valor"
                            labelCol={{span: 24}}
                        >
                            <Input
                                style={{
                                    height: 40,
                                    textTransform: 'uppercase',
                                    marginTop: -15
                                }}
                                type={'text'}
                                placeholder="0,00"
                                onChange={(e) => {
                                    const value = e.target.value
                                        .replace(/[^\d,.]/g, '')
                                        .replace(/(\..*)\./g, '$1')
                                    setProcessValue(value)
                                }}
                                value={processValue}
                            />
                        </Form.Item>
                    </Col>
                </Row>

            </Form>

        </AntModal>
    )
}

export {ProcedureValueUpdate}
