import { TGetUnitResponse } from "../domain/portal-unit.entities";
import { UnitGateway } from "../domain/unit.gateway";

export class ListUnitUseCase {
    constructor(private gate: UnitGateway) {}

    async execute(): Promise<TGetUnitResponse> {
        return this.gate.listUnits();
    }
}
