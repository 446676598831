import 'reflect-metadata';
import { Container } from "inversify";
import { ProcedureHttpGateway } from "./procedure.http.gateway";
import { httpApi, mockApi } from "../../common/infra/http";
import { CreateUseCase } from "../application/create.use.case";
import { MedicalProcedureUseCase } from '../application/medicalProcedure.use.case';
import { UpdateUseCase } from '../application/update.use.case';

export const Registry = {
    ProcedureGeteway: Symbol.for('ProcedureGeteway'),
    AxiosAdapter: Symbol.for('AxiosAdapter'),
    MockAdapter: Symbol.for('MockAdapter'),

    CreateUseCase: Symbol.for('CreateUseCase'),
    UpdateUseCase: Symbol.for('UpdateUseCase'),
    MedicalProcedureUseCase: Symbol.for('MedicalProcedureUseCase'),
};

export const container = new Container();

//########## HTTP
container.bind(Registry.AxiosAdapter).toConstantValue(httpApi);
container.bind(Registry.MockAdapter).toConstantValue(mockApi);

//#region ########## GATEWAYS
container.bind(Registry.ProcedureGeteway).toDynamicValue((context) => {
    return new ProcedureHttpGateway(
        context.container.get(Registry.AxiosAdapter),
        context.container.get(Registry.MockAdapter),
    );
});
//#endregion

//#region ########## USE CASES
container.bind(Registry.CreateUseCase).toDynamicValue((context) => {
    return new CreateUseCase(context.container.get(Registry.ProcedureGeteway));
});
container.bind(Registry.UpdateUseCase).toDynamicValue((context) => {
    return new UpdateUseCase(context.container.get(Registry.ProcedureGeteway));
})
container.bind(Registry.MedicalProcedureUseCase).toDynamicValue((context) => {
    return new MedicalProcedureUseCase(context.container.get(Registry.ProcedureGeteway));
});
//#endregion

//#region ######### CONTAINERS GET
const procedure = {
    create: container.get<CreateUseCase>(Registry.CreateUseCase),
    update: container.get<UpdateUseCase>(Registry.UpdateUseCase),
    medicalProcedure: container.get<MedicalProcedureUseCase>(Registry.MedicalProcedureUseCase),
};
export default procedure;
//#endregion