import 'reflect-metadata';
import {Container} from 'inversify';
import {httpApi, mockApi} from '../../common/infra/http';

import {PatientHttpGateway} from './patient.http.gateway';
import {FindUseCase} from '../application/find.use.case';

export const Registry = {
  AxiosAdapter: Symbol.for('AxiosAdapter'),
  MockAdapter: Symbol.for('MockAdapter'),

  IPatientGateway: Symbol.for('IPatientGateway'),
  FindUseCase: Symbol.for('FindUseCase'),
};

export const container = new Container();

//########## HTTP
container.bind(Registry.AxiosAdapter).toConstantValue(httpApi);
container.bind(Registry.MockAdapter).toConstantValue(mockApi);

//#region ########## GATEWAYS
container.bind(Registry.IPatientGateway).toDynamicValue(context => {
  return new PatientHttpGateway(
    context.container.get(Registry.AxiosAdapter),
  );
});
//#endregion

//#region ########## USE CASES
container.bind(Registry.FindUseCase).toDynamicValue(context => {
  return new FindUseCase(context.container.get(Registry.IPatientGateway));
});
//#endregion

//#region ######### CONTAINERS GET
export const patient = {
  searchByCpf: container.get<FindUseCase>(Registry.FindUseCase),
};
//#endregion
