import {ApplicationInsights, ITelemetryItem} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';

const OBSERVABILITY_KEY = import.meta.env.VITE_OBSERVABILITY_KEY;
const plugin = new ReactPlugin() as any;

const observability = () => {
    if (OBSERVABILITY_KEY) {
        const appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: OBSERVABILITY_KEY,
                extensions: [plugin],
                extensionConfig: {},
                enableAutoRouteTracking: true,
                disableAjaxTracking: false,
                autoTrackPageVisitTime: true,
                enableRequestHeaderTracking: true,
                enableResponseHeaderTracking: true,
            },
        });

        appInsights.loadAppInsights();

        appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
            env.tags = env.tags || [];
            env.tags['ai.cloud.role'] = 'web-mip';
        });
    }
};

export {plugin, observability};
