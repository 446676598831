import React from 'react';
import {Form, Layout, Popover, Typography, Space, Avatar, Divider} from 'antd';
import {useNavigate} from 'react-router-dom';
import type {ColumnsType} from 'antd/es/table';
import {
    List,
    DataType,
    getFieldsForFilter,
    getPageAndPerPage,
    ResetPassword,
} from '@presentation/components/form';
import {useAuthentication} from '@presentation/modules/authentication/contexts/authentication';
import {formattedRoute, routes} from '../../routes';
import {Notification} from '@presentation/components/common';
import user from '@core/modules/user/infra/container.registry';
import {User} from '@core/modules/user/domain/user.entities';
import word from '@core/modules/common/infra/word';
import {getType, ENUM_TYPES} from '@presentation/modules/route/helpers';

const {Link} = Typography;

const UserList = () => {
    const context = useAuthentication();
    const navigate = useNavigate();
    const [modalVisible, setModalVisible] = React.useState(false);
    const [params, setParams] = React.useState<User>();
    const [loading, setLoading] = React.useState(false);
    const [isReload, setIsReload] = React.useState(false);
    const [visible, setVisible] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [typeUser, setTypeUser] = React.useState<ENUM_TYPES | undefined>();

    const permissionRole = (record: User) => {
        switch (typeUser) {
            case ENUM_TYPES.backoffice:
                return true;
            case ENUM_TYPES.surgeon:
                if (record?.surgeons?.filter((item) => item.personId === context.user?.id)) {
                    return true;
                }
                return false;
            case ENUM_TYPES.secretary:
                if (context.user?.accessId === record?.person?.accessId) {
                    return true;
                }
                return false;
            default:
                return false;
        }
    };

    React.useEffect(() => {
        const fetchData = async () => {
            if (!context.user) return;
            const type = await getType(context.user);
            setTypeUser(type);
        };

        fetchData();
    }, [context.user]);

    const options = {
        value: 'search',
        name: 'Nome',
        placeholder: 'Pesquisar por usuário...',
    };

    const changeResetPassword = async (email?: string) => {
        if (!email) {
            Notification.error({
                message: 'Usuários não possui e-mail para envio do código!',
            });
            return;
        }
        setEmail(email);
        setVisible(!visible);
    };

    async function loadData(params?: any) {
        const result = await user.getAll.execute({
            ...getPageAndPerPage(params),
            ...getFieldsForFilter(params),
        });

        return result;
    }

    const content = (record: User) => {
        const permitted = permissionRole(record);

        if (!permitted)
            return (
                <Space direction="vertical">
                    <Link style={{color: '#A1A2A2'}}>
                        Você possui permissão de alterar apenas seus dados!
                    </Link>
                </Space>
            );
        return (
            <Space direction="vertical">
                <Link
                    style={{color: '#000000'}}
                    onClick={() => navigate(formattedRoute(routes.USER_EDIT, record.id))}
                    target="_blank"
                >
                    Editar
                </Link>
                <Divider style={{margin: 0, padding: 0}} />
                <Link
                    style={{color: '#000000'}}
                    onClick={() => changeResetPassword(record?.person?.email)}
                    target="_blank"
                >
                    Recuperar senha
                </Link>
            </Space>
        );
    };

    const columns: ColumnsType<DataType> = [
        {
            title: 'Nome ',
            sortDirections: ['descend'],
            width: '40%',
            render: (record) => (
                <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                    <Popover
                        content={content(record)}
                        placement="bottom"
                        trigger="click"
                        overlayStyle={{
                            border: '1.5px solid #D9D9D9',
                            borderRadius: 13,
                            backdropFilter: 'blur(2px)',
                        }}
                    >
                        <img src="/menu-points.svg" alt="Points" style={{height: 16, width: 12}} />
                    </Popover>
                    <Avatar size={38} shape='square'>
                        {word.defaultName(word.getFirstTwoLetters(record?.person?.name ?? ''))}
                    </Avatar>
                    <>{record?.person?.name}</>
                </div>
            ),
        },
        {
            title: 'Email ',
            dataIndex: 'person',
            render: (record) => <>{record.email}</>,
            defaultSortOrder: 'descend',
            width: '40%',
            responsive: ['sm'],
        },
        {
            title: 'Telefone',
            dataIndex: 'person',
            render: (record) => <>{record.phone}</>,
            defaultSortOrder: 'descend',
            width: '20%',
            responsive: ['sm'],
        },
    ];

    return (
        <Layout style={{backgroundColor: 'red'}}>
            {visible && <ResetPassword email={email} setVisible={setVisible} visible={visible} />}
            <List
                isAdd={true}
                onAdd={() => navigate(formattedRoute(routes.USER_CREATE))}
                columns={columns}
                loadData={loadData}
                Form={Form}
                setLoading={setLoading}
                loading={loading}
                options={options}
                setModalVisible={setModalVisible}
                modalVisible={modalVisible}
                setParams={setParams}
                params={params}
                setReload={setIsReload}
                isReload={isReload}
                hideDataFilter={true}
            />
        </Layout>
    );
};

export default UserList;
