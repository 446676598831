import React from "react";
import { Row, Typography } from 'antd';

import ChartIcon from '../../../../../public/icons/charts.svg';
import { Button } from "@/@presentation/components/form";

interface IProps {
    title: string;
    value: number;
    onClick?: () => void;
};

export const Card: React.FC<IProps> = (props: IProps) => {
    return (
        <div
            style={{
                backgroundColor: 'white',
                borderRadius: 8,
                boxShadow: '7px 3px 17px 3px rgba(0, 0, 0, 0.04)',
                padding: 16,
                marginBottom: 16,
                width: '100%',
            }}
        >
            <Row style={{ marginBottom: 5 }}>
                <img src={ChartIcon} style={{ width: 40, height: 40 }} />
            </Row>
            <Row style={{ marginBottom: 5 }}>
                <Typography.Text style={{ fontSize: 16, fontWeight: 400 }}>{props.title}</Typography.Text>
            </Row>
            <Row style={{ marginBottom: 5 }}>
                <Typography.Text style={{ color: '#1BA7CE', fontSize: 28, fontWeight: 500 }}>{props.value}</Typography.Text>
            </Row>
            <Row style={{ marginTop: 15 }}>
                <Button
                    type="primary"
                    nativeProps={{ ghost: true }}
                    text="Ver detalhes"
                    onClick={props.onClick}
                />
            </Row>
        </div>
    );
};