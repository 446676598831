import {Form as AntForm, Select} from 'antd';

const {Option} = Select;

export enum PersonType {
    // BACKOFFICE = 'BACKOFFICE',
    // SURGEON = 'SURGEON',
    SECRETARY = 'SECRETARY',
    // ANESTHETIST = 'ANESTHETIST',
    // PATIENT = 'PATIENT',
}

export const formatPersonType = (value: PersonType): string => {
    switch (value) {
        // case PersonType.BACKOFFICE:
        //     return 'Backoffice';
        // case PersonType.SURGEON:
        //     return 'Cirurgião';
        case PersonType.SECRETARY:
            return 'Secretário(a)';
        // case PersonType.ANESTHETIST:
        //     return 'Anestesista';
        // case PersonType.PATIENT:
        //     return 'Paciente';
        default:
            return 'TIPO DESCONHECIDO';
    }
};

const personTypeOptions = Object.values(PersonType).map((type) => ({
    label: formatPersonType(type),
    value: type,
}));

const SelectUserType = () => {
    return (
        <AntForm.Item name="personType" label="Tipo" rules={[{required: true}]}>
            <Select
                placeholder="Tipo"
                data-testid="select-user-personType"
                style={{
                    minWidth: '100%',
                    maxWidth: '100%',
                    height: 40,
                }}
            >
                {personTypeOptions.map((option) => (
                    <Option key={option.value} value={option.value}>
                        {option.label}
                    </Option>
                ))}
            </Select>
        </AntForm.Item>
    );
};

export {SelectUserType};
