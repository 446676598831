export type UserType = {
    id: number;
    email: string;
    name: string;
    area: string | null;
    occupation: string | null;
    rg: string | null;
    cpf: string | null;
    type: string;
    nationality: string | null;
    birthdayDate: string | null;
    gender: string;
    hasChildren: boolean | null;
    accessId: number;
    imageUploadId: number | null;
    juridicalPersonId: number | null;
    createdAt: string;
    updatedAt: string;
    profiles: ProfileType[];
};

export type ProfileType = {
    id: number;
    name: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
    permissions: PermissionType[];
};

export type PermissionType = {
    id: number;
    key: string;
    module: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
};

export type TIsPermitted = {
    path?: string;
    role?: 'read' | 'view' | 'write' | 'delete' | 'test';
};

export class UserInfos {
    constructor(public props: UserType) {}

    get id() {
        return this.props.id;
    }

    get email() {
        return this.props.email;
    }

    get name() {
        return this.props.name;
    }

    get type() {
        return this.props.type;
    }

    get accessId() {
        return this.props.accessId;
    }

    get profiles() {
        return this.props.profiles;
    }

    get area() {
        return this.props.area;
    }

    get occupation() {
        return this.props.occupation;
    }

    get rg() {
        return this.props.rg;
    }

    get cpf() {
        return this.props.cpf;
    }

    get nationality() {
        return this.props.nationality;
    }

    get birthdayDate() {
        return this.props.birthdayDate;
    }

    get gender() {
        return this.props.gender;
    }

    get hasChildren() {
        return this.props.hasChildren;
    }

    get imageUploadId() {
        return this.props.imageUploadId;
    }

    get juridicalPersonId() {
        return this.props.juridicalPersonId;
    }

    get createdAt() {
        return this.props.createdAt;
    }

    get updatedAt() {
        return this.props.updatedAt;
    }

    toJSON() {
        return {
            id: this.id,
            email: this.email,
            name: this.name,
            type: this.type,
            accessId: this.accessId,
            area: this.area,
            occupation: this.occupation,
            rg: this.rg,
            cpf: this.cpf,
            nationality: this.nationality,
            birthdayDate: this.birthdayDate,
            gender: this.gender,
            hasChildren: this.hasChildren,
            imageUploadId: this.imageUploadId,
            juridicalPersonId: this.juridicalPersonId,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            profiles: this.profiles,
        };
    }
}
