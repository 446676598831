export function validateCPF(cpf: string): boolean {
   cpf = cpf.replace(/\D/g, '');
   if (cpf.length !== 11) return false;

   if (/^(\d)\1+$/.test(cpf)) return false;

   let sum = 0;
   for (let i = 0; i < 9; i++) {
       sum += parseInt(cpf.charAt(i)) * (10 - i);
   }
   let remainder = 11 - (sum % 11);
   const digit1 = remainder > 9 ? 0 : remainder;
   if (parseInt(cpf.charAt(9)) !== digit1) return false;

   sum = 0;
   for (let i = 0; i < 10; i++) {
       sum += parseInt(cpf.charAt(i)) * (11 - i);
   }
   remainder = 11 - (sum % 11);
   const digit2 = remainder > 9 ? 0 : remainder;
   if (parseInt(cpf.charAt(10)) !== digit2) return false;

   return true;
}