import {TResetPassword} from '../domain/surgeon.entities';
import {SurgeonGateway} from '../domain/surgeon.gateway';

export class ResetPasswordUseCase {
    constructor(private gate: SurgeonGateway) {}

    async execute(params: TResetPassword): Promise<boolean> {
        return this.gate.resetPassword(params);
    }
}
