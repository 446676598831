import units from "@/@core/modules/unit/infra/container.registry";
import { Notification } from "@/@presentation/components/common";
import { useProcedure } from "@/@presentation/modules/procedure/context/procedure";
import { Button, Modal, Select } from "antd";
import { duration } from "moment";
import React from "react";

interface IModal {
  setIsReload(arg0: boolean): unknown;
  isReload: boolean;
  setVisible: (visible: boolean) => void,
  visible: boolean,
}

export const ModalUnit: React.FC<IModal> = (props) => {
  const {
    states,
    actions: { handleInitLoadSearchGeneral },
  } = useProcedure();
  
  const [loadingModal, setLoadingModal] = React.useState(false);
  const [localeFilter, setLocaleFilter] = React.useState<any | undefined>(states?.procedureLocation)
  const [locale, setLocale] = React.useState<any | undefined>(undefined);

  const handleFilterSelect = (text: string) => {
    setLocaleFilter(
      states?.procedureLocation?.filter(
        (pl: any) => pl.label.toLowerCase().includes(text.toLowerCase())
      )
    )
  }

  const addUnit = () => {
    setLoadingModal(true);
    units.unit.create.execute({
      unit_id: locale,
    }).then(() => {
      setLoadingModal(false);
      props.setVisible(false);
      setLocale(undefined);
      props.setIsReload(!props.isReload);
    }).catch((e) => {
      if (e.message.message) {
        Notification.error({
          message: e.message.message,
          duration: 3,
        })
      }
      setLocale(undefined);
      setLoadingModal(false);
    });
  };

  const footer = [
    <Button
      key="back"
      className="btn btn-secondary"
      onClick={() => props.setVisible(false)}
      loading={loadingModal}
    >
      Cancelar
    </Button>,
    <Button
      key="submit"
      type='primary'
      onClick={addUnit}
      loading={loadingModal}
      disabled={!locale}
    >
      Adicionar
    </Button>,

  ];

  React.useEffect(() => {
    // if (states.procedureLocation.length === 0 && props.visible) {
        handleInitLoadSearchGeneral();
        setLocaleFilter(states?.procedureLocation)
    // }
}, [props.visible]);

  return (
    <Modal
      title="Adicionar unidade ao portal"
      open={props.visible}
      footer={footer}
    >
      <div style={{
        display: 'flex',
        flexDirection: 'column',
      }}>
        <label>Selecione uma unidade</label>
        <Select
          style={{ borderRadius: 8 }}
          placeholder={'Unidade'}
          value={locale}
          onChange={(e) => setLocale(e)}
          onSearch={(e) => handleFilterSelect(e)}
          filterOption={false}
          showSearch
        >
          {
            localeFilter?.map((pl: any, index: any) => (
              <Select.Option key={index} value={pl.value}>{pl.label}</Select.Option>
            ))
          }
        </Select>
      </div>
    </Modal>
  );
}
