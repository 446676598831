import { TRemoveUnitRequest, TGetUnitResponse } from "../domain/portal-unit.entities";
import { UnitGateway } from "../domain/unit.gateway";

export class DeleteUnitUseCase {
    constructor(private unitGateway: UnitGateway) {}

    async execute(data: TRemoveUnitRequest): Promise<TGetUnitResponse> {
        return this.unitGateway.deleteUnit(data);
    }
}
