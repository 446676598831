import { Container } from "inversify";
import { GetListUseCase } from "../application/get-list.use.case";
import { ProcessUseCase } from "../application/process.use.case";
import { httpApi } from "../../common/infra/http";
import { conciliatorHttpGateway } from "./conciliator.http.gateway";

export const Registry = {
    AxiosAdapter: Symbol.for('AxiosAdapter'),
    ConciliatorGateway: Symbol.for('ConciliatorGateway'),
    GetListUseCase: Symbol.for('GetListUseCase'),
    ProcessUseCase: Symbol.for('ProcessUseCase'),
};

export const container = new Container();

//########## HTTP
container.bind(Registry.AxiosAdapter).toConstantValue(httpApi);

//#region ########## GATEWAYS
container.bind(Registry.ConciliatorGateway).toDynamicValue((context) => {
    return new conciliatorHttpGateway(context.container.get(Registry.AxiosAdapter));
});
//#endregion

//#region ########## USE CASES
container.bind(Registry.GetListUseCase).toDynamicValue((context) => {
    return new GetListUseCase(context.container.get(Registry.ConciliatorGateway));
});

container.bind(Registry.ProcessUseCase).toDynamicValue((context) => {
    return new ProcessUseCase(context.container.get(Registry.ConciliatorGateway));
});
//#endregion

//#region ######### CONTAINERS GET
const conciliator = {
    getList: container.get<GetListUseCase>(Registry.GetListUseCase),
    process: container.get<ProcessUseCase>(Registry.ProcessUseCase),
};

export default conciliator;
//#endregion