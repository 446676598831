import Modal from "@/@presentation/components/common/modal";
import { Button } from "@/@presentation/components/form";
import { styles } from "@/@presentation/modules/procedure/pages/form/styles";
import { Form as AntForm, DatePicker, TimePicker } from "antd";
import dayjs from "dayjs";
import React from "react";

interface IRescheduleModal {
  onReschedule: (isReschedule: boolean, date?: string, time?: string) => void;
  open: boolean;
  initialDate: string;
}

const RescheduleModal: React.FC<IRescheduleModal> = ({ onReschedule, open, initialDate }) => {
  const [form] = AntForm.useForm();

  const handleSubmit = () => {
    form.validateFields()
      .then(values => {
        const { procedureDate, procedureTime } = values;
        const date = procedureDate.format('DD/MM/YYYY');
        const time = procedureTime.format('HH:mm');
        onReschedule(true, date, time);
        form.resetFields();
      })
      .catch(() => {
        return;
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onReschedule(false);
  }

  const footerRescheduleModal: React.ReactNode[] = [
    <Button text="Voltar" key='rescheduleReturn' onClick={handleCancel} nativeProps={{  ghost: true }}/>,
    <Button text="Reagendar" key='rescheduleSave' onClick={handleSubmit}/>,
  ];

  React.useEffect(() => {
    form.setFieldValue("procedureDate", dayjs(initialDate));
    form.setFieldValue("procedureTime", dayjs(initialDate));
  }, [])

  return (
    <Modal
      footer={footerRescheduleModal}
      open={open}
      title='Reagendar procedimento'
    >
      <AntForm
        form={form}
        layout="vertical"
        autoComplete="off"
      >
        <AntForm.Item
          name='procedureDate'
          label="Data do procedimento"
          style={{ marginRight: 40 }}
          rules={[{ required: true, message: 'Por favor, selecione a data do procedimento' }]}
        >
          <DatePicker
            style={{ ...styles.defaultInput, borderRadius: 8 }}
            format="DD/MM/YYYY"
            allowClear={false}
          />
        </AntForm.Item>
        <AntForm.Item
          name='procedureTime'
          label="Horário"
          style={{ marginRight: 40 }}
          rules={[{ required: true, message: 'Por favor, selecione o horário' }]}
        >
          <TimePicker
            format="HH:mm"
            suffixIcon={null}
            allowClear={false}
            style={{
              borderRadius: 8,
              border: '1px solid #d9d9d9',
              height: 40,
            }}
          />
        </AntForm.Item>
      </AntForm>
    </Modal>
  );
};

export default RescheduleModal;
