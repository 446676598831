const title = {
    color: '#262A41',
    fontSize: 34,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '106%',
    marginTop: 14,
    marginBottom: 18,
    letterSpacing: 0.73,
};

const subTitle = {
    color: '#262A41',
    fontSize: 26,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '106%',
    marginBottom: 14,
    letterSpacing: 0.73,
};

const dataTitle = {
    color: '#262A41',
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '90%',
    marginTop: 4,
    marginBottom: 2,
    letterSpacing: 0.73,
};

export default {
    title,
    subTitle,
    dataTitle,
};
