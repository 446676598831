import {Typography} from 'antd';

export const textFormat = (value: string, deleted_at?: Date | undefined) => {
    if (!deleted_at) return value;
    const {Text} = Typography;
    return (
        <Text delete type="danger">
            {value}
        </Text>
    );
};

export const textDateTimeFormat = (value: string) => {
    if (!value) return value;
    return new Intl.DateTimeFormat('pt-BR', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
    }).format(new Date(value));
};
