import { InvoiceGateway } from "../domain/invoice.gateway";

export class CreateUseCase {
    constructor(private gate: InvoiceGateway) {}

    async execute(params: InvoiceInterface[]): Promise<any> {
        const updatedParams: InvoiceInterface[] = params 
        return this.gate.create(updatedParams);
    }
}
