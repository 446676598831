import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Form, Layout, Modal, notification, Row, Select, Space, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { LoadingOutlined } from '@ant-design/icons';
import { useItemsContext } from '@/@presentation/contexts/schedulesContext';
import { Notification } from '@presentation/components/common';
import { ModalConfirmationNf } from './ModalConfirmationNf';
import { Receiver } from '../home/receiver';
import invoice from '@/@core/modules/invoice/infra/container.registry';
import { ModalConfirmationPayment } from './ModalConfirmationPayment';
import ModalError from '@/@presentation/modules/procedure/pages/form/components/modal';
import { Button } from '@/@presentation/components/form';
import customer from '@/@core/modules/customer/infra/container.registry';
import { CreateCustomerApi } from '@/services/customerUpdateApi';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface paymentOptionsInterface {
    label: string,
    value: string
}
export interface UpdateCustomerInterface {
    id?: number,
    personId?: number,
    scheduleId: number[],
    name: string
    email: string
    registry_code: string
    code: string
    address: {
        id?: number
        street: string
        number: string
        additional_details: string
        zipcode: string
        neighborhood: string
        city: string
        state: string
    }
}
const NfProcess = () => {

    const navigate = useNavigate()

    const { items, setItems } = useItemsContext()
    const [loading, setLoading] = React.useState(false)
    const [defineValues, setDefineValues] = useState(false)
    const [defaultInfo, setDefaultInfo] = useState('')
    const [intallentNumber, setIntallentNumber] = useState(0)
    const [doc, setDoc] = useState('')

    const [customerSearched, setCustomerSearched] = useState(null);

    const [nfError, setNfError] = useState({ open: false, message: '' })

    const [receiverVisible, setReceiverVisible] = useState(false)

    const [paymentType, setPaymentType] = useState('')

    const [error, setError] = useState(false)

    const [paymentOptions, setPaymentOptions] = useState<paymentOptionsInterface[]>([])
    const [installmentsNumber, setInstallmensNumber] = useState<paymentOptionsInterface[]>([])

    const [showConfirmation, setShowConfirmation] = useState(false)
    const [showConfirmationPayment, setShowConfirmationPayment] = useState(false)

    const [itemBase, setItemBase] = useState<any>()

    useEffect(() => {
        handleDefineValues()
    }, [doc])

    useEffect(() => {

        if (items[0]?.installments_number === 0 || items[0]?.installments_number === undefined) {

            setPaymentOptions([
                { label: 'PIX', value: 'pix' },
                { label: 'BOLETO', value: 'bank_slip' },
            ])

        } else {

            setPaymentOptions([
                { label: 'BOLETO', value: 'bank_slip' },
                { label: 'PIX', value: 'pix' },
                { label: 'CARTÃO DE CRÉDITO', value: 'credit_card' }
            ])

            const newInstallments = [];
            for (let i = 1; i <= items[0].installments_number; i++) {
                newInstallments.push({ label: `${i}`, value: `${i}` });
            }

            setInstallmensNumber(newInstallments)
        }

        if (items[0]?.methodCode !== '') {
            setPaymentType(items[0]?.methodCode ?? '')
            setIntallentNumber(items[0]?.paymentInstallmentsNumber ?? 0)
        }

        setItemBase(items[0])

        handleDefineValues()

    }, [items])

    const taxDeduction = (grossValue: number) => {
        let tax1 = Math.round(grossValue * 0.0465 * 100) / 100;
        let tax2 = Math.round(grossValue * 0.0150 * 100) / 100;

        tax1 = tax1 < 10 ? 0 : tax1;
        tax2 = tax2 < 10 ? 0 : tax2;

        const totalTax = tax1 + tax2;
        const netValue = Math.round((grossValue - totalTax) * 100) / 100;

        return netValue;
    }

    function handleDefineValues() {
        
        setDefineValues(true);

        const txtHeader = 'Serviço Médico de Anestesia Prestados ao paciente:';
        const txtFooter = '[1]Valor aproximado dos tributos 16,70%.\nConforme Lei nº 12741 de 08.12.2012';

        const groupedByPatient = items.reduce((acc, item) => {
            const { anesthetist, patient, procedureDate, anesthetistCrm } = item;

            if (!acc[patient]) {
                acc[patient] = [];
            }
            acc[patient].push({ anesthetist, procedureDate, anesthetistCrm });
            return acc;
        }, {});

        // Formata os dados conforme o formato solicitado
        const formattedData = Object.entries(groupedByPatient).map(([patient, procedures]) => {
            return procedures.map((procedure: any) => {
                const { procedureDate, anesthetist, anesthetistCrm } = procedure;
                const formattedPatient = `${patient.toUpperCase()} - Data: ${procedureDate}`;
                const formattedAnesthetist = `Anestesista:\nDr.: ${anesthetist}\nCRM.: ${anesthetistCrm}`;
                return `${formattedPatient}\n${formattedAnesthetist}`;
            }).join('\n\n');
        }).join('\n\n');

        const totalValue = items.reduce((acc, item) => {
            if (!item.total) {
                return acc;
            }

            const totalWithoutCurrency = item.total.replace(/[^\d,]/g, '');
            const totalWithDot = totalWithoutCurrency.replace(',', '.');

            const parsedTotal = parseFloat(totalWithDot);
            return isNaN(parsedTotal) ? acc : acc + parsedTotal;
        }, 0);

        const formattedTotal = totalValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        const companyValue = taxDeduction(totalValue).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

        const formatedDocument = doc?.replace(/\D/g, '')//payerCustomer?.code.replace(/\D/g, '')
        const isCompany = formatedDocument?.length > 11;
        
        // Adiciona os valores bruto e líquido se for uma empresa
        const companyText = isCompany 
            ? `\n\n\nValor bruto: ${formattedTotal}\nValor líquido: ${companyValue}\n\n`
            : '';

        const fullText = `${txtHeader}\n\n${formattedData}\n\n${companyText}\n${txtFooter}`;

        setDefaultInfo(fullText);
    }
    
    async function handleRequestNf() {

        const customerInfo = await customer.search.execute(itemBase?.paymentCustomer?.registry_code)
        setCustomerSearched(customerInfo)

        const isFieldEmpty = (field: any) => field === null || field === undefined || field === '';

        const customerAddress = customerInfo?.payment_customer?.address ?? {};
        const customerPerson = customerInfo?.payment_customer?.person ?? {};

        const requiredFields = {
            city: customerAddress.city,
            street: customerAddress.street,
            number: customerAddress.number,
            neighborhood: customerAddress.neighborhood,
            zipcode: customerAddress.zipcode,
            name: customerPerson.name,
            cpf: customerPerson.cpf,
            email: customerPerson.email, // Add email to required fields
        };

        // Mapping field keys to their corresponding Portuguese labels
        const fieldLabels = {
            city: 'Cidade',
            street: 'Rua',
            number: 'Número',
            neighborhood: 'Bairro',
            zipcode: 'CEP',
            name: 'Nome',
            cpf: 'CPF',
            email: 'Email', // Add email to field labels
        };

        const missingFields = Object.entries(requiredFields)
            .filter(([key, value]) => isFieldEmpty(value))
            .map(([key]) => fieldLabels[key as keyof typeof fieldLabels]); // Map to Portuguese labels

        if (missingFields?.length > 0) {
            Notification.error({
                message: `Os seguintes campos obrigatórios estão vazios: ${missingFields.join(', ')}`,
                duration: 5,
            });
            return;
        }


        if (itemBase.paymentCustomer.id === 0) {
            const newCustomer: UpdateCustomerInterface = {
                scheduleId: [itemBase.id],
                personId: customerInfo?.payment_customer.person.id,
                name: itemBase.paymentCustomer.name,
                email: itemBase.paymentCustomer.email,
                registry_code: itemBase.paymentCustomer.registry_code,
                code: itemBase.paymentCustomer.registry_code,
                address: {
                    id: customerInfo?.payment_customer.address.id,
                    street: customerInfo.payment_customer.address.street,
                    number: customerInfo.payment_customer.address.number,
                    additional_details: customerInfo.payment_customer.address.complement ?? '',
                    zipcode: customerInfo.payment_customer.address.zipcode,
                    neighborhood: customerInfo.payment_customer.address.neighborhood,
                    city: customerInfo.payment_customer.address.city,
                    state: customerInfo.payment_customer.address.state
                }
            };
            setDoc(newCustomer.registry_code)
            const result = await CreateCustomerApi(newCustomer);
        }


        //if (!itemBase.payment_customer?.id && customerInfo?.payment_customer?.id && customerSearched === null) {
        //    CreateCustomerApi({
        //        id: customerInfo?.payment_customer.id,
        //        personId: customerInfo?.payment_customer.person.id,
        //        scheduleId: itemBase.id,
        //        registry_code: itemBase.paymentCustomer.registry_code,
        //        code: itemBase.paymentCustomer.code,
        //        email: itemBase.paymentCustomer.email,
        //        name: itemBase.paymentCustomer.name,
        //        address: {
        //            id: customerInfo?.payment_customer.address.id,
        //            street: customerInfo?.payment_customer.address.street,
        //            number: customerInfo?.payment_customer.address.number,
        //            neighborhood: customerInfo?.payment_customer.address.neighborhood,
        //            city: customerInfo?.payment_customer.address.city,
        //            state: customerInfo?.payment_customer.address.state,
        //            zipcode: customerInfo?.payment_customer.address.zipcode,
        //            additional_details: customerInfo?.payment_customer.address.complement ?? '',
        //        },
        //    })
        //}

        const customerName = customerInfo?.payment_customer?.person?.name ?? ''
        const customerEmail = customerInfo?.payment_customer?.person?.email ?? ''
        const customerCpf = itemBase?.paymentCustomer?.registry_code ?? ''
        setDoc(customerCpf)
        const customerStreet = customerInfo?.payment_customer?.address?.street ?? ''
        const customerNumberHome = customerInfo?.payment_customer?.address?.number ?? ''
        const customerCep = customerInfo?.payment_customer?.address?.zipcode ?? ''

        if (customerName?.length === 0 ||
            customerEmail?.length === 0 ||
            customerCpf?.length === 0 ||
            customerStreet?.length === 0 ||
            customerNumberHome?.length === 0 ||
            customerCep?.length === 0
        ) {
            Notification.error({
                message: 'Por favor confirmar os campos obrigatórios do tomador!',
                duration: 5,
            });

            return
        } else if (paymentType?.length === 0 || defaultInfo?.length === 0) {
            setError(true)
            Notification.error({
                message: 'Por favor verifique a forma de pagamento e as Observações de encerramento!',
                duration: 3,
            });

        } else {
            setError(false)
            setShowConfirmation(true)
        }

    }

    async function handleConfirmation() {

        setLoading(true)

        const nfs: InvoiceInterface[] = items.map((item) => {
            return {
                schedule_id: item?.id,
                cpf: item?.paymentCustomer?.registry_code?.replace(/\D/g, ''),
                date: item?.scheduleDate,
                hospital: item?.unit,
                patient: item?.patient,
                text: defaultInfo,
                value: item?.total,
                email: item?.paymentCustomer?.email,
                method_code: paymentType,
                installments_number: intallentNumber ?? 0,
                payment_billing_id: item?.paymentBillingId ?? 0,
                schedule_surgery_id: item?.scheduleSurgeryId ?? 0
            }
        })

        const res = await invoice.create.execute(nfs) ?? [];

        const NFIndex = res.findIndex((item: any) => item.hasOwnProperty("NotaFiscal"));
        const nfRes = res[NFIndex]

        if (nfRes.NotaFiscal.status === 'success') {

            items[0].methodCode = paymentType
            items[0].paymentInstallmentsNumber = intallentNumber ?? 0
            setItems(items)

            Notification.success({
                message: 'Solicitação e NF realizada com sucesso!',
                duration: 3,
            });
            setShowConfirmation(false)

            let showConfirmationControl = false;

            items.forEach((item) => {
                console.log(item)
                item.methodCode = paymentType
                item.paymentInstallmentsNumber = intallentNumber
                if (item.statusBilling == 'allpending') {
                    showConfirmationControl = true;
                }
            });

            if (showConfirmationControl) {
                setShowConfirmationPayment(true);
            } else {
                navigate('/finance');
            }
        }

        if (nfRes.NotaFiscal.status === 'error') {
            setShowConfirmation(false)
            setNfError({
                open: true,
                message: nfRes.NotaFiscal.message
            })
            setShowConfirmation(false)
        }

        if (!nfRes) {
            notification.error({
                message: 'Erro ao solicitar NF, status de retorno não identificado!',
                duration: 3,
            })
        }

        setLoading(false)

    }

    const updateItemsValues = () => {

        items.forEach((item) => {
            item.paymentCustomer.city = itemBase?.paymentCustomer?.city
            item.paymentCustomer.country = itemBase?.paymentCustomer?.country
            item.paymentCustomer.email = itemBase?.paymentCustomer?.email
            item.paymentCustomer.id = itemBase?.paymentCustomer?.id
            item.paymentCustomer.name = itemBase?.paymentCustomer?.name
            item.paymentCustomer.neighborhood = itemBase?.paymentCustomer?.neighborhood
            item.paymentCustomer.number = itemBase?.paymentCustomer?.number
            item.paymentCustomer.registry_code = itemBase?.paymentCustomer?.registry_code
            item.paymentCustomer.state = itemBase?.paymentCustomer?.state
            item.paymentCustomer.street = itemBase?.paymentCustomer?.street
            item.paymentCustomer.zipcode = itemBase?.paymentCustomer?.zipcode
            item.paymentInstallmentsNumber = intallentNumber
        })
        setItems(items)
    }

    async function handleConfirmationPayment() {
        updateItemsValues()
        navigate('/paymentprocess', { state: { id: items[0].id, registry_code: doc } })
    }

    async function handleCancelPayment() {
        setShowConfirmationPayment(false)
        navigate('/finance')
    }

    return (

        <Layout
            data-testid="layout-schedule-view"
            style={{
                height: '80vh',
                backgroundColor: 'white',
            }}
        >

            <ModalError open={nfError.open} title='Erro ao confirmar nota fiscal' onOk={() => setNfError({ open: false, message: '' })} text={nfError.message} />

            <Content className="site-layout-content">
                <Content className="pd-24">
                    <Spin spinning={loading} indicator={antIcon} tip="Carregando..."></Spin>

                    <Row justify="start">
                        <Col xs={24} sm={22} md={22} lg={22} xl={22}>
                            <Space size="small" style={{ display: 'flex' }}></Space>
                        </Col>
                        <Col xs={24} sm={2} md={2} lg={2} xl={2}>
                            <Form.Item></Form.Item>
                        </Col>
                    </Row>

                    <Row style={{ height: 60, marginTop: -50 }}>
                        <p style={{
                            fontSize: 28,
                            fontStyle: 'normal',
                            fontWeight: 700,
                            lineHeight: '4',
                            marginLeft: '5%',
                            // color: '#054B7C'
                            color: '#101010'
                        }}>Nota Fiscal</p>
                    </Row>

                    <Row>
                        <p style={{
                            marginLeft: '5%',
                            marginBottom: 30,
                            fontSize: 18,
                            fontStyle: 'normal',
                            lineHeight: '4',
                            color: '#101010'
                        }}>Emitir nota fiscal</p>
                    </Row>

                    <Row style={{ marginBottom: 10, marginTop: -20 }}>
                        <label style={{ marginLeft: '5%', fontSize: 15, fontWeight: 'bold' }}>Tomador</label>
                    </Row>

                    <Row style={{ marginBottom: 30, marginTop: 10 }}>
                        <label style={{ marginLeft: '5%', fontSize: 15, backgroundColor: '#f5f5f5', paddingTop: 9, paddingBottom: 9, paddingLeft: 15, width: 500, borderRadius: 8 }}>{itemBase?.paymentCustomer?.name.toUpperCase()}</label>
                        <Button
                            nativeProps={{
                                ghost: true,
                                style: {
                                    marginLeft: 10
                                }
                            }}
                            text="Alterar Tomador"
                            onClick={() => setReceiverVisible(true)}
                        />
                    </Row>

                    <Row style={{ marginBottom: 20 }}>
                        <label style={{ marginLeft: '5%', fontSize: 15, fontWeight: 'bold' }}>Observação de NF</label>
                        <textarea
                            rows={20}
                            style={{
                                width: '90%',
                                marginLeft: '5%',
                                borderRadius: 8,
                                marginTop: 10,
                                marginBottom: 30,
                                padding: 15,
                                fontSize: 16,
                                resize: 'none',
                                borderColor: error ? '#ff0000' : '#054B7C',
                            }}
                            disabled={!defineValues}
                            onChange={(e) => setDefaultInfo(e.target.value)}
                            value={defaultInfo}
                        >
                        </textarea>
                    </Row>

                    <Row style={{ marginBottom: 10, marginTop: -20 }}>
                        <label style={{ marginLeft: '5%', fontSize: 15, fontWeight: 'bold' }}>Forma de pagamento</label>
                    </Row>

                    <Row style={{ marginLeft: '5%' }}>
                        <Form.Item
                            validateStatus={error ? 'error' : ''}
                        // help={error ? 'Por favor, selecione uma forma de pagamento' : null}
                        >
                            <Select
                                style={{
                                    height: 40,
                                    width: 400,
                                    marginBottom: 10
                                }}
                                placeholder="Selecione a forma de pagamento"
                                options={paymentOptions}
                                onChange={(e) => setPaymentType(e)}
                                value={paymentType === 'bank_slip' ? 'BOLETO' :
                                    paymentType === 'pix' ? 'PIX' :
                                        paymentType === 'credit_card' ? 'CARTÃO DE CRÉDITO' : ''}
                                disabled={items.filter((f) => f.advancePayment === true)?.length > 0 ||
                                    items[0]?.methodCode === 'pix' ||
                                    items[0]?.methodCode === 'bank_slip' ||
                                    items[0]?.methodCode === 'credit_card'
                                    ? true : false}
                            />
                        </Form.Item>
                    </Row>

                    <Row style={{ marginLeft: '5%', display: paymentType === 'credit_card' ? 'flex' : 'none' }}>
                        <Row style={{ marginBottom: 10, width: 200 }}>
                            <label style={{ fontSize: 15 }}>Quantidade de parcelas</label>
                        </Row>
                        <Row style={{ width: '100%' }}>

                            <Form.Item>
                                <Select
                                    style={{ height: 40, width: 200 }}
                                    placeholder="Selecione as parcelas"
                                    options={installmentsNumber}
                                    onChange={(e) => setIntallentNumber(e)}
                                    disabled={items.filter((f) => f.advancePayment === true)?.length > 0 ? true : false}
                                />
                            </Form.Item>
                        </Row>
                    </Row>
                    <Row style={{
                        bottom: 10,
                        display: 'flex',
                        justifyContent: 'flex-end',
                        paddingBottom: 40,
                        paddingRight: '5%'
                    }}>
                        <Button
                            text='Cancelar'
                            nativeProps={{
                                ghost: true,
                                style: {
                                    marginRight: 10
                                }
                            }}
                            onClick={() => navigate('/finance')}
                        />
                        <Button
                            text='OK'
                            onClick={() => handleRequestNf()}
                        />
                    </Row>
                </Content>
            </Content>

            <ModalConfirmationNf
                visible={showConfirmation}
                loading={loading}
                children={undefined} title={'Confirmar dados'}
                handleCancel={() => setShowConfirmation(false)}
                handleOk={async () => await handleConfirmation()}
                paymentCustomerName={itemBase?.paymentCustomer?.name.toUpperCase() || 'Não informado'}
                paymentType={paymentType === 'bank_slip' ? 'BOLETO' :
                    paymentType === 'pix' ? 'PIX' :
                        paymentType === 'credit_card' ? 'CARTÃO DE CRÉDITO' : 'NÃO INFORMADO'}
            />

            <ModalConfirmationPayment
                visible={showConfirmationPayment}
                children={undefined} title={'Realizar Pagamento'}
                handleCancel={() => handleCancelPayment()}
                handleOk={async () => await handleConfirmationPayment()}
            />

            <Receiver
                doc={doc}
                setDoc={setDoc}
                visible={receiverVisible}
                setVisible={setReceiverVisible}
                data={itemBase}
                allData={items}
                setReload={() => console.log()}
                setItemBase={setItemBase}
            />
        </Layout>
    );
};

export default NfProcess;
