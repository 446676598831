import React, { useState } from 'react';
import {useNavigate} from 'react-router-dom';
import {Col, Form, Popover, Row, Typography, Space, Button} from 'antd';
import excel from '@core/modules/common/infra/excel';
import schedule from '@core/modules/schedule/infra/container.registry';
import {Notification} from '@presentation/components/common';
const {Link} = Typography;
import {formattedRoute} from '../../../routes';
import {routes} from "../../../../procedure/routes";

const Header = (props:{
    hideBtnSchedule?: boolean
}) => {

    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);

    const download = async () => {
        try {
            setLoading(true);
            const result = await schedule.export.execute();
            if (result.data.length)
                return excel.exportToExcel({
                    data: result.data,
                    name: 'schedule',
                });
            Notification.error({
                message: 'Não foi encontrado informações!',
            });
        } catch {
            Notification.error({
                message: 'Não foi encontrado informações, retorno com error !',
            });
        } finally {
            setLoading(false);
        }
    };

    const content = () => {
        return (
            <Space direction="vertical">
                <Link style={{color: '#A1A2A2'}} onClick={() => download()} target="_blank">
                    {loading ? 'CARREGANDO ...' : 'DOWNLOAD'}
                </Link>
            </Space>
        );
    };

    return (
        <Form layout="vertical" style={{padding: 10}}>
            <Row justify="start">
                <Col xs={24} sm={4} md={4} lg={4} xl={4}>
                        {props.hideBtnSchedule == undefined || props.hideBtnSchedule == false ? (
                            <Row style={{paddingTop: '15%'}} justify="end">
                                <Button
                                        type="primary"
                                        data-testid="btn-add"
                                        size="middle"
                                        style={{
                                            borderRadius: 20,
                                            color: '#FFFFFF',
                                            backgroundColor: '#43AB66',
                                            border: '2px solid #FFFFFF',
                                            boxShadow: '0 0 0 5px #43AB66',
                                            width: 200
                                        }}
                                        onClick={() => navigate(formattedRoute(routes.PROCEDURE_CREATE))}
                                    >
                                        Agendar
                                </Button>
                            </Row>
                        ):(<></>)}
                </Col>
            </Row>

        </Form>
    );
};

export default Header;
