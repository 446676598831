import {Schedule, TGet} from '../domain/schedule.entities';
import {ScheduleGateway} from '../domain/schedule.gateway';

export class GetUseCase {
    constructor(private gate: ScheduleGateway) {}

    async execute(params: TGet): Promise<Schedule> {
        return this.gate.get(params);
    }
}
