import React, {createContext, useState, useContext, useMemo} from 'react';

interface LoadingContextData {
    isLoading: boolean;
    setIsLoading: React.Dispatch<boolean>;
}
interface Props {
    children: React.ReactNode;
}

const LoadingContext = createContext<LoadingContextData>({} as LoadingContextData);

const LoadingProvider: React.FC<Props> = ({children}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // const init = React.useCallback(async () => {
    //     if (!initialized) return setIsLoading(true);

    //     if (keycloak?.authenticated && keycloak.token) {
    //         setIsLoading(false);
    //     }
    // }, [initialized, keycloak]);

    // React.useEffect(() => {
    //     init();
    // }, []);

    const value = useMemo(
        () => ({
            isLoading,
            setIsLoading,
        }),
        [],
    );

    return <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>;
};

function useLoading() {
    const context = useContext(LoadingContext);

    if (!context) {
        throw new Error('useLoading must be used within an LoadingProvider.');
    }

    return context;
}

export {LoadingProvider, useLoading};
