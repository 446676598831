import MockAdapter from 'axios-mock-adapter';
import {data as dataMock} from '../__mocks__/RequestAccess.mock';
import {data as dataUserInfos} from '../__mocks__/UserInfos.mock';
import {Paths} from '../infra/keys';

export const mockRequestAccess = (mock: MockAdapter) => {
    return mock.onPost(Paths.requestAccess).reply((config) => {
        const {data} = config;
        const formData = new URLSearchParams(data);

        const email = formData.get('email');
        const password = formData.get('password');

        if (email === 'admin@email.com' && password === '12345') {
            return [200, dataMock];
        } else {
            return [401, 'Não autorizado'];
        }
    });
};

export const mockUserInfo = (mock: MockAdapter) => {
    return mock.onGet(Paths.userInfo).reply(() => {
        return [200, dataUserInfos];
    });
};

export const mockForgotPassword = (mock: MockAdapter) => {
    return mock.onPost(Paths.forgotPassword).reply((config) => {
        const {data} = config;
        const formData = new URLSearchParams(data);

        const email = formData.get('email');
        if (email === 'admin@email.com') {
            return [200, {}];
        } else {
            return [404, 'Servico indisponivel'];
        }
    });
};

export const mockAcceptTermsOfUseAndPrivacyPolicies = (mock: MockAdapter) => {
    return mock.onPost(Paths.termsOfUseAndPrivacyPolicies).reply(() => {
        const data = {
            acceptIn: new Date(),
            lastUpdate: new Date(),
            lastUrlPdf: 'http://www.pdf995.com/samples/pdf.pdf',
            currentUrlPdf: 'http://www.pdf995.com/samples/pdf.pdf',
        };
        return [200, data];
    });
};

export const mockTermsOfUseAndPrivacyPolicies = (mock: MockAdapter) => {
    return mock.onGet(Paths.termsOfUseAndPrivacyPolicies).reply(() => {
        const data = {
            acceptIn: new Date(),
            lastUpdate: new Date(),
            lastUrlPdf: 'http://www.pdf995.com/samples/pdf.pdf',
            currentUrlPdf: 'http://www.pdf995.com/samples/pdf.pdf',
        };
        return [200, data];
    });
};

export const mockMyTermsOfUseAndPrivacyPolicies = (mock: MockAdapter) => {
    return mock.onGet(Paths.myTermsOfUseAndPrivacyPolicies).reply(() => {
        const data = {
            acceptIn: new Date(),
            lastUpdate: new Date(),
            lastUrlPdf: 'http://www.pdf995.com/samples/pdf.pdf',
            currentUrlPdf: 'http://www.pdf995.com/samples/pdf.pdf',
        };
        return [200, data];
    });
};
