import { TGetSecretariesResponse } from "../domain/portal-unit-secretary.entities";
import { UnitGateway } from "../domain/unit.gateway";

export class ListUnitSecretaryUseCase {
    constructor(private unitGateway: UnitGateway) {}

    async execute(params: any): Promise<TGetSecretariesResponse> {
        return this.unitGateway.listSecretaries(params);
    }
}
