import {Surgeon, TCreateOrUpdate} from '../domain/surgeon.entities';
import {SurgeonGateway} from '../domain/surgeon.gateway';

export class CreateUseCase {
    constructor(private gate: SurgeonGateway) {}

    async execute(params: TCreateOrUpdate): Promise<Surgeon> {
        return this.gate.create(params);
    }
}
