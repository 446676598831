const avatar = (bgColor: string) => {
    return {
        backgroundColor: bgColor,
        cursor: 'pointer',
        color: 'black',
        borderRadius: 12,
        marginBottom: 20,
    };
};

const container = {
    margin: '10%',
    marginTop: 48,
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
};

const containerInfo = {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 15,
};

const name = {
    color: '#1C1C1C',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 1.5,
    cursor: 'pointer',
};

const type = (color: string) => {
    return {
        fontWeight: 300,
        fontSize: 16,
        lineHeight: 1.5,
        color: color,
        cursor: 'pointer',
    }
};

const email = (color: string) => {
    return {
        fontWeight: 300,
        fontSize: 14,
        lineHeight: 1.5,
        color: color,
    }
};

const content = { cursor: 'pointer' };

export default {
    avatar,
    content,
    container,
    name,
    type,
    email,
    containerInfo,
};
