export const CreditCardColumn = [
    {
      title: '',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Identificado',
      dataIndex: 'identified',
      key: 'identified',
      render: (value: number | bigint) => new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    }).format(value),
    },
    {
      title: 'Não Identificado',
      dataIndex: 'notIdentified',
      key: 'notIdentified',
      render: (value: number | bigint) => new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    }).format(value),
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (value: number | bigint) => new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    }).format(value),
    },
];

export const TicketAndPixColumn = [
  {
    title: '',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Identificado',
    dataIndex: 'identified',
    key: 'identified',
    render: (value: number | bigint) => new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
  }).format(value),
  },
];

export const RightColumn = [
  {
    title: 'Já processado',
    dataIndex: 'processed',
    key: 'processed',
    render: (value: number | bigint) => new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
  }).format(value),
  },
  {
    title: 'Não Conciliado',
    dataIndex: 'notReconciled',
    key: 'notReconciled',
    render: (value: number | bigint) => new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
  }).format(value),
  },
];