import {Layout as AntLayout, Typography, Row, theme} from 'antd';
import {Link} from 'react-router-dom';
import style from './style';
import notAuthentication from '@presentation/modules/route/notAuthenticated';

const {Text} = Typography;

const Prohibited = () => {
    const {
        token: {colorPrimary},
    } = theme.useToken();

    setTimeout(() => {
        window.location.replace(notAuthentication.defaultRoute.key);
    }, 4000);

    return (
        <AntLayout style={style.container} data-testid="layout-prohibited">
            <Row>
                <img src="/prohibited.svg" data-testid="img-prohibited" />
            </Row>
            <Row>
                <Text style={style.title} data-testid="text-prohibited">
                    A opção selecionada não é permitida ou você não está autenticado!
                </Text>
            </Row>
            <Row>
                <Text style={style.titleSub} data-testid="text-prohibited">
                    Faça login ou entre em contado conosco.
                </Text>
            </Row>
            <Row>
                <Link to="/">
                    <Text style={style.back(colorPrimary)} data-testid="back-link-prohibited">
                        Voltar
                    </Text>
                </Link>
            </Row>
        </AntLayout>
    );
};

export default Prohibited;
