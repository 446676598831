import {Surgeon, TCreateOrUpdate} from '../domain/surgeon.entities';
import {SurgeonGateway} from '../domain/surgeon.gateway';

export class UpdateUseCase {
    constructor(private gate: SurgeonGateway) {}

    async execute(params: TCreateOrUpdate): Promise<Surgeon> {
        return this.gate.update(params);
    }
}
