const text = {
    color: '#828383',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
};

const title = {
    color: '#262A41',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: 0.65,
};

const textDetails = {
    color: '#101828',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
};

const divider = {
    backgroundColor: '#ACD084',
    fontSize: '16',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    width: 2,
    marginLeft: 0,
};

const textUnit = {
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '4',
    color: '#101828',
};

const rowTitle = {marginTop: 8, marginBottom: 10};

const rowDetails = {marginTop: 10};

export default {
    text,
    textDetails,
    divider,
    textUnit,
    title,
    rowTitle,
    rowDetails,
};
