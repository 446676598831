import {Col, Divider, Typography, Row} from 'antd';
const {Text} = Typography;
import style from './style';

import {Schedule} from '@/@core/modules/schedule/domain/schedule.entities';

interface IInfos {
    data: Schedule | undefined;
}

const Infos = (props: IInfos) => {
    return (
        <Col xs={24} sm={24} md={10} lg={10} xl={10} style={{paddingLeft: 40}}>
            <Text style={style.textUnit}>{props?.data?.unit.toUpperCase()}</Text>

            <Row style={style.rowTitle}>
                <Text style={style.title}>Cirurgião responsável:</Text>
            </Row>
            <Text style={style.textDetails}>{props?.data?.surgeon}</Text>
            <Divider />

            <Row style={style.rowTitle}>
                <Text style={style.title}>Anestesista Principal:</Text>
            </Row>
            <Text style={style.textDetails}>{props?.data?.anesthetist ?? 'Não informado'}</Text>
            <Divider />
            <Row style={style.rowTitle}>
                <Text style={style.title}>Data e hora do procedimento:</Text>
            </Row>
            <Row style={style.rowTitle}>
                <Text style={style.textDetails}>{props?.data?.procedureDate}</Text>
            </Row>
            <Divider />
            <Row style={style.rowTitle}>
                <Text style={style.title}>Data e hora de criação:</Text>
            </Row>
            <Text style={style.textDetails}>{props?.data?.scheduleDate}</Text>
        </Col>
    );
};

export default Infos;
