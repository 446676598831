import { TRemoveSecretaryRequest, TCreateSecretaryResponse } from "../domain/portal-unit-secretary.entities";
import { UnitGateway } from "../domain/unit.gateway";

export class DeleteUnitSecretaryUseCase {
    constructor(private unitGateway: UnitGateway) {}

    async execute(data: TRemoveSecretaryRequest): Promise<TCreateSecretaryResponse> {
        return this.unitGateway.deleteSecretary(data);
    }
}
