import {AxiosInstance} from 'axios';

import {Paths} from './keys';
import { IPatientGateway } from '../domain/patient.gateway';
import { IPatientResponse } from '../domain/patient.entities';

export class PatientHttpGateway implements IPatientGateway {
  constructor(private http: AxiosInstance) {}

  async searchByCpf(cpf: string): Promise<IPatientResponse> {
    const path = `${Paths.searchByCpf}/${cpf}`
    return await this.http.get(path).then((res) => {
      return res.data
    }).catch((error) => {
      throw new Error(error)
    })
  }
}
