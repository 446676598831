import {Route} from 'react-router-dom';
import {paths} from '@presentation/modules/authentication/routes';

const routes = paths.map((route) => (
    <Route key={route.key} path={route.key} element={route.element} />
));

const login = paths.filter((item) => item.key === '/login')[0];

export default {
    routes: routes,
    paths: paths,
    defaultRoute: login,
};
