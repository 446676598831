import React, { useState } from 'react';
import { Button as ButtonAntd, ButtonProps } from 'antd';
import { BaseButtonProps } from 'antd/es/button/button';

interface IButton extends BaseButtonProps {
  onClick?: () => void;
  text: string;
  loading?: boolean;
  nativeProps?: ButtonProps;
}

export const Button: React.FC<IButton> = (props) => {
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    if (props.loading) {
      setLoading(true)
    }

    if (props.onClick) {
      props.onClick();
    }

    if (props.loading) {
      setTimeout(() => {
        setLoading(false);
      }, 5000); // 5 seconds
    }
  };

  return (
    <ButtonAntd
      {...props.nativeProps}
      disabled={props.disabled}
      type="primary"
      data-testid="btn-add"
      size="middle"
      loading={loading || props.loading}
      style={{
        ...props.nativeProps?.style,
        borderRadius: 8,
      }}
      onClick={handleClick}
    >
      {props.text}
    </ButtonAntd>
  );
};
