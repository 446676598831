import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import {CustomException, ErrorMap} from '@core/modules/common/infra/error';
import queryString from 'query-string';

// const NODE_ENV = import.meta.env.MODE;
const API_URL = import.meta.env.VITE_API_BASE_URL;

const data = (baseURL: string, isUpload = false) => ({
    baseURL: baseURL,
    headers: {
        'Content-Type': isUpload ? 'multipart/form-data' : 'application/json',
    },
    timeout: 60000,
    paramsSerializer: {
        serialize: (params: any) => {
            return queryString.stringify(params, {arrayFormat: 'bracket'});
        },
    },
});

const mockCustom = () => new MockAdapter(axios);
// NODE_ENV === 'test'
//     ? new MockAdapter(httpAxiosInstance)
//     // : new MockAdapter(axios);

export const httpApi = axios.create(data(API_URL));
export const httpApiUpload = axios.create(data(API_URL, true));
export const mockApi = mockCustom();

export const setHeaderAuthorization = async (token: string) => {
    if (!httpApi || !token) return;

    if (httpApi?.defaults) {
        localStorage.setItem('token', token);
        httpApi.defaults.headers.authorization = `${token}`;
        httpApi.defaults.headers.Authorization = `${token}`;

        httpApi.interceptors.request.use(
            function (config) {
                config.headers.authorization = `Bearer ${token}`;
                config.headers.Authorization = `Bearer ${token}`;
                config.paramsSerializer = (params) =>
                    queryString.stringify(params, {arrayFormat: 'bracket'});
                return config;
            },
            function () {
                return Promise.reject(new CustomException(ErrorMap.NOT_AUTHORIZED));
            },
        );
    }
};

if (httpApi) {
    const interceptorResponse = async (error: any) => {
        if (error?.response === undefined) throw new CustomException(ErrorMap.SERVER_CONNECTION);
        if (error?.response?.status === 401) {
            throw new CustomException(ErrorMap.NOT_AUTHORIZED);
        }

        throw error;
    };

    httpApi?.interceptors?.response?.use(
        async (response) => response,
        async (error) => interceptorResponse(error),
    );
}

export const getToken = async () => {
    const token = localStorage.getItem('token');
    if (!httpApi || !token) return;
    httpApi.defaults.headers.authorization = `Bearer ${token}`;
    httpApi.defaults.headers.Authorization = `Bearer ${token}`;
};

getToken();

export const formatQueryParams = (path: string, params: any) => {
    const queryParams = new URLSearchParams();

    for (const [key, value] of Object.entries(params)) {
        if (value !== undefined && value !== null) {
            if (Array.isArray(value)) {
                queryParams.append(key, value.join(','));
            } else {
                queryParams.append(key, value.toString());
            }
        }
    }
    return `${path}?${queryParams.toString()}`;
};
