import {Form as AntForm, Input} from 'antd';

interface IInputPassword {
    label?: string;
    value?: string;
    required?: boolean;
    name?: string;
    placeholder?: string;
    style?: React.CSSProperties | undefined;
}

const InputPassword = (props: IInputPassword) => {
    return (
        <AntForm.Item
            style={{
                padding: 0,
                margin: 0,
            }}
            id={props.name ? props.name : 'password'}
            name={props.name ? props.name : 'password'}
            label={props.label ? props.label : ''}
            data-testid="input-password"
            rules={[
                {
                    required: props?.required,
                    message: `${
                        props.label || props.placeholder
                            ? props.label || props.placeholder
                            : 'Senha'
                    } inválida!`,
                },
            ]}
            initialValue={props?.value}
        >
            <Input.Password
                size="large"
                id="password"
                style={{
                    minWidth: '100%',
                    maxWidth: '100%',
                    borderRadius: 8,
                    ...props.style,
                }}
                disabled={false}
                data-testid="input-password"
                maxLength={255}
                placeholder={`${props.placeholder ? props.placeholder : 'Senha'}`}
            />
        </AntForm.Item>
    );
};

export {InputPassword};
