const header = (bgColor: string, isSmall: boolean) => {
    return {
        background: bgColor,
        opacity: 1,
        paddingBottom: isSmall ? '50px' : '4%',
        width: '100%',
        maxHeight: 8,
        minHeight: 8,
    };
};

const buttonMenu = {
    position: 'absolute' as const,
    left: 12 as const,
    fontSize: '16px' as const,
    width: 64 as const,
    height: 64 as const,
    top: '-1%',
};

const headerContainer = {
    display: 'flex',
    justifyContent: 'space-between',
};

export default {
    header,
    headerContainer,
    buttonMenu,
};
