import React from 'react';
import {Pagination as AntPagination} from 'antd';

interface IPagination {
    total: number;
    defaultPageSize: number;
    defaultCurrent: number;
    onShowSizeChange: (current: number, size: number) => void;
    onChange: (page: number, pageSize: number) => void;
    loading?: boolean;
}

export const Pagination = React.memo((params: IPagination) => (
    <AntPagination
        data-testid="pagination-form"
        disabled={params.loading}
        total={params.total}
        showTotal={(total) => `Total ${total} itens`}
        defaultPageSize={params.defaultPageSize}
        defaultCurrent={params.defaultCurrent}
        pageSizeOptions={[5, 10, 20, 50, 100]}
        showLessItems
        showSizeChanger
        onShowSizeChange={params.onShowSizeChange}
        onChange={params.onChange}
        style={{backgroundColor: 'white'}}
    />
));

Pagination.displayName = 'Pagination';
