import {Layout as AntLayout, Typography, Row, theme} from 'antd';
import {Link} from 'react-router-dom';
import style from './style';

const {Text} = Typography;

const NotFound = () => {
    const {
        token: {colorPrimary},
    } = theme.useToken();
    return (
        <AntLayout style={style.container} data-testid="layout-not-found">
            <Row>
                <img src="/notFound.svg" data-testid="img-not-found" />
            </Row>
            <Row>
                <Text style={style.title} data-testid="text-not-found">
                    Oops.. Página não encontrada.
                </Text>
            </Row>
            <Row>
                <Link to="/">
                    <Text style={style.back(colorPrimary)} data-testid="back-link-not-found">
                        Voltar
                    </Text>
                </Link>
            </Row>
        </AntLayout>
    );
};

export default NotFound;
