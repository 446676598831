export type TypeTermsOfUseAndPrivacyPolicies = {
    acceptIn?: string;
    lastUpdate?: string;
    lastUrlPdf?: string;
    currentUrlPdf?: string;
};

export type TMyTerms = {
    id?: number;
    version?: string;
    type?: string;
    uploadId?: number;
    upload?: {
        id: number;
        name: string;
        path: string;
        fileSize: number;
        uploadType: string;
        createdAt: string;
        updatedAt?: string;
    };
};
export class MyTerms {
    constructor(public props: TMyTerms) {}
    get id() {
        return this.props.id;
    }
    get version() {
        return this.props.version;
    }
    get type() {
        return this.props.type;
    }
    get uploadId() {
        return this.props.uploadId;
    }
    get upload() {
        return this.props.upload;
    }

    toJSON() {
        return {
            id: this.props.id,
            version: this.props.version,
            type: this.props.type,
            uploadId: this.props.uploadId,
            upload: this.props.upload,
        };
    }
}

export class TermsOfUseAndPrivacyPolicies {
    constructor(public props: TypeTermsOfUseAndPrivacyPolicies) {}
    get acceptIn() {
        return this.props.acceptIn;
    }
    get lastUpdate() {
        return this.props.lastUpdate;
    }
    get lastUrlPdf() {
        return this.props.lastUrlPdf;
    }
    get currentUrlPdf() {
        return this.props.currentUrlPdf;
    }

    toJSON() {
        return {
            acceptIn: this.acceptIn,
            lastUpdate: this.lastUpdate,
            lastUrlPdf: this.lastUrlPdf,
            currentUrlPdf: this.currentUrlPdf,
        };
    }
}
