import { CustomerGateway } from '../domain/customer.gateway';
import { SearchCustomerInterface } from '../domain/customer.entity';

export class SearchUseCase {
    constructor(private gate: CustomerGateway) {}

    async execute(cpf: string): Promise<SearchCustomerInterface> {
        return this.gate.search(cpf);
    }
}
