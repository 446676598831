import { useState } from "react";

const Status = (record: any) => {
    let bd = "#c9c9c9";
    let bkg = "#f9f9f9";

    let statusAtual = record?.item?.statusBilling;

    const defineStatusColor = (status: string) => {
        switch (status?.toLowerCase()) {
            case 'pendente':
                bd = '#054B7C';
                bkg = '#D0ECFF';
                break;

            case 'pagamento a confirmar':
                bd = '#F57C00';
                bkg = '#FFF4EC';
                break;

            case 'em andamento':
                bd = '#054B7C';
                bkg = '#D0ECFF';
                break;

            case 'finalizado':
                bd = '#054B7C';
                bkg = '#D0ECFF';
                break;

            case 'pago':
                bd = '#06813A';
                bkg = '#EDF9F0';
                break;

            case 'pagamento antecipado':
                bd = '#F57C00';
                bkg = '#FFF4EC';
                break;

            case 'cancelado':
                bd = '#E55050';
                bkg = '#FEEFEF';
                break;

            case 'cortesia':
                bd = '#7570FF';
                bkg = '#E3E2FF ';
                break;

            case 'agendamento cancelado':
                bd = '#E55050';
                bkg = '#FEEFEF';
                break;

            default:
                break;
        }
    };

    function defineStatusAtual() {
        const billingStatus = record?.item?.statusBilling;

        if (record?.item?.status === 'CANCELADO' && billingStatus === 'paid') {
            statusAtual = 'Pago';
            bd = '#E55050';
            bkg = '#FEEFEF';
            return;
        }

        if (record?.item?.status === 'CANCELADO') {
            statusAtual = 'Cancelado';
        } else if (record?.item?.schedulePaymentTypeId === 5) {
            statusAtual = 'Cortesia';
        } else {
            if (billingStatus === 'pending' && record?.item?.advancePayment === false) {
                statusAtual = 'Pagamento a confirmar';
            } else if (billingStatus === 'pending' && record?.item?.advancePayment === true) {
                statusAtual = 'Pagamento a confirmar';
            } else if (billingStatus === 'canceled') {
                statusAtual = 'Pendente';
            } else if (billingStatus === 'paid') {
                statusAtual = 'Pago';
            } else {
                statusAtual = 'Pendente';
            }
        }

        // Define as cores com base no status final
        defineStatusColor(statusAtual);
    }

    defineStatusAtual();

    return (
        <div
            style={{
                cursor: 'not-allowed',
                width: 92,
                backgroundColor: bkg,
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: bd,
                borderRadius: 20,
                color: bd,
                fontSize: 10,
                padding: 4,
                textAlign: "center",
            }}
        >
            {statusAtual}
        </div>
    );
};

export default Status;
