import React from 'react';
import { theme, Row, Col } from 'antd';
import style from './style';
import { auth } from '@/@core/modules/authentication/infra/container.registry';
import notAuthentication from '@presentation/modules/route/notAuthenticated';
import { Button } from '@/@presentation/components/form';
import { PoweroffOutlined } from '@ant-design/icons';

interface IFooter {
    collapsed: boolean;
    setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

const Footer: React.FC<IFooter> = (props: IFooter) => {
    const [isLoading, setIsLoading] = React.useState(false)
    const {
        token: {
            colorPrimaryBg
        },
    } = theme.useToken();

    const handleLogout = async () => {
        setIsLoading(true);
        await auth.logout.execute();

        setTimeout(() => {
            window.location.replace(notAuthentication.defaultRoute.key);
            setIsLoading(false);
        }, 1000);
    };

    return (
        <Col style={style.container(colorPrimaryBg)}>
            <Row>
                <Button
                    text='Sair'
                    onClick={handleLogout}
                    loading={isLoading}
                    nativeProps={{
                        style: {
                            width: '100%',
                            margin: 10,
                        },
                        icon: <PoweroffOutlined />,
                    }}
                />
            </Row>
            <Row
                data-testid="footer-div"
                onClick={() => props.setCollapsed(!props.collapsed)}
            >
                <img
                    data-testid="footer-img"
                    alt="iconHide"
                    style={style.img(props.collapsed)}
                    src="/takaoka.svg"
                />
            </Row>
        </Col>
    );
};

export default Footer;
