import {Form as AntForm} from 'antd';
import InputMask from 'react-input-mask';
import style from './style';
interface IInputCnpj {
    name: string;
    label?: string;
    placeholder?: string;
    required?: boolean;
}

const InputCnpj = (props: IInputCnpj) => {
    return (
        <AntForm.Item
            name={props?.name ?? 'cnpj'}
            label={props?.label ?? 'Cnpj'}
            rules={[{required: props.required}]}
        >
            <InputMask
                mask="99.999.999/9999-99"
                style={style.cnpj}
                data-testid="input-cnpj"
                placeholder={props?.placeholder ?? 'Cnpj'}
            />
        </AntForm.Item>
    );
};

export {InputCnpj};
