import { useProcedure } from "@/@presentation/modules/procedure/context/procedure";
import { Button, List, Modal, Tooltip } from "antd";
import { CheckOutlined, InfoCircleOutlined } from "@ant-design/icons";
import React from "react";

const SurgeonShow = (props: any) => {

  if (!props.surgeon.isSurgeonInSecretary) {
    return (
      <>
        <Tooltip title="Sem vinculo com você">
          <span style={{
            color: '#ffc107',
          }}>{props.surgeon.label}</span> 
        </Tooltip>
        <Tooltip title="Sem vinculo com você">
          <InfoCircleOutlined style={{ color: '#ffc107' }} />
        </Tooltip>
      </>
    )
  }

  if (!props.surgeon.isUnitSurgeon) {
    return (
      <>
        <Tooltip title="Sem vinculo com sua unidade">
          <span style={{
            color: 'red',
          }}>{props.surgeon.label}</span> 
        </Tooltip>
        <Tooltip title="Sem vinculo com sua unidade">
          <InfoCircleOutlined style={{ color: 'red' }} />
        </Tooltip>
      </>
    )
  }

  if (props.surgeon.checked) {
    return (
      <>
        <Tooltip title="Cirurgião selecionado">
          <span >
            {props.surgeon.label}
          </span>
        </Tooltip>
        <Tooltip title="Cirurgião selecionado">
          <CheckOutlined style={{ color: 'green' }} />
        </Tooltip>
      </>
    )
  }

  return (
    <span>{props.surgeon.label}</span>
  )
}

interface IProps {
}

export const SurgeonFilter: React.FC<IProps> = (props) => {
  const [selectedItems, setSelectedItems] = React.useState<string[]>([]);
  const [openModal, setOpenModal] = React.useState(false);

  const unit_portal = JSON.parse(localStorage.getItem('@unit_portal') ?? '{}');

  const toggleSelect = (id: string) => {

    const canCheck = doctors.filter((doctor: any) => doctor.value === id)[0].isUnitSurgeon;

    if (!canCheck) {
      return;
    }

    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((item) => item !== id));
      localStorage.setItem('@surgeon_filtered', JSON.stringify(selectedItems.filter((item) => item !== id)));
      return;
    }

    if (!selectedItems.includes(id)) {
      setSelectedItems([...selectedItems, id]);
      localStorage.setItem('@surgeon_filtered', JSON.stringify([...selectedItems, id]));
    }
  };

  const {
    states: { doctors },
    actions: { handleInitLoadSearchGeneral },
  } = useProcedure();

  React.useEffect(() => {
    handleInitLoadSearchGeneral();
  }, []);

  React.useEffect(() => {

    const storedSurgeons = localStorage.getItem('@surgeon_filtered');
    if (storedSurgeons && storedSurgeons.length > 0) {
      setSelectedItems(JSON.parse(localStorage.getItem('@surgeon_filtered') as string));
      return;
    }

    const selectedDoctors = doctors
      .filter((doctor: any) => doctor.isUnitSurgeon)
      .map((doctor: any) => doctor.value);

    setSelectedItems(selectedDoctors);
  }, [doctors]);

  function mergeDoctorsAndSurgeons() {
    const doctorValues = doctors.map(doctor => Number(doctor.value));

    const uniqueUnitSurgeons = unit_portal?.unit_portal_surgeons?.filter((doctor: any) => !doctorValues.includes(Number(doctor.surgeon_id)));
  
    let combinedDoctors = [
      ...doctors.map((doctor: any) => ({
        label: doctor.label,
        value: doctor.value,
        isUnitSurgeon: doctor.isUnitSurgeon,
        isSurgeonInSecretary: true,
        checked: selectedItems.includes(doctor.value),
      })),
    ];

    if (uniqueUnitSurgeons) {
      combinedDoctors = [
        ...combinedDoctors,
        ...uniqueUnitSurgeons.map((doctor: any) => ({
          label: doctor.surgeon_name,
          value: doctor.surgeon_id,
          isUnitSurgeon: true,
          isSurgeonInSecretary: false,
          checked: selectedItems.includes(doctor.surgeon_id),
        })),
      ];
    } 
  
    return combinedDoctors;
  }
  

  return (
    <>
      <Modal
        title="Filtro geral de cirurgiões"
        open={openModal}
        closeIcon={null}
        footer={[
          <Button
            onClick={() => {
              window.location.reload();
            }}
          >
            Fechar
          </Button>
        ]}
      >
        <div style={{ maxHeight: '350px', overflowY: 'auto', border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
          <List
            dataSource={mergeDoctorsAndSurgeons()}
            renderItem={(item) => (
              <List.Item
                onClick={() => toggleSelect(item.value)}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <SurgeonShow
                  surgeon={item}
                />
              </List.Item>
            )}
          />
        </div>
      </Modal>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 10,
        }}
      >
        <Button
          type="primary"
          style={{ width: '100%' }}
          onClick={() => setOpenModal(true)}
        >
          Cirurgiões ({selectedItems.length} selecionados)
        </Button>
      </div>
    </>
  );
};
