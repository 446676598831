export const Keys = {
    ACCESS: '@access_key',
    USER_INFOS: '@user_infos_key',
};

export const Paths = {
    requestAccess: '/api/auth/login',
    userInfo: '/api/persons/me',
    forgotPassword: 'api/forgot-password',
    forgotPasswordByCode: 'api/access/password-by-code',
    forgotPasswordValidate: 'api/forgot-password/validate',
    termsOfUseAndPrivacyPolicies: '/api/terms-person',
    myTermsOfUseAndPrivacyPolicies: '/api/lgpd-terms',
    acceptedLasTerm: '/api/terms-person/accepted-last-term',
};
