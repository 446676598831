import {UserInfos} from '../domain/userInfos.entities';
import {
    AuthenticationGateway,
    AuthenticationLocalStorageGateway,
} from '../domain/authentication.gateway';

export class UserInfosUseCase {
    constructor(
        private gate: AuthenticationGateway,
        private gateLocalStorage: AuthenticationLocalStorageGateway,
    ) {}

    async execute(): Promise<UserInfos | undefined> {
        // const userInfos = await this.gateLocalStorage.getUserInfos();

        // if (userInfos) {
        //     return userInfos;
        // }

        try {
            const userInfos = await this.gate.userInfos();

            await this.gateLocalStorage.saveUserInfos(userInfos);
            return userInfos;
        } catch {
            return undefined;
        }
    }
}
