import Modal from "@/@presentation/components/common/modal"
import { Button } from "@/@presentation/components/form";
import { Typography } from "antd"

interface IConfirmeModel {
  onConfirm: (isConfirm: boolean) => void;
  open: boolean;
}

const ConfirmModal = (props: IConfirmeModel) => {
  const footerConfirmModal: React.ReactNode[] = [
    <Button text="Não" key='noConfirm' onClick={() => props.onConfirm(false)} nativeProps={{ ghost: true}}/>,
    <Button text="Sim" key='yesConfirm' onClick={() => props.onConfirm(true)}/>,
  ]

  return (
    <Modal
      footer={footerConfirmModal}
      open={props.open}
      title='Confirmar procedimento'
    >
      <Typography>Deseja confirmar o procedimento?</Typography>
    </Modal>
  )
}

export default ConfirmModal;