import Administration from './pages/administration';
import SurgeonForm from './pages/surgeon/form';
import UserForm from './pages/user/form';

import {ENUM_PERMISSIONS} from '@presentation/modules/route/helpers';

export const permissionAdmin = {
    key: '/v1/administration',
};

export enum routes {
    ADMINISTRATION = '/administration',
    ADMINISTRATION_KEY = '/administration/:key',
    SURGEON_CREATE = '/administration/surgeon/create',
    SURGEON_EDIT = '/administration/surgeon/edit/:id',
    SURGEON_RESET_PASSWORD = '/administration/surgeon/reset-password/:id',
    USER_CREATE = '/administration/user/create',
    USER_EDIT = '/administration/user/edit/:id',
}

export const formattedRoute = (route: string, value?: any) => {
    return route.replace('/:key', `/${value}`).replace('/:id', `/${value}`);
};

export const permissions = [
    ENUM_PERMISSIONS.admin,
    ENUM_PERMISSIONS.secretary,
    ENUM_PERMISSIONS.surgeon,
];

const hostname = window.location.protocol + '//' + window.location.host + '/';

export const paths = [
    {
        label: 'Painel',
        key: routes.ADMINISTRATION,
        icon: hostname + 'icons/panel.svg',
        element: <Administration />,
        permission: permissions,
        children: null,
    },
    {
        label: '',
        key: routes.ADMINISTRATION_KEY,
        icon: null,
        element: <Administration />,
        permission: permissions,
        children: null,
    },
    {
        label: '',
        key: routes.SURGEON_EDIT,
        icon: null,
        element: <SurgeonForm />,
        permission: permissions,
        children: null,
    },
    {
        label: '',
        key: routes.SURGEON_CREATE,
        icon: null,
        element: <SurgeonForm />,
        permission: permissions,
        children: null,
    },
    {
        label: '',
        key: routes.USER_EDIT,
        icon: null,
        element: <UserForm />,
        permission: permissions,
        children: null,
    },
    {
        label: '',
        key: routes.USER_CREATE,
        icon: null,
        element: <UserForm />,
        permission: permissions,
        children: null,
    },
];
