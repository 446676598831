import { IChartNextDays, IChartResponse, ISimpleChart } from "../domain/dashboard.entities";
import { DashboardGateway } from "../domain/dashboard.gateway";

export class GetDashboardUseCase {
  constructor(private gate: DashboardGateway) {}

  async getSchedulesToday(): Promise<ISimpleChart> {
    return await this.gate.getSchedulesToday();
  }

  async getSchedulesPendingToPay(): Promise<ISimpleChart> {
    return await this.gate.pendingToPay();
  }

  async getSchedulesPendingToConfirmation(): Promise<ISimpleChart> {
    return await this.gate.pendingToConfirmation();
  }

  async getSchedulesYesterday(): Promise<ISimpleChart> {
    return await this.gate.getSchedulesYesterday();
  }

  async getSchedulesNext30Days(): Promise<IChartResponse> {
    return await this.gate.getSchedulesNext30Days();
  }
}
