import React from 'react';
import {Button, Col, Form, Row, Typography} from 'antd';
import {auth} from '@core/modules/authentication/infra/container.registry';
import {TypeForgotPassword} from '@core/modules/authentication/domain/requestAccess.entities';
import {Notification} from '@presentation/components/common';
import {InputEmail} from '../../InputEmail';
import {validateMessages} from '../../helpers/message';

import style from '../style';

interface IFormSendEmail {
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setSendEmail: React.Dispatch<React.SetStateAction<boolean>>;
    setEmail: React.Dispatch<React.SetStateAction<string>>;
}

const FormSendEmail = (props: IFormSendEmail) => {
    const handleSend = async (values: TypeForgotPassword) => {
        try {
            props.setLoading(true);
            await auth.forgotPassword.execute(values);
            Notification.success({
                message: 'Solicitação de redefinição de senha enviada para seu e-mail!',
            });
            props.setEmail(values.email);
            props.setSendEmail(true);
        } catch (error: any) {
            Notification.error({
                message: error.message,
            });
        } finally {
            props.setLoading(false);
        }
    };

    return (
        <div style={style.container}>
            <Typography.Title style={style.title}>Esqueceu a senha?</Typography.Title>
            <Typography.Title style={style.description}>
                Insira seu e-mail. Você receberá um código para recuperar sua senha
            </Typography.Title>

            <Form
                name="forgot_password"
                className="login-form"
                labelCol={{span: 12}}
                layout="vertical"
                validateMessages={validateMessages}
                onFinish={handleSend}
                autoComplete="off"
                style={style.form}
            >
                <InputEmail label="" required={true} style={{ marginBottom: 12, paddingBottom: 12 }} />

                <Row justify="end">
                    <Col>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="login-form-button"
                            size={'middle'}
                            loading={props.loading}
                        >
                            ENVIAR
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export {FormSendEmail};
