import { InputDefault } from '@/@presentation/components/form';
import { Form as AntForm, FormInstance, Row, Select, message } from 'antd';
import React,  { useEffect, useState } from 'react';
import { styles } from '../../styles';
import { ProcedureContextData } from '@/@core/modules/procedure/domain/procedure.entities';
import TextArea from 'antd/es/input/TextArea';
import ReactInputMask from 'react-input-mask';
import { ConfirmationView } from '@/@presentation/modules/procedure/components/confirmationView';
import { Notification } from '@presentation/components/common';
interface IProps {
  states: ProcedureContextData['states'];
  searchAddress: (zip: string) => void;
  isConfirm: boolean;
  form: FormInstance<any>
}

const stateNames: Record<string, string> = {
  "AC": "Acre",
  "AL": "Alagoas",
  "AP": "Amapá",
  "AM": "Amazonas",
  "BA": "Bahia",
  "CE": "Ceará",
  "DF": "Distrito Federal",
  "ES": "Espírito Santo",
  "GO": "Goiás",
  "MA": "Maranhão",
  "MT": "Mato Grosso",
  "MS": "Mato Grosso do Sul",
  "MG": "Minas Gerais",
  "PA": "Pará",
  "PB": "Paraíba",
  "PR": "Paraná",
  "PE": "Pernambuco",
  "PI": "Piauí",
  "RJ": "Rio de Janeiro",
  "RN": "Rio Grande do Norte",
  "RS": "Rio Grande do Sul",
  "RO": "Rondônia",
  "RR": "Roraima",
  "SC": "Santa Catarina",
  "SP": "São Paulo",
  "SE": "Sergipe",
  "TO": "Tocantins"
};


export const Address: React.FC<IProps> = (props) => {
  
  const formItems = props.form.getFieldsValue()
  const isArrayAddress = props.states.searchedAddress;

  if (isArrayAddress && 'erro' in isArrayAddress && isArrayAddress.erro) {
      console.log("Error: CEP inválido.");
      props.form.resetFields(['addressStreet', 'addressNeighborhood', 'addressNumber', 'addressComplement', 'addressCity', 'addressState']);
      Notification.error({
        message: 'CEP inválido',
        duration: 3,
    });
  } else if (isArrayAddress) {
    const uf = isArrayAddress && isArrayAddress.uf ? isArrayAddress.uf : '';
    useEffect(() => {
      if (uf) {
        props.form.setFieldsValue({
          addressStateName: uf
        });
      } else {
        props.form.setFieldsValue({
          addressStateName: undefined
        });
      }
    }, [uf]);
    
  } else {
   
    console.log("Sem dados do endereço.");
  }
  
  

  return (
    <>
      <Row>
        <div
          style={{
            marginRight: 20,
          }}
        >
          {
            props.isConfirm ? (
              <ConfirmationView item={{
                label: 'CEP',
                value: formItems.addressZipCode || ''
              }} />
            ) : (
              <AntForm.Item label="CEP" name="addressZipCode">
                <ReactInputMask
                  placeholder="000000-00"
                  mask="99999-999"
                  name="addressZipCode"
                  maskChar="_"
                  onChange={(e) => props.searchAddress(e.target.value)}
                  disabled={props.isConfirm}
                  style={{
                    borderRadius: 8,
                    height: 40,
                    borderStyle: 'solid',
                    borderWidth: 1,
                    paddingLeft: 10,
                    borderColor: '#D9D9D9'
                  }}
                />
              </AntForm.Item>
            )
          }
        </div>
        <div
          style={{
            marginRight: 20,
          }}
        >
          {
            props.isConfirm ? (
              <ConfirmationView item={{
                label: 'Rua',
                value: formItems.addressStreet || ''
              }} />
            ) : (
              <InputDefault
                label="Rua"
                name="addressStreet"
                placeholder="Digite o nome da rua"
                required={props?.states?.searchedAddress?.cep ? true : false}
                nativeProps={{
                  style: {
                    width: 380,
                  },
                  disabled: props.isConfirm
                }}
              />
            )
          }
        </div>
        {
          props.isConfirm ? (
            <ConfirmationView item={{
              label: 'Bairro',
              value: formItems.addressNeighborhood || ''
            }} />
          ) : (
            <InputDefault
              label="Bairro"
              name="addressNeighborhood"
              placeholder="Bairro"
              required={props?.states?.searchedAddress?.cep ? true : false}
              nativeProps={{
                style: styles.defaultInput,
                disabled: props.isConfirm
              }}
            />
          )
        }
      </Row>
      <Row>
        <div
          style={{
            marginRight: 20,
          }}
        >
          {
            props.isConfirm ? (
              <ConfirmationView item={{
                label: 'Número',
                value: formItems.addressNumber || ''
              }} />
            ) : (
              <InputDefault
                label="Número"
                name="addressNumber"
                placeholder="Número"
                required={props?.states?.searchedAddress?.cep ? true : false}
                nativeProps={{
                  style: {
                    width: 100,
                  },
                  disabled: props.isConfirm
                }}
              />
            )
          }
        </div>
        <div
          style={{
            marginRight: 20,
          }}
        >
          {
            props.isConfirm ? (
              <ConfirmationView item={{
                label: 'Complemento',
                value: formItems.addressComplement || ''
              }} />
            ) : (
              <InputDefault
                label="Complemento"
                name="addressComplement"
                placeholder="Complemento"
                nativeProps={{
                  style: {
                    width: 130,
                  },
                  disabled: props.isConfirm
                }}
              />
            )
          }
        </div>
        <div
          style={{
            marginRight: 20,
          }}
        >
          {
            props.isConfirm ? (
              <ConfirmationView item={{
                label: 'Cidade',
                value: formItems.addressCity || ''
              }} />
            ) : (
              <InputDefault
                label="Cidade"
                name="addressCity"
                placeholder="Cidade"
                required={props?.states?.searchedAddress?.cep ? true : false}
                nativeProps={{
                  style: {
                    width: 200,
                  },
                  disabled: props.isConfirm
                }}
              />
            )
          }
        </div>
        <div>
        {
          props.isConfirm ? (
            <ConfirmationView item={{
              label: 'UF',
              value: formItems.addressState || ''
            }} />
          ) : (
            <InputDefault
              label="UF"
              name="addressState"
              placeholder="UF"
              required={props?.states?.searchedAddress?.cep ? true : false}
              nativeProps={{
                style: styles.defaultInput,
                disabled: props.isConfirm
              }}
            />
          )           
        }
        </div>
        <div>
        {
           props.isConfirm ? (
            <ConfirmationView item={{
              label: 'Estado',
              value: formItems.addressStateName || ''
            }} />
          ) : (
            <AntForm.Item
              name="addressStateName"
              label="Estado"
            >
              <Select
                disabled={props.isConfirm}
                data-testid="select-procedure-patient-genre"
                style={{ ...styles.defaultInput, width: 140 }}
                placeholder="Selecione"
                showSearch
              >
                {Object.entries(stateNames).map(([uf, name]) => (
                  <Select.Option key={uf} value={uf}>
                    {`${name}`}
                  </Select.Option>
                ))}
              </Select>
            </AntForm.Item>
          )
        }
        </div>
      </Row>
      {
        props.isConfirm ? (
          <ConfirmationView item={{
            label: 'Alergias e patologias',
            value: formItems.allergieAndPathologies || ''
          }} />
        ) : (
          <AntForm.Item name="allergieAndPathologies" label="Alergias e patologias">
            <TextArea
              disabled={props.isConfirm}
              placeholder="Alergias e patologias..."
              style={{ ...styles.defaultInput, borderRadius: 8 }}
              autoSize={{ minRows: 5, maxRows: 8 }}
            />
          </AntForm.Item>
        )
      }
    </>
  )
};
