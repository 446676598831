import {ScheduleExport} from '../domain/schedule.entities';
import {ScheduleGateway} from '../domain/schedule.gateway';

export class ExportUseCase {
    constructor(private gate: ScheduleGateway) {}

    async execute(): Promise<ScheduleExport> {
        return this.gate.export();
    }
}
