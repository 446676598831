
import {AxiosInstance} from 'axios';
import {Paths} from './keys';
import { IGuardianGateway } from '../domain/guardian.gateway';
import { IGuardianResponse } from '../domain/guardian.entities';
export class GuardianHttpGateway implements IGuardianGateway {
  constructor(private http: AxiosInstance) {}
  async searchByCpf(cpf: string): Promise<IGuardianResponse> {
    const path = `${Paths.searchByCpf}/${cpf}`
    return await this.http.get(path).then((res) => {
      return res.data
    }).catch((error) => {
      throw new Error(error)
    })
  }
}