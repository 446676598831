import Home from './pages/home';

import {ENUM_PERMISSIONS} from '@presentation/modules/route/helpers';

export const formattedRoute = (route: string, value?: any) => {
    return route.replace('/:key', `/${value}`).replace('/:id', `/${value}`);
};

const hostname = window.location.protocol + '//' + window.location.host + '/';

export enum routes {
    HOME = '/home',
}

export const permissions = [
    ENUM_PERMISSIONS.admin,
    ENUM_PERMISSIONS.secretary,
    ENUM_PERMISSIONS.surgeon,
];

export const paths = [
    {
        label: 'Home',
        key: routes.HOME,
        icon: hostname + 'icons/home.svg',
        element: <Home />,
        permission: permissions,
        children: null,
    },
]
