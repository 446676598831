import {PieChartOutlined} from '@ant-design/icons';

import Login from './pages/login';

export const paths = [
    {
        label: 'Login',
        key: '/',
        icon: <PieChartOutlined />,
        path: '/',
        element: <Login />,
        isPermitted: true,
        permission: [],
    },
    {
        label: 'Login',
        key: '/login',
        icon: <PieChartOutlined />,
        element: <Login />,
        isPermitted: true,
        permission: [],
    },
];
