import { AxiosInstance } from "axios";
import { UnitGateway } from "../domain/unit.gateway";
import * as UNIT_TYPES from "../domain/portal-unit.entities";
import * as SURGEON_TYPES from "../domain/portal-unit-surgeon.entities";
import * as SECRETARY_TYPES from "../domain/portal-unit-secretary.entities";
import { CustomException } from "../../common/infra/error";
import { keys } from "./keys";
import { httpMessage } from "../../common/infra/message";

export class UnitHttpGateway implements UnitGateway {
    constructor(private http: AxiosInstance) { }

    async listUnits(): Promise<UNIT_TYPES.TGetUnitResponse> {
        return this.http
            .get(keys.unit.getUnit)
            .then((res) => {
                if (!res?.data) {
                    throw new CustomException(httpMessage.getAllResponseEmpty('unidade'));
                }
                return res.data;
            })
            .catch((error) => {
                if (error?.status !== 409) {
                    throw new CustomException(error?.response?.data);
                }
                throw new CustomException(httpMessage.getAllError('a unidade'));
            });
    }

    async createUnit(params: UNIT_TYPES.TCreateUnitRequest): Promise<UNIT_TYPES.TCreateUnitResponse> {
        return this.http
            .post(keys.unit.createUnit, params)
            .then((res) => {
                if (!res?.data) {
                    throw new CustomException(httpMessage.getAllResponseEmpty('unidade'));
                }
                return res.data;
            })
            .catch((error) => {
                if (error?.status !== 409) {
                    throw new CustomException(error?.response?.data);
                }
                throw new CustomException(httpMessage.getAllError('a unidade'));
            });
    }

    async getUnitById(data: UNIT_TYPES.TGetUnitByIdRequest): Promise<UNIT_TYPES.TGetUnitByIdResponse> {
        const url = keys.unit.getUnitById.replace(':id', data.id.toString());
        return this.http
            .get(url)
            .then((res) => {
                if (!res?.data) {
                    throw new CustomException(httpMessage.getAllResponseEmpty('unidade'));
                }
                return res.data;
            })
            .catch((error) => {
                if (error?.status !== 409) {
                    throw new CustomException(error?.response?.data);
                }
                throw new CustomException(httpMessage.getAllError('a unidade'));
            });
    }

    async deleteUnit(params: UNIT_TYPES.TRemoveUnitRequest): Promise<UNIT_TYPES.TGetUnitResponse> {
        return this.http
            .delete(keys.unit.deleteUnit.replace(':id', params.unit_id.toString()))
            .then((res) => {
                if (!res?.data) {
                    throw new CustomException(httpMessage.getAllResponseEmpty('unidade'));
                }
                return res.data;
            })
            .catch((error) => {
                if (error?.status !== 409) {
                    throw new CustomException(error?.response?.data);
                }
                throw new CustomException(httpMessage.getAllError('a unidade'));
            });
    }

    async createSurgeon(params: SURGEON_TYPES.TCreateSurgeonRequest): Promise<UNIT_TYPES.TCreateUnitResponse> {
        return this.http
            .post(keys.surgeon.createSurgeon, params)
            .then((res) => {
                if (!res?.data) {
                    throw new CustomException(httpMessage.getAllResponseEmpty('cirurgião'));
                }
                return res.data;
            })
            .catch((error) => {
                if (error?.status !== 409) {
                    throw new CustomException(error?.response?.data);
                }
                throw new CustomException(httpMessage.getAllError('o cirurgião'));
            });
    }

    async listSurgeons(params: any): Promise<SURGEON_TYPES.TGetSurgeonsResponse> {
        return this.http
            .get(keys.surgeon.getSurgeon, {params})
            .then((res) => {
                if (!res?.data) {
                    throw new CustomException(httpMessage.getAllResponseEmpty('cirurgião'));
                }
                return res.data;
            })
            .catch((error) => {
                if (error?.status !== 409) {
                    throw new CustomException(error?.response?.data);
                }
                throw new CustomException(httpMessage.getAllError('o cirurgião'));
            });
    }

    async deleteSurgeon(params: SURGEON_TYPES.TRemoveSurgeonRequest): Promise<SURGEON_TYPES.TCreateSurgeonResponse> {
        return this.http
            .delete(keys.surgeon.deleteSurgeon, { data: params })
            .then((res) => {
                if (!res?.data) {
                    throw new CustomException(httpMessage.getAllResponseEmpty('cirurgião'));
                }
                return res.data;
            })
            .catch((error) => {
                if (error?.status !== 409) {
                    throw new CustomException(error?.response?.data);
                }
                throw new CustomException(httpMessage.getAllError('o cirurgião'));
            });
    }

    async createSecretary(params: SECRETARY_TYPES.TCreateSecretaryRequest): Promise<SECRETARY_TYPES.TCreateSecretaryResponse> {
        return this.http
            .post(keys.secretary.createSecretary, params)
            .then((res) => {
                if (!res?.data) {
                    throw new CustomException(httpMessage.getAllResponseEmpty('secretário'));
                }
                return res.data;
            })
            .catch((error) => {
                if (error?.status !== 409) {
                    throw new CustomException(error?.response?.data);
                }
                throw new CustomException(httpMessage.getAllError('o secretário'));
            });
    }

    async listSecretaries(params: any): Promise<SECRETARY_TYPES.TGetSecretariesResponse> {
        return this.http
            .get(keys.secretary.getSecretary, { params })
            .then((res) => {
                if (!res?.data) {
                    throw new CustomException(httpMessage.getAllResponseEmpty('secretário'));
                }
                return res.data;
            })
            .catch((error) => {
                if (error?.status !== 409) {
                    throw new CustomException(error?.response?.data);
                }
                throw new CustomException(httpMessage.getAllError('o secretário'));
            });
    }

    async deleteSecretary(params: SECRETARY_TYPES.TRemoveSecretaryRequest): Promise<SECRETARY_TYPES.TCreateSecretaryResponse> {
        return this.http
            .delete(keys.secretary.deleteSecretary, { data: params })
            .then((res) => {
                if (!res?.data) {
                    throw new CustomException(httpMessage.getAllResponseEmpty('secretário'));
                }
                return res.data;
            })
            .catch((error) => {
                if (error?.status !== 409) {
                    throw new CustomException(error?.response?.data);
                }
                throw new CustomException(httpMessage.getAllError('o secretário'));
            });
    }

    async updateSecretaryIsPrivate(secretaryId: number, isPrivate: boolean): Promise<void> {
        return this.http
            .put(keys.secretary.updatetSecretaryIsPrivate, { secretaryId, isPrivate })
            .then((res) => {
                if (!res?.data) {
                    throw new CustomException(httpMessage.getAllResponseEmpty('secretário'));
                }
            })
            .catch((error) => {
                if (error?.status !== 409) {
                    throw new CustomException(error?.response?.data);
                }
                throw new CustomException(httpMessage.getAllError('o secretário'));
            });
    }

    async getSecretaryIsPrivate(secretaryId: number): Promise<boolean> {
        return this.http
            .get(keys.secretary.getSecretaryIsPrivate.replace(':secretaryId', secretaryId.toString()))
            .then((res) => {
                if (!res?.data) {
                    throw new CustomException(httpMessage.getAllResponseEmpty('secretário'));
                }
                return res.data;
            })
            .catch((error) => {
                if (error?.status !== 409) {
                    throw new CustomException(error?.response?.data);
                }
                throw new CustomException(httpMessage.getAllError('o secretário'));
            });
    }
}
