import units from "@/@core/modules/unit/infra/container.registry";
import user from "@/@core/modules/user/infra/container.registry";
import { Notification } from "@/@presentation/components/common";
import { getFieldsForFilter, getPageAndPerPage, List } from "@/@presentation/components/form";
import { Button, Flex, Form, Input, Modal, Radio } from "antd";
import React from "react";

interface IModal {
  setIsReload(arg0: boolean): unknown;
  isReload: boolean;
  setVisible: (visible: boolean) => void,
  visible: boolean,
  unitPortalId: number | undefined;
  unitName: string | undefined;
}

export const ModalSecretary: React.FC<IModal> = (props) => {
  const [loadingModal, setLoadingModal] = React.useState(false);
  const [paramsState, setParams] = React.useState<any>({
    limit: 5,
    offset: 0,
    search: '',
  });
  const [searched, setSearch] = React.useState('');
  const [isReload, setReload] = React.useState(false);
  const [selectedFilter, setSelectedFilter] = React.useState<string>('unit');
  const [secretariesUnitIds, setSecretariesUnitIds] = React.useState<number[]>([]);

  async function createSimplePaginatedSecretaryResponse(
    unitPortalId?: number,
    params: any = {}
  ): Promise<{ data: any; pagination: any }> {
    try {
      const request: any = await units.secretary.list.execute({ ...params, unitPortalId: unitPortalId });
      const { total, limit = 5, offset = 0 } = request;
      let rawData = request.data;

      if (params.search) {
        const searchTerm = params.search.toLowerCase();
        rawData = rawData.filter((item: any) =>
          item.name.toLowerCase().includes(searchTerm)
        );
      }

      const perPage = limit > 0 ? limit : 5;

      const currentPage = Math.floor(offset / perPage) + 1;
      const pageCount = Math.ceil(total / perPage);

      const mappedData = rawData.map((item: any) => ({
        id: item.id,
        person: {
          name: item.name,
        }
      }));
      const pagination = {
        total: total,
        pageCount: pageCount,
        page: currentPage,
        perPage: perPage,
        hasPreviousPage: currentPage > 1,
        hasNextPage: currentPage < pageCount
      };

      const result = {
        data: mappedData,
        pagination: pagination
      };

      return result;
    } catch (error) {
      return {
        data: [],
        pagination: {
          total: 0,
          pageCount: 0,
          page: 1,
          perPage: 5,
          hasPreviousPage: false,
          hasNextPage: false
        }
      };
    }
  }

  async function loadSecretaries() {
    const result = await units.unit.get.execute({
      id: props.unitPortalId!,
    });

    const secretariesId = result
      .secretaries
      .map((secretary: any) => secretary.id);

    setSecretariesUnitIds(secretariesId);
  }

  async function loadData(params?: any) {
    params.limit = 5;

    if (paramsState.search) {
      params.search = paramsState.search;
    }

    if (selectedFilter === 'unit' && props.unitPortalId) {
      const result = await createSimplePaginatedSecretaryResponse(props.unitPortalId, params);

      return result;
    }

    if (selectedFilter === 'all') {
      const result = await user.getAll.execute({
        ...getPageAndPerPage(params),
        ...getFieldsForFilter(params),
      });

      return result
    }
  }

  const addSecretary = (secretaryId: number) => {
    setLoadingModal(true);
    units.secretary.create.execute({
      secretaryId: secretaryId,
      unitPortalId: props.unitPortalId!,
    }).then(() => {
      setLoadingModal(false);
      loadSecretaries();
      setReload(true);
      props.setIsReload(!props.isReload);
    }).catch((e) => {
      if (e.message.message) {
        Notification.error({
          message: e.message.message,
          duration: 3,
        })
      }
      setLoadingModal(false);
    });
  };

  const search = (value: string) => {
    setSearch(value);
    setParams({
      ...paramsState,
      search: value,
    });
    loadSecretaries();
    setReload(true);
  }

  const removeSecretary = (secretaryId: number) => {
    setLoadingModal(true);
    units.secretary.delete.execute({
      secretaryId: secretaryId,
      unitPortalId: props.unitPortalId!,
    }).then(() => {
      setLoadingModal(false);
      loadSecretaries();
      props.setIsReload(!props.isReload);
      setReload(true);
    }).catch(() => {
      setLoadingModal(false);
    });
  }

  React.useEffect(() => {
    setParams({
      ...paramsState,
      search: '',
    })
    setSearch('');
    setReload(true);
  }, [selectedFilter]);

  React.useEffect(() => {
    if (props.visible === false) {
      setSecretariesUnitIds([]);
      setSelectedFilter('unit');
    } else {
      loadSecretaries();
      setReload(true);
      props.setIsReload(!props.isReload);
    }
  }, [props.visible, props.unitPortalId]);

  return (
    <Modal
      title={`Secretarias da unidade ${props.unitName}`}
      closable={false}
      open={props.visible}
      footer={[
        <Button
          key="submit"
          type='primary'
          onClick={
            () => props.setVisible(false)
          }
          loading={loadingModal}
        >
          OK
        </Button>,

      ]}
      width={'50%'}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <List
          limitPerPage={5}
          columns={[
            {
              title: 'Nome',
              key: 'name',
              width: '70%',
              render: (record) => (
                <p>
                  {record?.person?.name}
                </p>
              )
            },
            {
              title: '',
              key: 'actions',
              width: '30%',
              align: 'end',
              render: (text: any, record: any) => {
                if (secretariesUnitIds.includes(record?.secretaryId ? record.secretaryId : record.id)) {
                  return <Button type='default' onClick={() => removeSecretary(record.id)}>Remover</Button>
                }

                return (
                  <Button
                    type='primary'
                    onClick={() => {
                      addSecretary(record?.secretaryId ? record.secretaryId : record.id);
                    }}
                    loading={loadingModal}
                  >
                    Adicionar
                  </Button>
                );
              },
            },
          ]}
          loadData={loadData}
          Form={Form}
          setLoading={setLoadingModal}
          loading={loadingModal}
          setModalVisible={() => void 0}
          modalVisible={false}
          setParams={setParams}
          params={paramsState}
          setReload={setReload}
          isReload={isReload}
          hideDataFilter={true}
          selectedKeyView={() => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <div style={{ marginTop: '20px', marginBottom: '20px', width: '45%' }}>
                <Input.Search defaultValue={searched} onSearch={(e) => search(e)} size="middle" width="large"  placeholder="Pesquise por nome" />
              </div>
              <Flex vertical gap="middle">
                <Radio.Group options={[
                  { label: 'Secretarias da unidade', value: 'unit' },
                  { label: 'Todas as secretarias', value: 'all' },
                ]} defaultValue="unit" value={selectedFilter} onChange={(e) => setSelectedFilter(e.target.value)} />
              </Flex>
            </div>
          )}
        />
      </div>
    </Modal>
  );
}
