import React, { createContext, useContext, useState, ReactNode } from 'react';

interface ItemsContextType {
    selectedMenu: string;
    setSelectedMenu: React.Dispatch<React.SetStateAction<string>>;
    menuItems: any[];
    setMenuItems: React.Dispatch<React.SetStateAction<any[]>>;
}

const SelectedMenuContext = createContext<ItemsContextType | undefined>(undefined);

export const useSelectedMenuContext = () => {
  const context = useContext(SelectedMenuContext);
  if (!context) {
    throw new Error('useItemsContext must be used within a ItemsProvider');
  }
  return context;
};

interface ItemsProviderProps {
  children: ReactNode;
}

export const SelectedMenuProvider = ({ children }: ItemsProviderProps) => {
    const [selectedMenu, setSelectedMenu] = useState('/schedule');
    const [menuItems, setMenuItems] = useState<any>([]);

  return (
    <SelectedMenuContext.Provider value={{ 
        selectedMenu,
        setSelectedMenu,
        menuItems,
        setMenuItems,
    }}>
      {children}
    </SelectedMenuContext.Provider>
  );
};
