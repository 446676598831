import { TCreateSecretaryRequest, TCreateSecretaryResponse } from "../domain/portal-unit-secretary.entities";
import { UnitGateway } from "../domain/unit.gateway";

export class CreateUnitSecretaryUseCase {
    constructor(private unitGateway: UnitGateway) {}

    async execute(data: TCreateSecretaryRequest): Promise<TCreateSecretaryResponse> {
        return this.unitGateway.createSecretary(data);
    }
}
