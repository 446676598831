import Form from './pages/form';

import {ENUM_PERMISSIONS} from '@presentation/modules/route/helpers';

export const formattedRoute = (route: string, value?: any) => {
    return route.replace('/:key', `/${value}`).replace('/:id', `/${value}`);
};

export enum routes {
    PROCEDURE = '/procedure',
    PROCEDURE_CREATE = '/procedure/create',
    PROCEDURE_EDIT = '/procedure/edit/:id',
}

export const permissions = [
    ENUM_PERMISSIONS.admin,
    ENUM_PERMISSIONS.secretary,
    ENUM_PERMISSIONS.surgeon,
];

export const paths = [
    {
        label: '',
        key: routes.PROCEDURE_CREATE,
        icon: null,
        element: <Form />,
        permission: permissions,
        children: null,
    },
    {
        label: '',
        key: routes.PROCEDURE_EDIT,
        icon: null,
        element: <Form />,
        permission: permissions,
        children: null,
    },
]
