import { IAddressResponse } from "@/@core/modules/address/domain/address.entities";
import address from "@/@core/modules/address/infra/container.registry";
import { IPatientResponse } from "@/@core/modules/patient/domain/patient.entities";
import { patient } from "@/@core/modules/patient/infra/patient.container.registry";
import { IGuardianResponse } from "@/@core/modules/guardian/domain/guardian.entities";
import { guardian } from "@/@core/modules/guardian/infra/guardian.container.registry";
import { IScheduleGeneralItens, NationalityTypeForInterface, ProcedureContextData, TMedicalProcedures, TRadio, TSelect } from "@/@core/modules/procedure/domain/procedure.entities";
import procedure from "@/@core/modules/procedure/infra/container.registry";
import React, { createContext } from "react";

interface Props {
  children: React.ReactNode;
}

const ProcedureContext = createContext<ProcedureContextData>(
  {} as ProcedureContextData
);

const ProcedureProvider: React.FC<Props> = ({ children }) => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<any>();
  const [doctors, setDoctors] = React.useState<TSelect[]>([]);
  const [relationship, setRelationship] = React.useState<TSelect[]>([]);
  const [chargeMethods, setChargeMethods] = React.useState<TSelect[]>([]);
  const [procedureTypes, setProcedureTypes] = React.useState<TRadio[]>([]);
  const [procedureLocation, setProcedureLocation] = React.useState<TSelect[]>([]);
  const [genres, setGenres] = React.useState<TSelect[]>([]);
  const [medicalProcedures, setMedicalProcedures] = React.useState<TMedicalProcedures[]>([]);
  const [searchedAddress, setSearchedAddress] = React.useState<IAddressResponse>();
  const [searchedPatient, setSearchedPatient] = React.useState<IPatientResponse>();
  const [searchedGuardian, setSearchedGuardian] = React.useState<IGuardianResponse>();
  const [nationality, setNationality] = React.useState<NationalityTypeForInterface[]>([]);

  const handleDotors = React.useCallback(async (searchGeneral: IScheduleGeneralItens) => {
    const doctors = await procedure.medicalProcedure.getDoctors(searchGeneral);
    setDoctors(doctors);
  }, [])

  const handleNationality = React.useCallback(async (searchGeneral: IScheduleGeneralItens) => {
    const nationality = await procedure.medicalProcedure.getNationality(searchGeneral);
    setNationality(nationality);
  }, [])

  const handleRelationship = React.useCallback(async (searchGeneral: IScheduleGeneralItens) => {
    const relationship = await procedure.medicalProcedure.getRelationships(searchGeneral);
    setRelationship(relationship);
  }, [])

  const handleGetGenres = React.useCallback(async (searchGeneral: IScheduleGeneralItens) => {
    const genres = await procedure.medicalProcedure.getGenres(searchGeneral);
    setGenres(genres);
  }, [])

  const handleProcedureLocation = React.useCallback(async (searchGeneral: IScheduleGeneralItens) => {
    const location = await procedure.medicalProcedure.getProcedureLocation(searchGeneral);
    setProcedureLocation(location);
  }, [])

  const handleProcedureTypes = React.useCallback(async (searchGeneral: IScheduleGeneralItens) => {
    const types = await procedure.medicalProcedure.getProcedureTypes(searchGeneral);
    setProcedureTypes(types);
  }, [])

  const handleChargeMethods = React.useCallback(async (searchGeneral: IScheduleGeneralItens) => {
    const methods = await procedure.medicalProcedure.getChargeMethods(searchGeneral);
    setChargeMethods(methods);
  }, [])

  const handleListMedicalProcedures = React.useCallback(async (searchGeneral: IScheduleGeneralItens) => {
    const medicalProcedures = await procedure.medicalProcedure.execute(searchGeneral);
    setMedicalProcedures(medicalProcedures);
  }, [])

  const handleSearchZipCode = async (zip: string, reset: boolean = false) => {
    if (reset) {
      setSearchedAddress(undefined);
      return;
    }

    const response = await address.search.execute(zip);
    setSearchedAddress(response);
  }

  const handleLoadSearchGeneral = async (id?: string) => {
        const response = await procedure.create.loadSearchGeneral(id)
        handleInit(response)
  }

  const handleInit = async (searchGeneral: IScheduleGeneralItens) => {
    searchGeneral && await Promise.all([
      setData(searchGeneral.data),
      handleDotors(searchGeneral),
      handleNationality(searchGeneral),
      handleRelationship(searchGeneral),
      handleGetGenres(searchGeneral),
      handleProcedureLocation(searchGeneral),
      handleProcedureTypes(searchGeneral),
      handleChargeMethods(searchGeneral),
      handleListMedicalProcedures(searchGeneral),
    ]);
  };

  const handleFindPatientByCpf = async (cpf: string) => {
    try {
      const response = await patient.searchByCpf.execute(cpf);
      if (response) {
        setSearchedPatient(response);
      } else {
        setSearchedPatient(undefined);
      }
    } catch (error) {
      setSearchedPatient(undefined);
      console.error('Erro ao buscar paciente por CPF:', error);
    }
  };

  const handleFindGuardianByCpf = async (cpf: string) => {
    try {
      const response = await guardian.searchByCpf.execute(cpf);
      if (response) {
        setSearchedGuardian(response);
      } else {
        setSearchedGuardian(undefined);
      }
    } catch (error) {
      setSearchedGuardian(undefined);
      console.error('Erro ao buscar guardião por CPF:', error);
    }
  };

  const handleInitLoadSearchGeneral = async (id?: string) => {
    await handleLoadSearchGeneral(id)
  };

  const value = {
    states: {
      data,
      loading,
      doctors,
      relationship,
      genres,
      procedureLocation,
      procedureTypes,
      chargeMethods,
      medicalProcedures,
      searchedAddress,
      searchedPatient,
      searchedGuardian,
      nationality,
    },
    actions: {
      setLoading,
      handleSearchZipCode,
      handleInitLoadSearchGeneral,
      handleFindPatientByCpf,
      handleFindGuardianByCpf,
    },
  }

  return (
    <ProcedureContext.Provider value={value}>
      {children}
    </ProcedureContext.Provider>
  );
}

function useProcedure(): ProcedureContextData {
  const context = React.useContext(ProcedureContext);

  if (!context) {
    throw new Error('useProcedure must be used within an ProcedureProvider');
  }

  return context;
}

export { ProcedureProvider, useProcedure };
