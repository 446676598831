const container: React.CSSProperties = {
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
};

const link: React.CSSProperties = {
    color: '#B1B3B3',
    textAlign: 'center',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24.5px',
    letterSpacing: '0.4px',
    textTransform: 'uppercase',
};

const title: React.CSSProperties = {
    fontSize: 24,
    fontWeight: 600,
    color: 'rgb(0, 74, 125)',

    marginTop: 20,
    marginBottom: 4,
};

const description: React.CSSProperties = {
    fontSize: 16,
    fontWeight: 400,
    color: 'rgb(0, 74, 125)',
    textAlign: 'center',
    marginTop: 15,
    marginBottom: 30,
};

const checkbox: React.CSSProperties = {
    color: 'rgb(0, 74, 125)',
    fontSize: 16,
};

export default {
    container,
    link,
    title,
    description,
    checkbox,
};
