import {notification} from 'antd';

interface NotificationProps {
    [key: string]: any;
}

function success(props: NotificationProps) {
    notification.success({
        style: {
            backgroundColor: '#dcedc1',
            borderRadius: 10,
        },
        duration: 5,
        ...props,
        message: props?.message,
    });
}

async function error(props: NotificationProps) {
    return notification.error({
        style: {
            backgroundColor: '#f1d4d4',
            borderRadius: 10,
        },
        duration: 5,
        ...props,
        message: props?.message,
    });
}

function warning(props: NotificationProps) {
    notification.warning({
        style: {
            backgroundColor: '#f00',
            borderRadius: 10,
        },
        duration: 5,
        ...props,
        message: props?.message,
    });
}

function info(props: NotificationProps) {
    notification.info({
        style: {
            backgroundColor: '#f00',
            borderRadius: 10,
        },
        duration: 5,
        ...props,
        message: props?.message,
    });
}

function open(props: NotificationProps) {
    notification.open({
        style: {
            backgroundColor: '#f00',
            borderRadius: 10,
        },
        ...props,
        message: props?.message,
    });
}

export const Notification = {
    success,
    error,
    warning,
    info,
    open,
};
