import {LoadingOutlined} from '@ant-design/icons';
import {Spin} from 'antd';
import {ReactNode} from 'react';

const Icon = <LoadingOutlined style={{fontSize: 24}} spin />;

interface ILoading {
    value: boolean;
    children: ReactNode;
}

const Loading = (props: ILoading) => {
    return (
        <Spin data-testid="spin-form" spinning={props.value} indicator={Icon} tip="Carregando...">
            {props.children}
        </Spin>
    );
};

export {Icon, Loading};
