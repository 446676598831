import {ENUM_PERMISSIONS} from '@presentation/modules/route/helpers';
import ConcilitorList from './pages/conciliator';

export const formattedRoute = (route: string, value?: any) => {
    return route.replace('/:key', `/${value}`).replace('/:id', `/${value}`);
};

const hostname = window.location.protocol + '//' + window.location.host + '/';

export enum routes {
    CONCILIATOR = '/conciliator',
}

export const permissions = [
    ENUM_PERMISSIONS.conciliator,
];

export const paths = [
    {
        label: 'Conciliador',
        key: routes.CONCILIATOR,
        icon: hostname + 'icons/coin.svg',
        element: <ConcilitorList />,
        permission: permissions,
        children: null,
    },
]
