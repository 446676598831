import { TCreateSurgeonRequest, TCreateSurgeonResponse } from "../domain/portal-unit-surgeon.entities";
import { UnitGateway } from "../domain/unit.gateway";

export class CreateUnitSurgeonUseCase {
    constructor(private unitGateway: UnitGateway) {}

    async execute(data: TCreateSurgeonRequest): Promise<TCreateSurgeonResponse> {
        return this.unitGateway.createSurgeon(data);
    }
}
