import {AxiosInstance} from 'axios';
import MockAdapter from 'axios-mock-adapter';
import {setHeaderAuthorization} from '@core/modules/common/infra/http';
import {CustomException} from '@core/modules/common/infra/error';
import {
    RequestAccess,
    TypeForgotPassword,
    TypeRequestAccessParams,
} from '../domain/requestAccess.entities';
import {AuthenticationGateway} from '../domain/authentication.gateway';
import {Paths} from './keys';
import {
    mockAcceptTermsOfUseAndPrivacyPolicies,
    mockTermsOfUseAndPrivacyPolicies,
} from '../__mocks__/mocks';
import {UserInfos} from '../domain/userInfos.entities';
import {
    TermsOfUseAndPrivacyPolicies,
    MyTerms,
} from '../domain/termsOfUseAndPrivacyPolicies.entities';

export class AuthenticationHttpGateway implements AuthenticationGateway {
    constructor(private http: AxiosInstance, private mock: MockAdapter) {}

    async logout(): Promise<void> {
        await setHeaderAuthorization('...');
        localStorage.clear();
        localStorage.clear();
    }

    async requestAccess(params: TypeRequestAccessParams): Promise<RequestAccess> {
        return this.http
            .post(Paths.requestAccess, params)
            .then((res) => {
                if (!res?.data) {
                    throw new CustomException('Acesso negado, verifique suas credenciais.');
                }

                setHeaderAuthorization(res?.data?.access_token);
                return new RequestAccess(res?.data);
            })
            .catch(() => {
                throw new CustomException(`Acesso negado, verifique suas credenciais.`);
            });
    }

    async userInfos(): Promise<UserInfos> {
        return this.http
            .get(Paths.userInfo)
            .then((res) => {
                if (!res?.data) {
                    throw new CustomException(
                        'Usuário não encontrado, verifique sua conexão com a internet',
                    );
                }

                return new UserInfos(res?.data);
            })
            .catch(() => {
                throw new CustomException(
                    `Usuário não encontrado ou verifique sua conexão com a internet.`,
                );
            });
    }

    async forgotPassword(params: TypeForgotPassword): Promise<boolean> {
        return this.http
            .post(Paths.forgotPassword, params)
            .then((res) => {
                if (!res?.data) {
                    throw new CustomException('Desculpe, seu e-mail parecer estar incorreto!');
                }
                return true;
            })
            .catch(() => {
                throw new CustomException(`Desculpe, seu e-mail parecer estar incorreto!`);
            });
    }

    async forgotPasswordValidateCode(params: TypeForgotPassword): Promise<boolean> {
        return this.http
            .post(Paths.forgotPasswordValidate, params)
            .then((res) => {
                if (!res?.data) {
                    throw new CustomException(
                        'Desculpe, o código fornecido já expirou ou está incorreto!',
                    );
                }
                return true;
            })
            .catch(() => {
                throw new CustomException(
                    `Desculpe, o código fornecido já expirou ou está incorreto!`,
                );
            });
    }

    async forgotPasswordByCode(params: TypeForgotPassword): Promise<boolean> {
        return this.http
            .put(Paths.forgotPasswordByCode, {data: params})
            .then((res) => {
                if (!res?.data) {
                    throw new CustomException(
                        'Desculpe, não foi possível concluir a alteração neste momento.',
                    );
                }
                return true;
            })
            .catch(() => {
                throw new CustomException(
                    `Desculpe, não foi possível concluir a alteração neste momento.`,
                );
            });
    }

    async termsOfUseAndPrivacyPolicies(): Promise<TermsOfUseAndPrivacyPolicies> {
        mockTermsOfUseAndPrivacyPolicies(this.mock);

        return this.http
            .get(Paths.termsOfUseAndPrivacyPolicies)
            .then((res) => {
                if (!res?.data) {
                    throw new CustomException(
                        'Acesso negado, termos de uso e políticas de privacidade não encontrados, procure a administração.',
                    );
                }
                return new TermsOfUseAndPrivacyPolicies(res?.data);
            })
            .catch(() => {
                throw new CustomException(
                    `Acesso negado, termos de uso e políticas de privacidade não encontrados, procure a administração.`,
                );
            });
    }

    async acceptTermsOfUseAndPrivacyPolicies(): Promise<TermsOfUseAndPrivacyPolicies> {
        mockAcceptTermsOfUseAndPrivacyPolicies(this.mock);

        return this.http
            .post(Paths.termsOfUseAndPrivacyPolicies)
            .then((res) => {
                if (!res?.data) {
                    throw new CustomException(
                        'Acesso negado, o aceite do termos de uso e políticas de privacidade não foi salvo.',
                    );
                }
                return new TermsOfUseAndPrivacyPolicies(res?.data);
            })
            .catch(() => {
                throw new CustomException(
                    `Acesso negado,  o aceite do termos de uso e políticas de privacidade não foi salvo.`,
                );
            });
    }

    async myTermsOfUseAndPrivacyPolicies(): Promise<MyTerms | undefined> {
        return this.http
            .get(Paths.myTermsOfUseAndPrivacyPolicies)
            .then((res) => {
                if (!res?.data) {
                    return undefined;
                }
                return new MyTerms(res?.data);
            })
            .catch(() => {
                throw new CustomException(
                    `Acesso negado, termos de uso e políticas de privacidade não encontrados, procure a administração.`,
                );
            });
    }

    async acceptedLasTerm(): Promise<boolean> {
        return this.http
            .get(Paths.acceptedLasTerm)
            .then((res) => {
                return res?.data;
            })
            .catch(() => {
                throw new CustomException(
                    `Acesso negado, termos de uso e políticas de privacidade não encontrados, procure a administração.`,
                );
            });
    }
}
