import {Surgeon, TGet} from '../domain/surgeon.entities';
import {SurgeonGateway} from '../domain/surgeon.gateway';

export class CreateAccessUseCase {
    constructor(private gate: SurgeonGateway) {}

    async execute(params: TGet): Promise<Surgeon> {
        return this.gate.createAccess(params);
    }
}
