import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Form, Popover, Row, Typography, Space } from 'antd';
import excel from '@core/modules/common/infra/excel';
import schedule from '@core/modules/schedule/infra/container.registry';
import { Notification } from '@presentation/components/common';
const { Link } = Typography;
import { formattedRoute } from '../../../routes';
import { routes } from "../../../../procedure/routes";
import { Button } from '@/@presentation/components/form';


const Header = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);

    const download = async () => {
        try {
            setLoading(true);
            const result = await schedule.export.execute();
            if (result.data.length)
                return excel.exportToExcel({
                    data: result.data,
                    name: 'schedule',
                });
            Notification.error({
                message: 'Não foi encontrado informações!',
            });
        } catch {
            Notification.error({
                message: 'Não foi encontrado informações, retorno com error !',
            });
        } finally {
            setLoading(false);
        }
    };

    const content = () => {
        return (
            <Space direction="vertical">
                <Link style={{ color: '#A1A2A2' }} onClick={() => download()} target="_blank">
                    {loading ? 'CARREGANDO ...' : 'DOWNLOAD'}
                </Link>
            </Space>
        );
    };

    return (
        <Form layout="vertical" style={{ padding: 10 }}>
            <Row justify="end">
                <Button
                    text='Agendar'
                    onClick={() => navigate(formattedRoute(routes.PROCEDURE_CREATE))}
                    nativeProps={{
                        ghost: true,
                    }} />
            </Row>
        </Form>
    );
};

export default Header;
