export type TypeRequestAccessParams = {
    email: string;
    password: string;
    captchaToken: string; 
};

export type TypeForgotPassword = {
    email: string;
    code?: string;
    data?: {
        email: string;
        code: string;
        password: string;
    };
};

export type TResetPassword = {
    password: string;
    passwordConfirm: string;
};

export type TypeRequestAccess = {
    access_token: string;
    expires_in: number;
    refresh_expires_in: number;
    refresh_token: string;
    token_type: string;
    not_before_policy: number;
    session_state: string;
    scope: string;
    acceptTerms: boolean;
};

export class RequestAccess {
    constructor(public props: TypeRequestAccess | null) {}

    get access_token() {
        return this.props?.access_token;
    }
    get expires_in() {
        return this.props?.expires_in;
    }
    get refresh_expires_in() {
        return this.props?.refresh_expires_in;
    }
    get refresh_token() {
        return this.props?.refresh_token;
    }
    get token_type() {
        return this.props?.token_type;
    }
    get not_before_policy() {
        return this.props?.not_before_policy;
    }
    get session_state() {
        return this.props?.session_state;
    }
    get scope() {
        return this.props?.scope;
    }
    get acceptTerms() {
        return this.props?.acceptTerms;
    }

    toJSON() {
        return {
            access_token: this.access_token,
            expires_in: this.expires_in,
            refresh_expires_in: this.refresh_expires_in,
            refresh_token: this.refresh_token,
            token_type: this.token_type,
            not_before_policy: this.not_before_policy,
            session_state: this.session_state,
            scope: this.scope,
            acceptTerms: this.acceptTerms,
        };
    }
}
