import { Col, Row, Table } from "antd";
import React from "react";
import { CreditCardColumn, RightColumn, TicketAndPixColumn } from "./readValues.columns";
import { ConciliationTotal } from "@/@core/modules/conciliator/domain/conciliator.entity";

const dataCreditCard = (props: ConciliationTotal) => ({
    table1: [
        {
            key: '1',
            description: 'Valor Total Aprovado Bruto:',
            identified: props.bruteIdentifier,
            notIdentified: props.bruteNotIdentifier,
            total: props.brute,
        },
        {
            key: '2',
            description: 'Valor Taxas Aplicadas',
            identified: props.appliedFeeIdentifier,
            notIdentified: props.appliedFeeNotIdentifier,
            total: props.appliedFee,
        },
        {
            key: '3',
            description: 'Valor Total Líquido:',
            identified: props.brute - props.appliedFee,
            notIdentified: props.bruteNotIdentifier - props.appliedFeeNotIdentifier,
            total: props.brute - props.appliedFee,
        },
    ],
    table2: [
        {
            key: '1',
            description: '',
            processed: props.bruteProcessed,
            notReconciled: props.bruteDontConciliate,
        },
        {
            key: '2',
            description: '',
            processed: props.appliedFeeProcessed,
            notReconciled: props.appliedFeeDontConciliate,
        },
        {
            key: '3',
            description: '',
            processed: props.bruteProcessed - props.appliedFeeProcessed,
            notReconciled: props.bruteDontConciliate - props.appliedFeeDontConciliate,
        },
    ]
});

const CreditCard: React.FC<ConciliationTotal> = (props) => (
    <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col span={12}>
            <Table
                dataSource={dataCreditCard(props).table1}
                columns={CreditCardColumn}
                pagination={false}
                bordered
                size="small"
            />
        </Col>
        <Col span={12}>
            <Col span={12}>
                <Table
                    dataSource={dataCreditCard(props).table2}
                    columns={RightColumn}
                    pagination={false}
                    bordered
                    size="small"
                />
            </Col>
        </Col>
    </Row>
);

const TicketAndPix: React.FC<ConciliationTotal> = (props) => (
    <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col span={12}>
            <Table
                dataSource={dataCreditCard(props).table1}
                columns={CreditCardColumn}
                pagination={false}
                bordered
                size="small"
            />
        </Col>
        <Col span={12}>
            <Col span={12}>
                <Table
                    dataSource={dataCreditCard(props).table2}
                    columns={RightColumn}
                    pagination={false}
                    bordered
                    size="small"
                />
            </Col>
        </Col>
    </Row>
);

const ConciliatorReadValues: React.FC<{
    selectedPaymentType: 'CREDIT_CARD' | 'BANK_SLIP_AND_PIX';
    total: ConciliationTotal;
}> = (props) => {

    if (!props.total.brute) {
        return null;
    }

    if (props.selectedPaymentType === 'CREDIT_CARD') {
        return (
            <>
                <CreditCard {...props.total} />
            </>
        );
    }

    if (props.selectedPaymentType === 'BANK_SLIP_AND_PIX') {
        return (
            <>
                <TicketAndPix {...props.total} />
            </>
        );
    }
}

export default ConciliatorReadValues;
