import { Row, Col, Typography, Upload as UploadAntd, Button as ButtonAntd } from "antd";
import { Button } from "@/@presentation/components/form/Button";
import { CloseCircleFilled } from "@ant-design/icons";
import { DraggerProps, UploadChangeParam, UploadFile } from "antd/lib/upload";
import React, { useState } from "react";
import {file} from "@/@core/modules/file/infra/file.container.registry";
import { ENUM_PATH, ENUM_UPLOAD_TYPES } from "@/@core/modules/file/domain/gateway/file.gateway";
import { ProcedureContextData } from "@/@core/modules/procedure/domain/procedure.entities";

const { Dragger } = UploadAntd;

interface UploadProps {
  states?:  ProcedureContextData['states'];
  name: string,
  handleFileList: (file: UploadedType[]) => void,
  dragger?: DraggerProps,
  isConfirm: boolean,
}

interface UploadedProps {
  files: UploadedType[],
  onRemove: (file: UploadFile<any>) => void,
  isConfirm: boolean,
}

const bytesToMB = (bytes: number): string => {
  const megabytes = bytes / (1024 * 1024);
  return `${megabytes.toFixed(2)} MB`;
}

function formatDate(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  };
  return date.toLocaleDateString('pt-BR', options);
}

const Uploaded = ({ files, onRemove, isConfirm }: UploadedProps) => {
  return (
    <Row gutter={16}>
      {files.map((file) => (
        <Col
          key={file.id}
          span={12}
          style={{
            margin: '10px',
            maxWidth: 'calc(50% - 20px)',
            height: '80px',
            backgroundColor: '#EDF6F9',
          }}
        >
          <Row gutter={16} style={{ height: '80px' }}>
            <Col span={4}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 50,
                width: 50,
                backgroundColor: 'white',
                borderRadius: '50%',
              }}>
                <img alt="iconHide" src="/form/iconDirectoryUploadFile.svg" />
              </div>
            </Col>
            <Col span={16} style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
            }}>
              <Typography.Text>{file.file.name}</Typography.Text>
              <Typography style={{
                color: '#004A7D',
              }}>{bytesToMB(file.file.size || 0)} - {formatDate(file.uploadedDate)}</Typography>
            </Col>
            <Col
              span={4}
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'row-reverse',
                marginTop: 5,
              }}
            >
              {!isConfirm && <ButtonAntd
                type="text"
                icon={<CloseCircleFilled style={{ color: '#00A6D0' }} />}
                onClick={() => onRemove(file.file)}
              />}
            </Col>
          </Row>
        </Col>
      ))}
      {files.length % 2 === 1 && <Col span={12} />}
    </Row>
  )
}

export type UploadedType = {
  file: UploadFile<any>,
  uploadedDate: Date,
  id: number,
  type: ENUM_UPLOAD_TYPES,
}

const Upload = (props: UploadProps) => {
  const [fileList, setFileList] = useState<UploadedType[]>([]);

  const onRemove = async(fileToRemove: UploadFile) => {
    // const isSuccess = await file.remove.execute({id: fileToRemove.uid})
    // if (isSuccess) {
        setFileList((prevList) => prevList.filter((item) => item.file.uid !== fileToRemove.uid));
    //}
  };

  const onChange = async(info: UploadChangeParam<UploadFile<any>>) => {
    const isSuccess = await file.save.execute({
        file: info.file,
        uploadType: ENUM_UPLOAD_TYPES.DOCUMENT,
        path: ENUM_PATH.PATIENT
    })

    if (isSuccess) {
        setFileList((prevList) => {
          const data = [...prevList, {
            uploadedDate: new Date(),
            file: info.file,
            id: isSuccess.id,
            type: ENUM_UPLOAD_TYPES.DOCUMENT,
          }]
          props.handleFileList(data)
          return data;
        });
    }

  };

  const getDocuments = () => {
    const documents = props.states?.data?.uploads?.filter((item: any) => item.type === "DOCUMENT");
    if (documents && documents.length > 0) {
      if (fileList.length === 0) {
        setFileList(documents.map((file: any) => ({
          file: file,
          id: file.id,
          type: file.type,
          uploadedDate: new Date(),
        })));
      }
    }
  };

  React.useEffect(() => {
    props.states?.data?.uploads && getDocuments()
  }, []);

  return (
    <>
      <Row
        justify="space-between"
        align="middle"
        gutter={[8, 16]}
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'stretch',
          flexDirection: 'column',
        }}
      >
        {!props.isConfirm && <Dragger
          multiple={true}
          accept=".pdf,.png,.jpeg,.gif"
          fileList={fileList.map((file) => file.file)}
          onRemove={(event) => onRemove(event)}
          onChange={onChange}
          style={{
            padding: '16px',
            backgroundColor: '#EDF6F9',
          }}
          name={props.name}
          beforeUpload={() => false}
          showUploadList={false}
          {...props.dragger}
        >
          <p className="ant-upload-drag-icon">
            <img alt="iconHide" src="/form/upload.svg" />
          </p>
          <Typography style={{ color: '#004A7D' }}>
            Arraste e solte o arquivo aqui
          </Typography>
          <p className="ant-upload-hint">OU</p>
          <Button text="ADD. Anexo" />
        </Dragger>}
      </Row>
      <Typography style={{
        color: '#004A7D',
        marginTop: '16px',
        marginBottom: '16px'
      }}>Documentos Anexados</Typography>
      {fileList.length > 0 && <Uploaded files={fileList} onRemove={onRemove} isConfirm={props.isConfirm} />}
    </>
  );
};

export default Upload;
