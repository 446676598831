import React from 'react';
import {Button, Modal as AntModal, ModalProps, Row} from 'antd';

interface IModal extends Omit<ModalProps, 'onOk' | 'onCancel'> {
    visible: boolean
    children: React.ReactNode;
    title: string;
    handleOk: () => void;
    handleCancel: () => void;
}

const ModalConfirmationPayment: React.FC<IModal> = (props) => {        

    return (
        <AntModal
            data-testid="modal-form"
            destroyOnClose={true}
            forceRender
            title={props.title}
            open={props.visible}
            centered={true}
            onOk={props.handleOk}
            onCancel={props.handleCancel}
            width={700}
            footer={[
                <Button key="back" data-testid="modal-form-btn-cancel" style={{borderColor: '#de1010', color: '#de1010'}} onClick={props.handleCancel}>
                    Não
                </Button>,
                <Button key="back" data-testid="modal-form-btn-cancel" style={{backgroundColor: '#298f46', color: '#fff'}} onClick={props.handleOk}>
                    Sim
                </Button>
            ]}
        >
            <Row>
                <p>Deseja fazer o pagamento agora?</p>
            </Row>
        </AntModal>
    );
};

export {ModalConfirmationPayment};
