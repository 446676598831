import React from 'react';
import {useParams, useNavigate, NavigateFunction} from 'react-router-dom';
import dayjs from 'dayjs';
import {Form as AntForm, Col, Typography, Row, Button, Flex, DatePicker, Divider} from 'antd';
import {Notification} from '@presentation/components/common';
import {
    validateMessages,
    Loading,
    SelectGender,
    InputDefault,
    InputDefaultNumber,
    InputCpf,
    InputCnpj,
    initialize,
} from '@presentation/components/form';
import surgeon from '@core/modules/surgeon/infra/container.registry';
import {Surgeon, TCreateOrUpdate} from '@core/modules/surgeon/domain/surgeon.entities';
import style from './style';
import {formattedRoute, routes} from '../../../routes';

const {Text} = Typography;

const NotFound = (navigate: NavigateFunction) => {
    Notification.error({
        message: 'Cirurgião não encontrado!',
    });
    navigate(formattedRoute(routes.ADMINISTRATION_KEY, 'surgeon'));
};

function SurgeonForm() {
    const navigate = useNavigate();
    const {id} = useParams();
    const [loading, setLoading] = React.useState(false);
    const [form] = AntForm.useForm<TCreateOrUpdate>();
    const [data, setData] = React.useState<Surgeon>();

    const onFinish = async (values: TCreateOrUpdate) => {
        try {
            setLoading(true);
            if (id) {
                await surgeon.update.execute({
                    ...values,
                    id,
                    birthdayDate: values?.birthdayDateFormatted,
                });
            } else {
                await surgeon.create.execute({
                    ...values,
                    birthdayDate: values?.birthdayDateFormatted,
                });
            }

            Notification.success({
                message: id
                    ? 'Dados do cirurgião alterados com sucesso!'
                    : 'Cirurgião criado com sucesso!',
            });
        } catch (error: any) {
            Notification.error({
                message: error.message,
            });
        } finally {
            form.resetFields();
            setLoading(false);
            navigate(formattedRoute(routes.ADMINISTRATION_KEY, 'surgeon'));
        }
    };

    const loadData = React.useCallback(async () => {
        if (id && id !== undefined) {
            setLoading(true);
            try {
                const result = await surgeon.get.execute({id: id});
                setData(result);

                if (!result?.person?.name) {
                    NotFound(navigate);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
    }, [id]);

    React.useEffect(() => {
        loadData();
    }, [loadData]);

    React.useEffect(() => {
        const formatData = {
            id: data?.id,
            ...data?.person,
            surname: data?.surname,
            completeName: data?.person?.name,
        };
        initialize(setLoading, form, formatData);

        if (data?.person?.birthdayDate) {
            form.setFieldsValue({
                birthdayDateFormatted: dayjs(data.person.birthdayDate),
            });
        }
    }, [data]);

    const title = id ? 'Editar' : 'Cadastrar';

    return (
        <div style={{width: '90%', margin: 10}}>
            <Row>
                <Text style={style.title}>Cirurgião</Text>
            </Row>
            <Row>
                <Text style={style.dataTitle}>{title} Dados</Text>
            </Row>
            <Loading value={loading}>
                <AntForm
                    initialValues={data}
                    name="form-surgeon-edit"
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                    validateMessages={validateMessages}
                    autoComplete="off"
                    style={{margin: 40}}
                >
                    <Text style={style.subTitle}>Dados do cirurgião</Text>
                    <Flex wrap="wrap" gap="large" style={{marginTop: 10}}>
                        <Col xs={24} sm={11}>
                            <InputDefault
                                name="completeName"
                                placeholder="Digite o nome "
                                required={true}
                            />
                        </Col>
                        <Col xs={24} sm={11}>
                            <InputDefault
                                name="surname"
                                label="Apelido"
                                placeholder="Digite o apelido "
                            />
                        </Col>
                    </Flex>
                    <Flex wrap="wrap" gap="large">
                        <Col xs={24} sm={4}>
                            <AntForm.Item
                                name={'birthdayDateFormatted'}
                                label="Data de nascimento"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <DatePicker
                                    style={{
                                        minWidth: '100%',
                                        maxWidth: '100%',
                                        borderRadius: 20,
                                        height: 40,
                                    }}
                                    format="DD/MM/YYYY"
                                    onChange={(value) =>
                                        form.setFieldsValue({
                                            birthdayDate: value?.toString(),
                                        })
                                    }
                                    value={form.getFieldValue('birthdayDateFormatted')}
                                    allowClear={false}
                                />
                            </AntForm.Item>
                        </Col>
                        <Col xs={24} sm={2}>
                            <InputDefaultNumber
                                disabled
                                name="age"
                                label="Idade"
                                placeholder="Digite a idade"
                                height={40}
                            />
                        </Col>
                        <Col xs={24} sm={3}>
                            <SelectGender />
                        </Col>
                        <Col xs={24} sm={7}>
                            <InputCpf name="cpf" label="CPF" placeholder="Digite o CPF" />
                        </Col>
                    </Flex>
                    <Flex wrap="wrap" gap="large">
                        <Col xs={24} sm={6}>
                            <InputCnpj name="cnpj" label="CNPJ" placeholder="Digite o CNPJ" />
                        </Col>
                        <Col xs={24} sm={7}>
                            <InputDefault
                                name="companyName"
                                label="Razão social"
                                placeholder="Digite a razão social "
                            />
                        </Col>
                        <Col xs={24} sm={9}>
                            <InputDefault
                                name="tradeName"
                                label="Nome fantasia"
                                placeholder="Digite o nome fantasia "
                            />
                        </Col>
                    </Flex>
                    <Divider />

                    <Row style={{margin: 30}}>
                        <Col style={{width: '50%'}}>
                            <Button
                                key="submit"
                                data-testid="form-btn-back"
                                type="text"
                                loading={loading}
                                onClick={() => navigate('/administration/surgeon')}
                            >
                                VOLTAR
                            </Button>
                        </Col>
                        <Col style={{width: '50%', textAlign: 'right'}}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                data-testid="form-btn-save"
                                loading={loading}
                                style={{
                                    borderRadius: 20,
                                    width: '30%',
                                    color: '#FFFFFF',
                                    backgroundColor: '#43AB66',
                                    border: '2px solid #FFFFFF',
                                    boxShadow: '0 0 0 5px #43AB66',
                                    marginBottom: '5%',
                                }}
                            >
                                SALVAR
                            </Button>
                        </Col>
                    </Row>
                </AntForm>
            </Loading>
        </div>
    );
}

export default SurgeonForm;
