import React, {useEffect} from 'react';
import {Modal as AntModal, Typography, Checkbox, Button, Col, Row} from 'antd';
import {useNavigate} from 'react-router-dom';
import {auth} from '@core/modules/authentication/infra/container.registry';
import {Notification, textDateTimeFormat} from '@presentation/components/common';
import authentication from '@presentation/modules/route/authentication';
import style from './style';
import {TMyTerms} from '@core/modules/authentication/domain/termsOfUseAndPrivacyPolicies.entities';

export interface ITermsOfUseAndPrivacyPolicies {
    visible: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ComponentTermsOfUseAndPrivacyPolicies = (props: ITermsOfUseAndPrivacyPolicies) => {
    const navigate = useNavigate();
    const [contractTermsPrivacy, setContractTermsPrivacy] = React.useState<TMyTerms>();
    const [loading, setLoading] = React.useState(true);
    const [accept, setAccept] = React.useState(false);

    const initialize = React.useCallback(async () => {
        setLoading(true);
        const currentTerms = await auth.myTermsOfUseAndPrivacyPoliciesUseCase.execute();
        if (currentTerms) {
            setContractTermsPrivacy(currentTerms);
        }
        setLoading(false);
    }, []);

    const handleReadAndAccept = (value: boolean) => {
        setAccept(value);
    };

    const handleAccept = async () => {
        try {
            setLoading(true);
            await auth.acceptTermsOfUseAndPrivacyPolicies.execute();
            props.setVisible(false);
            navigate(authentication.defaultRoute.key);
        } catch (error: any) {
            Notification.error({
                message: error.message,
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        initialize();
    }, [initialize]);

    return (
        <>
            <AntModal
                data-testid="modal-form"
                destroyOnClose={true}
                forceRender
                title={
                    <Typography.Title style={style.title}>
                        Termos de Uso e Políticas de Privacidade
                    </Typography.Title>
                }
                open={props.visible}
                centered={true}
                onOk={() => props.setVisible(!props.visible)}
                width="90%"
                closable={false}
                footer={[
                    <Row justify="center" key="row-footer">
                        <Col>
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                                disabled={!accept}
                                loading={loading}
                                onClick={() => handleAccept()}
                            >
                                ACEITAR
                            </Button>
                        </Col>
                    </Row>,
                ]}
            >
                <div style={style.container}>
                    <Typography.Title style={style.description}>
                        Antes de prosseguir, conheça os nossos Termos de Uso de Políticas de
                        Privacidade
                    </Typography.Title>
                    <iframe
                        title="PDF Viewer"
                        src={contractTermsPrivacy?.upload?.path}
                        width="100%"
                        height="400"
                    ></iframe>
                    {(contractTermsPrivacy?.upload?.updatedAt ||
                        contractTermsPrivacy?.upload?.createdAt) && (
                        <Typography.Title style={style.description}>
                            Última Atualização:{' '}
                            {textDateTimeFormat(
                                contractTermsPrivacy?.upload?.updatedAt ??
                                    contractTermsPrivacy?.upload?.createdAt,
                            )}
                        </Typography.Title>
                    )}
                    <Checkbox
                        style={style.checkbox}
                        checked={accept}
                        onChange={(e) => handleReadAndAccept(e.target.checked)}
                    >
                        Li e aceito os Termos de Uso e Políticas de Privacidade
                    </Checkbox>
                </div>
            </AntModal>
        </>
    );
};

export {ComponentTermsOfUseAndPrivacyPolicies};
