import 'reflect-metadata';
import {Container} from 'inversify';
import {httpApi, mockApi} from '../../common/infra/http';

import {AuthenticationHttpGateway} from './authentication.http.gateway';
import {AuthenticationLSGateway} from './authentication.ls.gateway';

import {RequestAccessUseCase} from '../application/requestAccess.use.case';
import {ForgotPasswordUseCase} from '../application/forgotPassword.use.case';
import {UserInfosUseCase} from '../application/userInfos.use.case';
import {AcceptTermsOfUseAndPrivacyPoliciesUseCase} from '../application/acceptTermsOfUseAndPrivacyPolicies.use.case';
import {TermsOfUseAndPrivacyPoliciesUseCase} from '../application/termsOfUseAndPrivacyPolicies.use.case';
import {MyTermsOfUseAndPrivacyPoliciesUseCase} from '../application/myTermsOfUseAndPrivacyPolicies.use.case';
import {LogoutUseCase} from '../application/logout.use.case';
import {AcceptedLasTermUseCase} from '../application/acceptedLasTerm.use.case';

export const Registry = {
    AxiosAdapter: Symbol.for('AxiosAdapter'),
    MockAdapter: Symbol.for('MockAdapter'),

    AuthenticationGateway: Symbol.for('AuthenticationGateway'),
    AuthenticationLocalStorageGateway: Symbol.for('AuthenticationLocalStorageGateway'),

    RequestAccessUseCase: Symbol.for('RequestAccessUseCase'),
    ForgotPasswordUseCase: Symbol.for('ForgotPasswordUseCase'),
    UserInfosUseCase: Symbol.for('UserInfosUseCase'),
    AcceptTermsOfUseAndPrivacyPoliciesUseCase: Symbol.for(
        'AcceptTermsOfUseAndPrivacyPoliciesUseCase',
    ),
    TermsOfUseAndPrivacyPoliciesUseCase: Symbol.for('TermsOfUseAndPrivacyPoliciesUseCase'),
    MyTermsOfUseAndPrivacyPoliciesUseCase: Symbol.for('MyTermsOfUseAndPrivacyPoliciesUseCase'),
    LogoutUseCase: Symbol.for('LogoutUseCase'),
    AcceptedLasTermUseCase: Symbol.for('AcceptedLasTermUseCase'),
};

export const container = new Container();

//########## HTTP
container.bind(Registry.AxiosAdapter).toConstantValue(httpApi);
container.bind(Registry.MockAdapter).toConstantValue(mockApi);

//#region ########## GATEWAYS
container.bind(Registry.AuthenticationGateway).toDynamicValue((context) => {
    return new AuthenticationHttpGateway(
        context.container.get(Registry.AxiosAdapter),
        context.container.get(Registry.MockAdapter),
    );
});
container.bind(Registry.AuthenticationLocalStorageGateway).to(AuthenticationLSGateway);
//#endregion

//#region ########## USE CASES
container.bind(Registry.RequestAccessUseCase).toDynamicValue((context) => {
    return new RequestAccessUseCase(
        context.container.get(Registry.AuthenticationGateway),
        context.container.get(Registry.AuthenticationLocalStorageGateway),
    );
});
container.bind(Registry.ForgotPasswordUseCase).toDynamicValue((context) => {
    return new ForgotPasswordUseCase(context.container.get(Registry.AuthenticationGateway));
});
container.bind(Registry.UserInfosUseCase).toDynamicValue((context) => {
    return new UserInfosUseCase(
        context.container.get(Registry.AuthenticationGateway),
        context.container.get(Registry.AuthenticationLocalStorageGateway),
    );
});
container.bind(Registry.AcceptTermsOfUseAndPrivacyPoliciesUseCase).toDynamicValue((context) => {
    return new AcceptTermsOfUseAndPrivacyPoliciesUseCase(
        context.container.get(Registry.AuthenticationGateway),
    );
});

container.bind(Registry.TermsOfUseAndPrivacyPoliciesUseCase).toDynamicValue((context) => {
    return new TermsOfUseAndPrivacyPoliciesUseCase(
        context.container.get(Registry.AuthenticationGateway),
    );
});
container.bind(Registry.MyTermsOfUseAndPrivacyPoliciesUseCase).toDynamicValue((context) => {
    return new MyTermsOfUseAndPrivacyPoliciesUseCase(
        context.container.get(Registry.AuthenticationGateway),
    );
});
container.bind(Registry.LogoutUseCase).toDynamicValue((context) => {
    return new LogoutUseCase(
        context.container.get(Registry.AuthenticationGateway),
        context.container.get(Registry.AuthenticationLocalStorageGateway),
    );
});
container.bind(Registry.AcceptedLasTermUseCase).toDynamicValue((context) => {
    return new AcceptedLasTermUseCase(context.container.get(Registry.AuthenticationGateway));
});

//#endregion

//#region ######### CONTAINERS GET
export const auth = {
    requestAccess: container.get<RequestAccessUseCase>(Registry.RequestAccessUseCase),
    forgotPassword: container.get<ForgotPasswordUseCase>(Registry.ForgotPasswordUseCase),
    userInfos: container.get<UserInfosUseCase>(Registry.UserInfosUseCase),
    termsOfUseAndPrivacyPolicies: container.get<TermsOfUseAndPrivacyPoliciesUseCase>(
        Registry.TermsOfUseAndPrivacyPoliciesUseCase,
    ),
    acceptTermsOfUseAndPrivacyPolicies: container.get<AcceptTermsOfUseAndPrivacyPoliciesUseCase>(
        Registry.AcceptTermsOfUseAndPrivacyPoliciesUseCase,
    ),
    myTermsOfUseAndPrivacyPoliciesUseCase: container.get<MyTermsOfUseAndPrivacyPoliciesUseCase>(
        Registry.MyTermsOfUseAndPrivacyPoliciesUseCase,
    ),
    logout: container.get<LogoutUseCase>(Registry.LogoutUseCase),
    acceptLastTerm: container.get<AcceptedLasTermUseCase>(Registry.AcceptedLasTermUseCase),
};
//#endregion
