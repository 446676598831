import { Form as AntForm, Radio, RadioChangeEvent } from 'antd';
import React from 'react';

interface IProps {
    name: string;
    label?: string;
    options: Array<{ title: string; value: string }>;
    seleted?: string;
    required?: boolean;
}

const RadioForm = (props: IProps) => {
    const [value, setValue] = React.useState<string>();

    const onChange = (e: RadioChangeEvent) => {
        setValue(e.target.value);
    };

    return (
        <AntForm.Item
            name={props?.name ?? 'options'}
            label={props?.label ?? ''}
            rules={[{ required: props.required ?? false }]}
        >
            <Radio.Group onChange={onChange} value={value} style={{ marginTop: 30 }} defaultValue={props.seleted ?? props.seleted}>
                {props.options.map((item, index) => (
                    <Radio key={index} value={item.value}>
                        {item.title}
                    </Radio>
                ))}
            </Radio.Group>
        </AntForm.Item>
    );
};

export { RadioForm };
