import {FormInstance, Modal as AntModal} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {Notification} from '@presentation/components/common';

const reset = (ocultarModal: () => void, form: FormInstance<any>) => {
    ocultarModal();
    form.resetFields();
};

function handleCancel(form: FormInstance<any>, ocultarModal: () => void) {
    if (form.isFieldsTouched()) {
        AntModal.confirm({
            title: 'Atenção',
            centered: true,
            icon: <ExclamationCircleOutlined />,
            content: (
                <span>
                    <br />
                    Você deseja prosseguir e fechar a tela?
                </span>
            ),
            okText: 'Confirmar',
            okButtonProps: {style: {backgroundColor: '#004A7D'}},
            cancelButtonProps: {
                style: {borderColor: '#004A7D', color: 'black'},
            },
            cancelText: 'Cancelar',
            onOk: () => {
                reset(ocultarModal, form);
            },
        });
    } else {
        AntModal.destroyAll();
        reset(ocultarModal, form);
    }
}

function handleOk(form: FormInstance<any>) {
    form.validateFields().then(() => {
        form.submit();
    });
}

const isChangeValues = (
    values: any,
    params: any,
    form: FormInstance<any>,
    ocultarModal: () => void,
) => {
    const keys = Object.keys(values);
    for (const key of keys) {
        if (values[key] !== params?.[key]) {
            return true;
        }
    }
    reset(ocultarModal, form);
    return false;
};

async function initialize(
    setLoading: (value: React.SetStateAction<boolean>) => void,
    form: FormInstance<any>,
    params: any,
) {
    if (!form) return;
    if (params?.id) {
        try {
            setLoading(true);
            form.setFieldsValue(params);
        } catch (error: any) {
            Notification.error({
                message: error.message,
            });
        } finally {
            setLoading(false);
        }
    } else {
        form.setFieldsValue({
            active: true,
        });
    }
}

const handleSave = async (
    values: any,
    functionSave: (values: any) => Promise<void>,
    setLoading: (value: React.SetStateAction<boolean>) => void,
    form: FormInstance<any>,
    ocultarModal: () => void,
    callback: () => Promise<void>,
) => {
    try {
        setLoading(true);
        await functionSave(values);
        reset(ocultarModal, form);
        if (typeof callback == 'function') {
            callback();
        }
    } catch (error: any) {
        Notification.error({
            message: error.message,
        });
    } finally {
        setLoading(false);
    }
};

const handleDelete = async (
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setReload: React.Dispatch<React.SetStateAction<boolean>>,
    callback: () => Promise<boolean>,
) => {
    try {
        setLoading(true);
        if (typeof callback === 'function') {
            const result = await callback();
            if (result) {
                Notification.success({
                    message: 'Removido com sucesso!',
                    duration: 2,
                });
            } else {
                Notification.error({
                    message: 'Erro ao remover.',
                });
            }
        }
    } catch (error: any) {
        Notification.error({
            message: error.message,
        });
    } finally {
        setLoading(false);
        setReload(true);
    }
};

const getFieldsForFilter = (params: any) => {
    const {selectedKey, value} = params;
    if (!selectedKey && !value) return;
    return {[selectedKey]: value};
};

const getPageAndPerPage = (params: any) => {
    const {
        offset,
        limit,
        dtIni, 
        dtFim,
        schedule_id,
        search,
        status,
        patient,
        requester,
        surgeon_ids,
        unity_ids,
        createdAt,
        endCreatedAt,
        statusBilling,
        ignoreCanceled,
    } = params;
    return {
        offset,
        limit,
        dtIni,
        dtFim,
        schedule_id,
        search,
        status,
        patient,
        requester,
        surgeon_ids,
        unity_ids,
        createdAt,
        endCreatedAt,
        statusBilling,
        ignoreCanceled,
    };
};

export {
    initialize,
    reset,
    handleCancel,
    handleOk,
    handleSave,
    isChangeValues,
    handleDelete,
    getFieldsForFilter,
    getPageAndPerPage,
};
