import Modal from "@/@presentation/components/common/modal"
import { Button, Typography } from "antd"

interface IConfirmeModel {
  onOk: (isConfirm: boolean) => void;
  open: boolean;
  text: string;
  title?: string;
}

const ModalError = (props: IConfirmeModel) => {
  const footerConfirmModal: React.ReactNode[] = [
    <Button key='noConfirm' onClick={() => props.onOk(false)}>ok</Button>,
  ]

  return (
    <Modal
      footer={footerConfirmModal}
      open={props.open}
      title={props.title || 'Confirmar procedimento'}
    >
      <Typography>{props.text}</Typography>
    </Modal>
  )
}

export default ModalError;