import React, {useEffect} from 'react';
import {Modal as AntModal} from 'antd';
import {LinkForgotPassword} from './link';

import {FormSendEmail, FormVerifyCode, FormChangePassword} from '@presentation/components/form';

const ForgotPassword = () => {
    const [loading, setLoading] = React.useState(false);
    const [visible, setVisible] = React.useState(false);
    const [isSendEmail, setSendEmail] = React.useState(false);
    const [isVerifyCode, setIsVerifyCode] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [code, setCode] = React.useState('');

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setSendEmail(false);
            setIsVerifyCode(false);
            setLoading(false);
            setCode('');
            setEmail('');
        }, 300);
    }, [visible]);

    return (
        <>
            <LinkForgotPassword onClick={() => setVisible(!visible)} />
            <AntModal
                data-testid="modal-form"
                destroyOnClose={true}
                forceRender
                title={null}
                open={visible}
                centered={true}
                onOk={() => setVisible(!visible)}
                onCancel={() => setVisible(!visible)}
                width={380}
                footer={null}
            >
                {!isSendEmail && (
                    <FormSendEmail
                        loading={loading}
                        setLoading={setLoading}
                        setSendEmail={setSendEmail}
                        setEmail={setEmail}
                    />
                )}
                {isSendEmail && !isVerifyCode && (
                    <FormVerifyCode
                        loading={loading}
                        setLoading={setLoading}
                        setIsVerifyCode={setIsVerifyCode}
                        email={email}
                        setCode={setCode}
                    />
                )}
                {isVerifyCode && (
                    <FormChangePassword
                        loading={loading}
                        setLoading={setLoading}
                        setVisible={setVisible}
                        visible={visible}
                        email={email}
                        code={code}
                    />
                )}
            </AntModal>
        </>
    );
};

export {ForgotPassword};
