import { TSelect } from "@/@core/modules/procedure/domain/procedure.entities";
import procedure from "@/@core/modules/procedure/infra/container.registry";
import Modal from "@/@presentation/components/common/modal"
import { Button } from "@/@presentation/components/form";
import { styles } from "@/@presentation/modules/procedure/pages/form/styles";
import { Form as AntForm, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";

interface ICancelModal {
  onCancel: (text: string, other: string) => void;
  open: boolean;
  setClose: (value: boolean) => void;
}

const CancelModal = (props: ICancelModal) => {
  const [reasonCancelSchedule, setReasonCancelSchedule] = React.useState<TSelect[]>([]);
  const [selectedReason, setSelectedReason] = React.useState<string>('');
  const [other, setOther] = React.useState('')
  const [form] = AntForm.useForm<any>();

  const handleSubmit = () => {
    form.validateFields()
    .then(() => {
      props.onCancel(selectedReason, other);
      form.resetFields();
    })
    .catch(() => {
      return;
    });
  }

  const handleCancel = () => {
    form.resetFields();
    setSelectedReason('');
    props.onCancel(selectedReason, other);
  }

  const footerConfirmModal: React.ReactNode[] = [
    <Button text="Voltar" key='noConfirm' onClick={() => props.setClose(false)} nativeProps={{ ghost: true }} />,
    <Button text="Confirmar" key='yesConfirm' onClick={handleSubmit}/>,
  ]

  const handleReasonCancelSchedule = React.useCallback(async () => {
    const reasons = await procedure.medicalProcedure.getReasonCancelSchedule();
    setReasonCancelSchedule(reasons);
  }, []);

  React.useEffect(() => {
    handleReasonCancelSchedule();
  }, []);

  return (
    <Modal
      footer={footerConfirmModal}
      open={props.open}
      title='Cancelar agendamento'
    >
      <AntForm
        name="form-cancel-schedule"
        form={form}
        layout="vertical"
        autoComplete="off"
      >
        <AntForm.Item
          name="reason"
          required
          label="Motivo"
          rules={[{ required: true, message: 'Por favor, selecione o motivo' }]}
          help={form.getFieldError('reason') ? form.getFieldError('reason')[0] : null}
        >
          <Select
            placeholder="Selecione o motivo"
            data-testid="select-reason"
            style={{
              minWidth: 200,
              width: '100%',
            }}
            onChange={(value) => setSelectedReason(value)}
            value={selectedReason}
          >
            {reasonCancelSchedule.map((reason) => <Select.Option key={reason.value} value={reason.value}>{reason.label}</Select.Option>)}
          </Select>
        </AntForm.Item>
        {
          selectedReason === 'other' && ( // alterar para o nome ou ID caso seja ID unico
            <AntForm.Item
              name="description-reason"
              required
              label="Informe o motivo"
              rules={[{ required: true, message: 'Por favor, informe o motivo' }]}
              help={form.getFieldError('description-reason') ? form.getFieldError('description-reason')[0] : null}
            >
            <TextArea
                placeholder="Informe o motivo"
                style={{ ...styles.defaultInput, borderRadius: 20 }}
                autoSize={{ minRows: 5, maxRows: 8 }}
                onChange={(e) => setOther(e.target.value)}
            />
        </AntForm.Item>
          )
        }
      </AntForm>
    </Modal>
  )
}

export default CancelModal;
