import { InputCpf, InputDefault, InputPhone } from '@/@presentation/components/form';
import { Row, Form as AntForm, Select, FormInstance } from 'antd';
import React from 'react';
import { styles } from '../../styles';
import { ProcedureContextData } from '@/@core/modules/procedure/domain/procedure.entities';
import { validateCPF } from '@/@core/modules/common/infra/validateCPF';
import { ConfirmationView } from '@/@presentation/modules/procedure/components/confirmationView';

interface IProps {
  handleSearchResponsibleByCpf: (cpf: string) => void;
  states: ProcedureContextData['states'];
  isConfirm: boolean;
  setHasError: (hasError: boolean) => void;
  form: FormInstance<any>;
  changeGuardian: () => void;
}

export const Responsible: React.FC<IProps> = (props) => {
  const formItems = props.form.getFieldsValue()
  const [invalidCpf, setInvalidCpf] = React.useState(false)

  const handleChange = (cpf: string): void => {
    cpf = cpf.replace(/\D/g, '')
    if (cpf.length === 11) {
      const isInvalid = !validateCPF(cpf)
      props.handleSearchResponsibleByCpf(cpf)
      props.changeGuardian()
      setInvalidCpf(isInvalid)
      props.setHasError(isInvalid)
    }
  }

  return (
    <>
      <Row>
        <div
          style={{
            marginRight: 20,
          }}
        >
          {
            props.isConfirm ? (
              <ConfirmationView item={{
                label: 'CPF',
                value: formItems.responsibleCpf || ''
              }} />
            ) : (
              <InputCpf
                name="responsibleCpf"
                label="CPF"
                placeholder='Digite o CPF'
                onChange={(e) => handleChange(e.target.value)}
                error={{ isError: invalidCpf, message: 'CPF invalido!' }}
              />
            )
          }
        </div>
        {
          props.isConfirm ? (
            <ConfirmationView item={{
              label: 'Nome completo',
              value: formItems.responsibleFullName || ''
            }} />
          ) : (
            <InputDefault
              label="Nome completo"
              name="responsibleFullName"
              placeholder="Nome completo do responsável"
              nativeProps={{
                style: {
                  width: 400,
                },
                disabled: props.isConfirm
              }}
            />
          )
        }
      </Row>
      <Row>
        {
          props.isConfirm ? (
            <ConfirmationView item={{
              label: 'Nome completo',
              value: formItems.responsibleFullName || ''
            }} />
          ) : (
            <AntForm.Item name="responsibleRelationship" label="Grau de parentesco" style={{ marginRight: 20 }}>
              <Select
                disabled={props.isConfirm}
                data-testid="select-procedure-responsible-relationship"
                style={styles.defaultInput}
                placeholder="Selecione"
              >
                {props.states.relationship.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </AntForm.Item>
          )
        }
        <div style={{ marginRight: 20 }}>
          {
            props.isConfirm ? (
              <ConfirmationView item={{
                label: 'E-mail',
                value: formItems.responsibleEmail || ''
              }} />
            ) : (
              <InputDefault
                label="E-mail"
                name="responsibleEmail"
                placeholder="Digite o e-mail do responsável"
                nativeProps={{
                  type: "email",
                  style: {
                    width: 300,
                  },
                  disabled: props.isConfirm
                }}
              />
            )
          }
        </div>
        {
          props.isConfirm ? (
            <ConfirmationView item={{
              label: 'Telefone',
              value: formItems.responsibleEmail || ''
            }} />
          ) : (
            <InputPhone label="Telefone" name="responsiblePhone" />
          )
        }
      </Row>
    </>
  )
};