import 'reflect-metadata';
import {Container} from 'inversify';
import {httpApi, mockApi} from '../../common/infra/http';
import {GuardianHttpGateway} from './guardian.http.gateway';
import {FindUseCase} from '../../guardian/application/find.use.case';
export const Registry = {
  AxiosAdapter: Symbol.for('AxiosAdapter'),
  MockAdapter: Symbol.for('MockAdapter'),
  IGuardianGateway: Symbol.for('IGuardianGateway'),
  FindUseCase: Symbol.for('FindUseCase'),
};
export const container = new Container();
//########## HTTP
container.bind(Registry.AxiosAdapter).toConstantValue(httpApi);
container.bind(Registry.MockAdapter).toConstantValue(mockApi);
//#region ########## GATEWAYS
container.bind(Registry.IGuardianGateway).toDynamicValue(context => {
  return new GuardianHttpGateway(
    context.container.get(Registry.AxiosAdapter),
  );
});
//#endregion
//#region ########## USE CASES
container.bind(Registry.FindUseCase).toDynamicValue(context => {
  return new FindUseCase(context.container.get(Registry.IGuardianGateway));
});
//#endregion
//#region ######### CONTAINERS GET
export const guardian = {
  searchByCpf: container.get<FindUseCase>(Registry.FindUseCase),
};
//#endregion

