import {AxiosInstance} from 'axios';
import {CustomException} from '@core/modules/common/infra/error';
import {httpMessage} from '@core/modules/common/infra/message';

import {Paths} from './keys';
import { BillGateway } from '../domain/bill.gateway';
import { BillInterface } from '../domain/bill.entities';

export class BillHttpGateway implements BillGateway {
    constructor(private http: AxiosInstance) {}

    async create(params: BillInterface): Promise<Boolean> {
        return this.http
            .post(Paths.default, params)
            .then((res) => {
                if (!res?.data) {
                    // throw new CustomException(httpMessage.updateResponseEmpty('create bill'));
                    return false
                }
                return true
            })
            .catch((error) => {
                if (error?.status !== 409) {
                    // throw new CustomException(error?.response?.data);
                    return false
                }
                throw new CustomException(httpMessage.updateError('create bill'));
            });
    }
}
