const buttonClose = (bgColor: string) => {
    return {
        color: bgColor,
        position: 'absolute' as const,
        fontSize: 24 as const,
        top: 6 as const,
        right: 14 as const,
    };
};

export default {
    buttonClose,
};
