import React from 'react';
import {Button, Col, Form, Row, Typography, Form as AntForm, Input} from 'antd';
import {auth} from '@core/modules/authentication/infra/container.registry';
import {TResetPassword} from '@core/modules/authentication/domain/requestAccess.entities';
import {Notification} from '@presentation/components/common';
import {validateMessages} from '../../helpers/message';
import style from '../style';

interface IFormChangePassword {
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    visible: boolean;
    email: string;
    code: string;
}

const FormChangePassword = (props: IFormChangePassword) => {
    const handleChange = async (values: TResetPassword) => {
        try {
            props.setLoading(true);
            const data = {
                email: props.email,
                code: props.code,
                password: values.password,
            };
            await auth.forgotPassword.changePassword(data);
            Notification.success({
                message: 'Senha alterada com sucesso!',
            });
            props.setVisible(!props.visible);
        } catch (error: any) {
            Notification.error({
                message: error.message,
            });
        } finally {
            props.setLoading(false);
        }
    };

    return (
        <div style={style.container}>
            <Typography.Title style={style.description}>Insira a nova senha</Typography.Title>

            <Form
                name="forgot_password"
                className="login-form"
                labelCol={{span: 12}}
                layout="vertical"
                validateMessages={validateMessages}
                onFinish={handleChange}
                autoComplete="off"
                style={style.form}
            >
                <Col xs={24} sm={24}>
                    <AntForm.Item<TResetPassword>
                        label="Senha"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Senha inválida!',
                            },
                        ]}
                    >
                        <Input.Password />
                    </AntForm.Item>
                </Col>
                <Col xs={24} sm={24}>
                    <AntForm.Item<TResetPassword>
                        label="Confirme a senha"
                        name="passwordConfirm"
                        rules={[
                            {
                                required: true,
                                message: `Senha inválida!`,
                            },
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error(`As senhas não são correspondentes!`),
                                    );
                                },
                            }),
                        ]}
                        dependencies={['password']}
                    >
                        <Input.Password />
                    </AntForm.Item>
                </Col>

                <Row justify="end">
                    <Col>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="login-form-button"
                            size={'middle'}
                            loading={props.loading}
                        >
                            SALVAR
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export {FormChangePassword};
