import { TRemoveSurgeonRequest, TCreateSurgeonResponse } from "../domain/portal-unit-surgeon.entities";
import { UnitGateway } from "../domain/unit.gateway";

export class DeleteUnitSurgeonUseCase {
    constructor(private unitGateway: UnitGateway) {}

    async execute(data: TRemoveSurgeonRequest): Promise<TCreateSurgeonResponse> {
        return this.unitGateway.deleteSurgeon(data);
    }
}
