import React from 'react';
import {CloseOutlined} from '@ant-design/icons';
import {theme, Button} from 'antd';
import style from './style';

interface IIconClose {
    isSmall: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const IconClose: React.FC<IIconClose> = (props: IIconClose) => {
    const {
        token: {colorText},
    } = theme.useToken();

    return (
        <>
            {props.isSmall && (
                <Button
                    data-testid="btn-close"
                    icon={<CloseOutlined />}
                    onClick={() => props.setOpen(false)}
                    type="text"
                    style={style.buttonClose(colorText)}
                />
            )}
        </>
    );
};

export default IconClose;
