import 'reflect-metadata';
import {Container} from 'inversify';
import {httpApi} from '../../common/infra/http';

import {ScheduleHttpGateway} from './schedule.http.gateway';

import {GetUseCase} from '../application/get.use.case';
import {GetAllUseCase} from '../application/getAll.use.case';
import {UpdateUseCase} from '../application/update.update.case';
import {ExportUseCase} from '../application/export.use.case';
import { ConfirmUseCase } from '../application/confirm.use.case';
import { CancelUseCase } from '../application/cancel.use.case';
import { ResendBillingUseCase } from '../application/resend-billing.use.case';

export const Registry = {
    AxiosAdapter: Symbol.for('AxiosAdapter'),
    MockAdapter: Symbol.for('MockAdapter'),

    ScheduleGateway: Symbol.for('ScheduleGateway'),
    GetAllUseCase: Symbol.for('GetAllUseCase'),
    GetUseCase: Symbol.for('GetUseCase'),
    UpdateUseCase: Symbol.for('UpdateUseCase'),
    ExportUseCase: Symbol.for('ExportUseCase'),
    ConfirmUseCase: Symbol.for('ConfirmUseCase'),
    CancelUseCase: Symbol.for('CancelUseCase'),
    ResendBillingUseCase: Symbol.for('ResendBillingUseCase'),
};

export const container = new Container();

//########## HTTP
container.bind(Registry.AxiosAdapter).toConstantValue(httpApi);

//#region ########## GATEWAYS
container.bind(Registry.ScheduleGateway).toDynamicValue((context) => {
    return new ScheduleHttpGateway(context.container.get(Registry.AxiosAdapter));
});

//#endregion

//#region ########## USE CASES
container.bind(Registry.GetUseCase).toDynamicValue((context) => {
    return new GetUseCase(context.container.get(Registry.ScheduleGateway));
});

container.bind(Registry.GetAllUseCase).toDynamicValue((context) => {
    return new GetAllUseCase(context.container.get(Registry.ScheduleGateway));
});

container.bind(Registry.UpdateUseCase).toDynamicValue((context) => {
    return new UpdateUseCase(context.container.get(Registry.ScheduleGateway));
});

container.bind(Registry.ExportUseCase).toDynamicValue((context) => {
    return new ExportUseCase(context.container.get(Registry.ScheduleGateway));
});

container.bind(Registry.ConfirmUseCase).toDynamicValue((context) => {
    return new ConfirmUseCase(context.container.get(Registry.ScheduleGateway))
});

container.bind(Registry.CancelUseCase).toDynamicValue((context) => {
    return new CancelUseCase(context.container.get(Registry.ScheduleGateway))
});

container.bind(Registry.ResendBillingUseCase).toDynamicValue((context) => {
    return new ResendBillingUseCase(context.container.get(Registry.ScheduleGateway))
});

//#endregion

//#region ######### CONTAINERS GET
const schedule = {
    get: container.get<GetUseCase>(Registry.GetUseCase),
    getAll: container.get<GetAllUseCase>(Registry.GetAllUseCase),
    export: container.get<ExportUseCase>(Registry.ExportUseCase),
    update: container.get<UpdateUseCase>(Registry.UpdateUseCase),
    confirm: container.get<ConfirmUseCase>(Registry.ConfirmUseCase),
    cancel: container.get<CancelUseCase>(Registry.CancelUseCase),
    resendBilling: container.get<ResendBillingUseCase>(Registry.ResendBillingUseCase),
};
export default schedule;
//#endregion
