const cpf = {
    minWidth: '100%',
    maxWidth: '100%',
    borderRadius: 20,
    height: 40,
    paddingLeft: 10,
    border: '1.4px solid var(--brand-colors-neutral-colors-grey-4, #D9D9D9)',
};

export default {
    cpf,
};
