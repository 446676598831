import {SchedulePagination, TAll} from '../domain/schedule.entities';
import {ScheduleGateway} from '../domain/schedule.gateway';

export class GetAllUseCase {
    constructor(private gate: ScheduleGateway) {}

    async execute(params: TAll): Promise<SchedulePagination> {
        return this.gate.getAll(params);
    }
}
