import React, { createContext, useContext, useState, ReactNode } from 'react';

interface ItemsContextType {
  items: any[];
  setItems: React.Dispatch<React.SetStateAction<any[]>>;
  origin: string;
  setOrigin: React.Dispatch<React.SetStateAction<string>>;
  currentPagination: number;
  setCurrentPagination: React.Dispatch<React.SetStateAction<number>>;
  initialDate: string
  setInitialDate: React.Dispatch<React.SetStateAction<string>>;
  finalDate: string
  setFinalDate: React.Dispatch<React.SetStateAction<string>>;
  selectedKey: string|undefined;
  setSelectedKey: React.Dispatch<React.SetStateAction<string|undefined>>;
  surgeon: string|undefined;
  setSurgeon: React.Dispatch<React.SetStateAction<string|undefined>>;
  statusFilter: string|undefined;
  setStatusFilter: React.Dispatch<React.SetStateAction<string|undefined>>;
  locale: string|undefined;
  setLocale: React.Dispatch<React.SetStateAction<string|undefined>>;
  scheduleId: string|undefined;
  setScheduleId: React.Dispatch<React.SetStateAction<string|undefined>>;
  requester: string|undefined;
  setRequester: React.Dispatch<React.SetStateAction<string|undefined>>;
  patient: string|undefined;
  setPatient: React.Dispatch<React.SetStateAction<string|undefined>>;
  createdInitial: string|undefined;
  setCreatedInitial: React.Dispatch<React.SetStateAction<string|undefined>>;
  createdFinal: string|undefined;
  setCreatedFinal: React.Dispatch<React.SetStateAction<string|undefined>>;
  statusBilling: string|undefined;
  setStatusBilling: React.Dispatch<React.SetStateAction<string|undefined>>;
  isRedirect: boolean;
  setIsRedirect: React.Dispatch<React.SetStateAction<boolean>>;
}

const ItemsContext = createContext<ItemsContextType | undefined>(undefined);

export const useItemsContext = () => {
  const context = useContext(ItemsContext);
  if (!context) {
    throw new Error('useItemsContext must be used within a ItemsProvider');
  }
  return context;
};

interface ItemsProviderProps {
  children: ReactNode;
}

export const ItemsProvider = ({ children }: ItemsProviderProps) => {
  const [items, setItems] = useState<any[]>([]);
  const [origin, setOrigin] = useState<string>('');
  const [currentPagination, setCurrentPagination] = useState<number>(0);
  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState('')
  const [selectedKey, setSelectedKey] = useState<string | undefined>(undefined);
  const [surgeon, setSurgeon] = useState<string | undefined>(undefined);
  const [statusFilter, setStatusFilter] = useState<string | undefined>(undefined);
  const [locale, setLocale] = useState<string | undefined>(undefined);
  const [scheduleId, setScheduleId] = useState<string | undefined>(undefined);
  const [requester, setRequester] = useState<string | undefined>(undefined);
  const [patient, setPatient] = useState<string | undefined>(undefined);
  const [createdInitial, setCreatedInitial] = useState<string | undefined>(undefined);
  const [createdFinal, setCreatedFinal] = useState<string | undefined>(undefined);
  const [statusBilling, setStatusBilling] = useState<string | undefined>(undefined);

  const [isRedirect, setIsRedirect] = useState<boolean>(false);

  return (
    <ItemsContext.Provider value={{ 
      items, 
      setItems, 
      origin, 
      setOrigin, 
      currentPagination, 
      setCurrentPagination,
      initialDate,
      setInitialDate,
      finalDate,
      setFinalDate,
      selectedKey,
      setSelectedKey,
      surgeon,
      setSurgeon,
      statusFilter,
      setStatusFilter,
      locale,
      setLocale,
      scheduleId,
      setScheduleId,
      requester,
      setRequester,
      patient,
      setPatient,
      createdInitial,
      setCreatedInitial,
      createdFinal,
      setCreatedFinal,
      statusBilling,
      setStatusBilling,

      setIsRedirect,
      isRedirect,
    }}>
      {children}
    </ItemsContext.Provider>
  );
};
