import { TGetSurgeonsResponse } from "../domain/portal-unit-surgeon.entities";
import { UnitGateway } from "../domain/unit.gateway";

export class ListUnitSurgeonUseCase {
    constructor(private unitGateway: UnitGateway) {}

    async execute(params: any): Promise<TGetSurgeonsResponse> {
        return this.unitGateway.listSurgeons(params);
    }
}
