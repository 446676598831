function updateUrlParameter(url: string, param: string, value: string): string {
  const regex = new RegExp(`([?&])${param}=.*?(&|$)`, 'i');
  const separator = url.indexOf('?') !== -1 ? '&' : '?';
  if (url.match(regex)) {
    return url.replace(regex, `$1${param}=${value}$2`);
  } else {
    return `${url}${separator}${param}=${value}`;
  }
}

export default function addSurgeonsAndUnity(url: string) {
  const isBackoffice = JSON.parse(localStorage.getItem('@user_infos_key') ?? '{}').type === 'BACKOFFICE';

  if (isBackoffice) {
    return url;
  }

  const unit_portal = JSON.parse(localStorage.getItem('@unit_portal') ?? '{}');
  const surgeon_filtered = JSON.parse(localStorage.getItem('@surgeon_filtered') ?? '[]');

  const surgeons = surgeon_filtered.map((surgeon: number) => surgeon).join(',');
  const unity = unit_portal.id;

  url = updateUrlParameter(url, 'surgeon_ids', surgeons);

  if (!unit_portal.isPrivate) {
    url = updateUrlParameter(url, 'unity_ids', unity);
  }

  return url;
}