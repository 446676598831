import {Notification} from '@presentation/components/common';

const fetchCep = async (zipcode: string) => {

  const response = await fetch(`https://viacep.com.br/ws/${zipcode}/json/`)

  if (!response.ok) {

    Notification.error({
      message: 'Não foi possível pesquisar o CEP nesse momento!',
      duration: 2,
    })

    throw new Error('Erro ao obter resposta')
  }

  const data = await response.json()

  return data

}

const CheckCep = async (zipcode: string) => {

  try {

    const data = await fetchCep(zipcode)

    if ( data.erro == true ){

      Notification.error({
        message: 'O CEP informado não foi localizado!',
        duration: 2,
      })

    }

    return data

  } catch (error) {

    Notification.error({
      message: 'Não foi possível pesquisar o CEP nesse momento!',
      duration: 2,
    })

    return {}
  }

}

export default CheckCep
