import React from 'react';
import {Button, Col, Form, Row, Typography, Flex} from 'antd';
import {auth} from '@core/modules/authentication/infra/container.registry';
import {Notification} from '@presentation/components/common';
import {InputDefaultNumber} from '../../InputDefaultNumber';
import {validateMessages} from '../../helpers/message';

import style from '../style';

interface IFormVerifyCode {
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setIsVerifyCode: React.Dispatch<React.SetStateAction<boolean>>;
    email: string;
    setCode: React.Dispatch<React.SetStateAction<string>>;
}

interface TFormVerifyCode {
    codeOne: string;
    codeTwo: string;
    codeThree: string;
    codeFour: string;
}

const FormVerifyCode = (props: IFormVerifyCode) => {
    const handleVerify = async (values: TFormVerifyCode) => {
        try {
            props.setLoading(true);
            const codeArray: string[] = Object.values(values);
            const code: string = codeArray.join('');

            const isSendCode = await auth.forgotPassword.validateCode({
                code: code,
                email: props.email,
            });
            props.setCode(code);
            props.setIsVerifyCode(isSendCode);
        } catch (error: any) {
            Notification.error({
                message: error.message,
            });
        } finally {
            props.setLoading(false);
        }
    };

    return (
        <div style={style.container}>
            <Typography.Title style={style.description}>
                Insira os 4 dígitos que enviamos para você para recuperar a senha
            </Typography.Title>

            <Form
                name="forgot_password-verify-code"
                className="login-form"
                labelCol={{span: 12}}
                layout="vertical"
                validateMessages={validateMessages}
                onFinish={handleVerify}
                autoComplete="off"
                style={style.form}
            >
                <Flex>
                    <InputDefaultNumber
                        name="codeOne"
                        label=""
                        placeholder=""
                        required={true}
                        maxLength={1}
                        maxWidth="56%"
                        height={40}
                        min={0}
                    />
                    <InputDefaultNumber
                        name="codeTwo"
                        label=""
                        placeholder=""
                        required={true}
                        maxLength={1}
                        maxWidth="56%"
                        height={40}
                        min={0}
                    />
                    <InputDefaultNumber
                        name="codeThree"
                        label=""
                        placeholder=""
                        required={true}
                        maxLength={1}
                        maxWidth="56%"
                        height={40}
                        min={0}
                    />
                    <InputDefaultNumber
                        name="codeFour"
                        label=""
                        placeholder=""
                        required={true}
                        maxLength={1}
                        maxWidth="56%"
                        height={40}
                        min={0}
                    />
                </Flex>
                <Row justify="end">
                    <Col>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="login-form-button"
                            size={'middle'}
                            loading={props.loading}
                        >
                            VERIFICAR
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export {FormVerifyCode};
