import {AuthenticationGateway} from '../domain/authentication.gateway';
import {TermsOfUseAndPrivacyPolicies} from '../domain/termsOfUseAndPrivacyPolicies.entities';

export class TermsOfUseAndPrivacyPoliciesUseCase {
    constructor(private gate: AuthenticationGateway) {}

    async execute(): Promise<TermsOfUseAndPrivacyPolicies> {
        return this.gate.termsOfUseAndPrivacyPolicies();
    }
}
