import { CustomerGateway } from '../domain/customer.gateway';
import { UpdateCustomerInterface } from '@/@presentation/modules/schedule/pages/home/receiver';

export class UpdateUseCase {
    constructor(private gate: CustomerGateway) {}

    async execute(params: UpdateCustomerInterface): Promise<number> {
        const updatedParams: UpdateCustomerInterface = {
            ...params
        };
        return this.gate.update(updatedParams);
    }
}
