import { useState } from "react";

const Status = (record: any) => {

    // const [bd, setBd] = useState("#c9c9c9")
    // const [bkg, setBkg] = useState("#f9f9f9")

    let bd = "#c9c9c9"
    let bkg = "#f9f9f9"

    const defineStatusColor = (status: string) =>{
        switch (status?.toLowerCase()) {
            case 'pendente':
                bd = '#F57C00'
                bkg = '#FFF4EC '
                break
            case 'pendente pagamento':
                bd = '#F57C00'
                bkg = '#FFF4EC '
                break          
            case 'em andamento':
                break
            case 'finalizado':
                break
            case 'pago':
                bd = '#06813A'
                bkg = '#EDF9F0'
                break
            case 'pago antecipado':
                bd = '#E55050'
                bkg = '#EDF9F0'
                break
            case 'cancelado':
                bd = '#E55050'
                bkg = '#FEEFEF'
                break
            case 'cortesia':
                bd = '#7570FF'
                bkg = '#E3E2FF'
                break
            default:
                break
        }
    }

    defineStatusColor(record.status)

    return (
        <div style={{
            cursor: 'not-allowed', 
            width: 92, 
            backgroundColor: bkg, 
            borderWidth: 1, 
            borderStyle: "solid",
            borderColor: bd, 
            borderRadius: 20, 
            color: bd,
            fontSize: 10,
            padding: 4,
            textAlign: "center"
        }}>
            {record.status}
        </div>
    );
};

export default Status
