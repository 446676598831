import { ConciliationResponse, ConciliatorFilter } from "../domain/conciliator.entity";
import { conciliatorGateway } from "../domain/conciliator.gateway";

export class GetListUseCase {
  constructor(private gateway: conciliatorGateway) {}

  async execute(filters: ConciliatorFilter): Promise<ConciliationResponse> {
    return await this.gateway.getListConciliator(filters);
  }
}
