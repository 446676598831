import {TPagination} from '@core/modules/common/domain/common.entities';
import phone from '@core/modules/common/infra/phone';

export type TResetPassword = {
    password: string;
    passwordConfirm: string;
};

export type TAll = {
    offset: number;
    limit: number;
    search?: string;
};

export type TGet = {
    id: string | undefined;
};

export type TDataItem = {
    id?: string;
    personId?: string;
    surname?: string;
    crm?: string;
    uf?: string;
    createdAt?: Date;
    updatedAt?: Date;
    person?: {
        name: string;
        email?: string;
        phone?: string;
        telephone: string;
        cpf?: string;
        gender?: string;
        birthdayDate?: string;
        accessId: number;
        cnpj?: string;
        companyName?: string;
        tradeName?: string;
        contact?: string;
        age?: number;
    };
    customerPortalUserId?: number;
};

export type TCreateOrUpdate = {
    id: string;
    completeName: string;
    email?: string;
    phone?: string;
    birthdayDate?: string;
    gender?: string;
    cpf?: string;
    cnpj?: string;
    companyName?: string;
    tradeName?: string;
    observation?: string;
    birthdayDateFormatted?: any;
};

export class SurgeonPagination {
    constructor(
        public props: {
            data: TDataItem[];
            pagination: TPagination;
        },
    ) {}

    get data() {
        return this.props.data?.map((item) => {
            return {
                ...item,
                person: {
                    ...item.person,
                    phone: phone.applyMaskPhone(item.person?.phone),
                },
            };
        });
    }
    get pagination() {
        return this.props.pagination;
    }

    toJSON() {
        return {
            data: this.data,
            pagination: this.pagination,
        };
    }
}

export class Surgeon {
    constructor(public props: TDataItem) {}

    get id() {
        return this.props.id;
    }
    get personId() {
        return this.props.personId;
    }
    get surname() {
        return this.props.surname;
    }
    get crm() {
        return this.props.crm;
    }
    get uf() {
        return this.props.uf;
    }
    get createdAt() {
        return this.props.createdAt;
    }
    get updatedAt() {
        return this.props.updatedAt;
    }
    get person() {
        return {
            ...this.props.person,
            phone: phone.applyMaskPhone(this.props.person?.phone),
        };
    }
    get customerPortalUserId() {
        return this.props.customerPortalUserId;
    }

    toJSON() {
        return {
            id: this.id,
            personId: this.personId,
            surname: this.surname,
            crm: this.crm,
            uf: this.uf,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            person: this.person,
            customerPortalUserId: this.customerPortalUserId,
        };
    }
}
