import React from "react";

interface IStyles {
  typography: React.CSSProperties;
  h1: React.CSSProperties;
  defaultInput: React.CSSProperties;
}

export const styles: IStyles = {
  typography: {
    marginBottom: 30,
    color: '#004A7D',
    fontSize: 20,
    fontWeight: 400,
  },
  h1: {
    fontSize: 30,
    fontWeight: 400,
  },
  defaultInput: {
    minWidth: '100%',
    maxWidth: '100%',
    height: 40,
  },
}
