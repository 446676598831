import {Form as AntForm, Input} from 'antd';

interface IInputEmail {
    value?: string;
    required?: boolean;
    name?: string;
    label?: string;
    placeholder?: string;
    style?: React.CSSProperties | undefined;
}

const InputEmail = (props: IInputEmail) => {
    return (
        <AntForm.Item
            style={{
                padding: 0,
                margin: 0,
            }}
            id={props.name ? props.name : 'email'}
            name={props.name ? props.name : 'email'}
            label={props.label ?? ''}
            data-testid="input-email"
            rules={[
                {
                    required: props?.required,
                    type: 'email',
                    message: `${
                        props.label || props.placeholder
                            ? props.label || props.placeholder
                            : 'E-mail'
                    } inválido!`,
                },
            ]}
            initialValue={props?.value}
        >
            <Input
                size="large"
                id="email"
                style={{
                    minWidth: '100%',
                    maxWidth: '100%',
                    borderRadius: 8,
                    ...props.style,
                }}
                disabled={false}
                data-testid="input-email"
                maxLength={255}
                placeholder={`${props.placeholder ? props.placeholder : 'E-mail'}`}
            />
        </AntForm.Item>
    );
};

export {InputEmail};
