import { RadioForm } from '@/@presentation/components/form';
import { Typography } from 'antd';
import React from 'react';
import { styles } from '../../styles';
import { ProcedureContextData } from '@/@core/modules/procedure/domain/procedure.entities';
import { FormInstance } from 'antd/lib';

interface IProps {
  states: ProcedureContextData['states'];
  isConfirm: boolean;
  form: FormInstance<any>;
}

export const TypeOfAppointment: React.FC<IProps> = (props) => {
  const formItems = props.form.getFieldsValue()

  return (
    <div>
      <Typography style={{ ...styles.typography, marginBottom: 0 }}>Tipo de agendamento</Typography>
      {
        props.isConfirm ? (
          <RadioForm
            name="type"
            options={props.states.procedureTypes.filter((item) => item.value === (formItems.type ? formItems.type : 'surgery'))}
            seleted='surgery'
          />
        ) : (
          <RadioForm
            name="type"
            options={props.states.procedureTypes}
            seleted='surgery'
          />
        )
      }
    </div>
  )
};