const sidebar = (bgColor: string) => {
    return {
        marginTop: '0%',
        marginLeft: '0%',
        backgroundColor: bgColor,
    };
};

const drawer = (bgColor: string) => {
    return {
        backgroundColor: bgColor,
    };
};

export default {
    sidebar,
    drawer,
};
