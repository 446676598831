import {injectable} from 'inversify';
import {RequestAccess} from '../domain/requestAccess.entities';
import {AuthenticationLocalStorageGateway} from '../domain/authentication.gateway';

import {Keys} from './keys';
import {UserInfos} from '../domain/userInfos.entities';

@injectable()
export class AuthenticationLSGateway implements AuthenticationLocalStorageGateway {
    async get(): Promise<RequestAccess> {
        const data = JSON.parse(window.localStorage.getItem(Keys.ACCESS) ?? '');
        return new RequestAccess(data || null);
    }
    async clearAll(): Promise<void> {
        window.localStorage.clear();
    }
    async remove(): Promise<void> {
        window.localStorage.remove(Keys.ACCESS);
    }
    async save(params: RequestAccess): Promise<void> {
        window.localStorage.setItem(Keys.ACCESS, JSON.stringify(params));
    }

    async getUserInfos(): Promise<UserInfos> {
        const st = window.localStorage.getItem(Keys.USER_INFOS) ?? '';
        const data = st ? JSON.parse(st) : '';
        return new UserInfos(data || null);
    }

    async saveUserInfos(params: UserInfos): Promise<void> {
        window.localStorage.setItem(Keys.USER_INFOS, JSON.stringify(params));
    }
}
