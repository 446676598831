import { Row, Col, Typography, Switch, Button } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";

interface ProceduresProps {
  code: string;
  name: string;
  isPrincipal: boolean;
  onChange: (checked: boolean) => void;
  onRemove: (code: string) => void;
  isConfirm: boolean;
}

const Procedures = (props: ProceduresProps) => {
  return (
    <Row
      justify="space-between"
      align="middle"
      gutter={[8, 16]}
      style={{ width: '100%' }}
    >
      <Col style={{width: 600}}>
        <Typography.Text>{`${props.code} - ${props.name}`}</Typography.Text>
      </Col>
      {!props.isConfirm && <Col>
        <Switch
          checked={props.isPrincipal}
          onChange={props.onChange}
        />
        <Typography.Text style={{ marginLeft: 8 }}>
          Procedimento principal
        </Typography.Text>
      </Col>}
        {!props.isConfirm && <Col>
          <Button
            type="text"
            icon={<CloseCircleFilled style={{ color: '#00A6D0' }} />}
            onClick={() => props.onRemove(props.code)}
          />
        </Col>}
    </Row>
  );
};

export default Procedures;
