const container = {
    minHeight: '99vh',
    minWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};
const title = {
    fontSize: 24,
    marginTop: '6%',
    marginBottom: '2%',
};

const titleSub = {
    fontSize: 14,
    marginTop: '2%',
    marginBottom: '12%',
};

const back = (bgColor: string) => {
    return {
        fontSize: 18,
        color: bgColor,
    };
};

export default {
    container,
    title,
    back,
    titleSub,
};
