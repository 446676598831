const API_URL = import.meta.env.VITE_API_BASE_URL;
const cspValue = `connect-src 'self' ${API_URL} https://viacep.com.br;`;

const CSP = () => {
    const metaTagElement = document.createElement('meta');
    metaTagElement.httpEquiv = 'Content-Security-Policy';
    metaTagElement.content = cspValue;
    document.head.appendChild(metaTagElement);
};

const Security = () => {
    CSP();
};

export default Security;
